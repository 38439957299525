import React, { useState, useContext } from "react";
import { Auth } from "aws-amplify";
import { LibertateContext } from "../../../context/Context";
import styles from "../../pages/Login.module.scss"

const FormLogin = ({ loginPageHandler }) => {
  let libertateContext = useContext(LibertateContext);
  const [userLoginDetails, setUserLoginDetails] = useState({ userName: "" });
  const [formLoginValidation, setFormLoginValidation] = useState({
    userName: "",
    currentPassWord: "",
  });

  const validateForm = () => {
    console.log("validateForm");
    var form = true;
    if (
      userLoginDetails?.["userName"] === "" ||
      userLoginDetails?.["error_userName"] === true
    ) {
      setFormLoginValidation((prevstate) => ({
        ...prevstate,
        ["userName"]: true,
      }));
      form = false;
    }
    if (
      userLoginDetails?.["currentPassWord"] === "" ||
      userLoginDetails?.["error_currentPassWord"] === true
    ) {
      setFormLoginValidation((prevstate) => ({
        ...prevstate,
        ["currentPassWord"]: true,
      }));
      form = false;
    }
    return form;
  };
  const signIn = async (e) => {
    e.preventDefault();
    let validForm = validateForm();
    if (validForm) {
      console.log(libertateContext);
      libertateContext.startLoader();
      const username = userLoginDetails?.["userName"];
      const password = userLoginDetails?.["currentPassWord"];
      try {
        var userTemp = await Auth.signIn(username.toLowerCase(), password);
        console.log("UserTemp: ", userTemp);
        if (userTemp.challengeName === "NEW_PASSWORD_REQUIRED") {
          loginPageHandler("changePassword");
          libertateContext.stopLoader();
        } else {
          if (
            userTemp.signInUserSession !== null &&
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_AGENCY_GROUP)
          ) {
            sessionStorage.setItem("isLoggedIn", true);
            window.location.href = "./Quote";
            libertateContext.stopLoader();
          } else if (
            (userTemp.signInUserSession !== null &&
              (userTemp.signInUserSession.accessToken.payload[
                "cognito:groups"
              ].includes(process.env.REACT_APP_RIPPLING_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_IES_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_GMS_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_SIMPLOY_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_DEMO_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_EMPLOYCO_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_APPPEO_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_ENGAGEPEO_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_MAKAI_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_WCN_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_CORPSOL_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_GREGORY_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_EASI_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_STAFFLINK_GROUP) ||
                userTemp.signInUserSession.accessToken.payload[
                  "cognito:groups"
                ].includes(process.env.REACT_APP_STRATUS_GROUP))) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_EXPRESS_GROUP) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_GALACTIC_GROUP) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_PAYROLLME_GROUP) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_ACCURATE_GROUP) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_CONNECTIFY) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_MANAGEPOINT) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_NEMR) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_TEAMWORKS) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_ELEVATION) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_TOTALSOLUTION) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_COVENANT) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_METHOD_GROUP) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_MILTONPARK) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_SIDECAR) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_CBR) ||
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_ESG_GROUP)
          ) {
            sessionStorage.setItem("isLoggedIn", true);
            window.location.href = "./Quote";
            // $('#loader').css('display','none');
          } else if (
            userTemp.signInUserSession !== null &&
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_SALES_GROUP)
          ) {
            sessionStorage.setItem("isLoggedIn", true);
            window.location.href = "./Quote";
          } else if (
            userTemp.signInUserSession !== null &&
            userTemp.signInUserSession.accessToken.payload[
              "cognito:groups"
            ].includes(process.env.REACT_APP_UW_GROUPS)
          ) {
            sessionStorage.setItem("isLoggedIn", true);
            window.location.href = "./UwPortal";
          } else {
            try {
              sessionStorage.clear();
              localStorage.clear();
            } catch (error) {}
            Auth.signOut()
              .then(function (res) {})
              .catch((err) => {
                console.log("error in logout: ", err);
              });
            libertateContext.stopLoader();
            setFormLoginValidation((prevstate) => ({
              ...prevstate,
              userDoesNotExists: true,
              errMessage: "User does not exist!",
            }));
          }
        }
      } catch (err) {
        libertateContext.stopLoader();
        console.log("error signing in! :", err);
        if (
          err.code === "UserNotFoundException" ||
          err.code === "NotAuthorizedException"
        ) {
          setFormLoginValidation((prevstate) => ({
            ...prevstate,
            userDoesNotExists: true,
            errMessage: err.message,
          }));
        } else {
          setFormLoginValidation((prevstate) => ({
            ...prevstate,
            userDoesNotExists: true,
            errMessage:
              "Unable to login! Please contact admin@insurecomp.com for more details!",
          }));
        }
      }
    }
  };
  const loginDetailsHandler = (event) => {
    console.log("on everytime");
    const name = event.target.name;
    const value = event.target.value;

    setUserLoginDetails((prevState) => ({
      ...prevState,
      [name]: value,
      [`error_${name}`]: false,
    }));
    setFormLoginValidation((prevState) => ({
      ...prevState,
      [name]: false,
      userDoesNotExists: false,
      errMessage: "",
    }));
  };

  const validEmail = (e) => {
    console.log("validateForm email");
    var element = e.target;
    const name = event.target.name;
    var reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(element.value);
    if (!isValid && element.value !== "") {
      setUserLoginDetails((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    }
  };
  const validPassword = (e) => {
    console.log("validateForm passower");
    var element = e.target;
    const name = event.target.name;
    var reg =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/;
    var isValid = reg.test(element.value);
    if (!isValid && element.value !== "") {
      setUserLoginDetails((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    }
  };

  return (
    <div className="tab-pane fade-in active">
      <div id="formLogin" className={styles.formLogin}>
        <p className={`${styles.formTitle} text-center`}>LOGIN</p>
        <hr />
        <form
          onSubmit={(e) => {
            signIn(e);
          }}
        >
          <div>
            {userLoginDetails?.["error_userName"] && (
              <span className={styles.errMsg}>john.doe@example.com</span>
            )}
            {formLoginValidation?.["userName"] && (
              <span className={styles.errMsg}>Email is required</span>
            )}
            <input
              autoComplete="off"
              id="userId"
              type="email"
              name="userName"
              placeholder="Enter Email Address"
              onBlur={validEmail}
              onChange={loginDetailsHandler}
              value={userLoginDetails?.["userName"] || ""}
              style={{
                borderColor: `${
                  userLoginDetails?.["error_userName"] ||
                  formLoginValidation?.["userName"]
                    ? "red"
                    : ""
                }`,
              }}
            />
          </div>
          <div>
            {userLoginDetails?.["error_currentPassWord"] && (
              <span className={styles.errMsg}>
                Password at least 8 digits with upper, lower case alphabet,
                Numbers and special characters.
              </span>
            )}
            {formLoginValidation?.["currentPassWord"] && (
              <span className={styles.errMsg}>Passwork is required</span>
            )}
            <input
              autoComplete="off"
              id="userPassword"
              name="currentPassWord"
              type="password"
              placeholder="Enter Password"
              onBlur={validPassword}
              onChange={loginDetailsHandler}
              value={userLoginDetails?.["currentPassWord"] || ""}
              style={{
                borderColor: `${
                  userLoginDetails?.["error_currentPassWord"] ||
                  formLoginValidation?.["currentPassWord"]
                    ? "red"
                    : ""
                }`,
              }}
            />
          </div>
          <input
            autoComplete="off"
            id="doRemember"
            type="checkbox"
            defaultChecked
          />
          <span className={styles.inptSideText}>Remember Me</span>
          <br></br>
          {formLoginValidation?.["userDoesNotExists"] && (
            <span style={{ color: "red" }}>
              {formLoginValidation?.["errMessage"]}
            </span>
          )}
          <button id="loginSubmit" className="btnFormFinal">
            SUBMIT
          </button>
        </form>
        <div className={`${styles.formBottomLinks} d-flex`}>
          <a
            id="link"
            data-toggle="tab"
            onClick={() => {
              loginPageHandler("changePassword");
            }}
          >
            Change Password
          </a>
          <a
            data-toggle="tab"
            aria-expanded="true"
            onClick={() => {
              loginPageHandler("resetPassword");
            }}
          >
            Forgot Password?
          </a>
        </div>
      </div>
    </div>
  );
};

export default FormLogin;
