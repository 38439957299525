import { React, useState, useContext } from "react";
import { Auth } from "aws-amplify";
import { LibertateContext } from "../../../context/Context";
import styles from "../../pages/Login.module.scss"
import { style } from "@mui/system";

const FormReset = ({ loginPageHandler }) => {
  let libertateContext = useContext(LibertateContext);
  const [isOTPSent, setIsOTPSent] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [resetEmailId, setResetEmailId] = useState({
    resetUserEmail: "",
    otp: "",
    resetPassword: "",
    resetConfirmPassword: "",
  });
  const [formLoginValidation, setFormLoginValidation] = useState({});

  const resetEmailHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setResetEmailId((prevState) => ({
      ...prevState,
      [name]: value,
      [`error_${name}`]: false,
    }));
    setFormLoginValidation((prevState) => ({
      ...prevState,
      [name]: false,
      userDoesNotExists: false,
      errMessage: "",
    }));
  };

  const validEmail = (e) => {
    var element = e.target;
    const name = event.target.name;
    var reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(element.value);
    if (!isValid && element.value !== "") {
      setResetEmailId((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    }
  };

  const onlyNum = (e) => {
    const name = event.target.name;
    // var reg = /^[0-9\b]+$/;
    var reg = /^\d{1,6}$/;
    var element = e.target;
    var isValid = reg.test(element.value);
    console.log("isValid: ", isValid);
    if (element.value.length < 6) {
      setResetEmailId((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    } else if (!isValid && element.value !== "") {
      setResetEmailId((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    }
  };
  const validPassword = (e) => {
    var element = e.target;
    const name = event.target.name;
    var reg =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/;
    var isValid = reg.test(element.value);
    if (!isValid && element.value !== "") {
      setResetEmailId((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    }
  };
  const forgotPassword = async () => {
    if (!isOTPSent) {
      if (
        resetEmailId?.["resetUserEmail"] === "" ||
        resetEmailId?.["error_resetUserEmail"] === true
      ) {
        // show tha email Id is Required when it's empty
        setFormLoginValidation((prevState) => ({
          ...prevState,
          resetUserEmail: true,
        }));
        return false;
      } else {
        libertateContext.startLoader();
        var user_name = resetEmailId?.["resetUserEmail"];
        await Auth.forgotPassword(user_name.toLowerCase())
          .then((res) => {
            console.log(res);
            setIsOTPSent(true);
            libertateContext.stopLoader();
          })
          .catch((err) => {
            console.log("++", err);
            if (err.code === "LimitExceededException") {
              console.log("limit exceeded exception");

              setFormLoginValidation((prevState) => ({
                ...prevState,
                userDoesNotExists: true,
                errMessage:
                  "Attempt limit exceeded, please try after some time.",
              }));
            } else if (
              err.code === "UserNotFoundException" ||
              err.code === "InvalidParameterException"
            ) {
              setFormLoginValidation((prevState) => ({
                ...prevState,
                userDoesNotExists: true,
                errMessage: "User not found.",
              }));
            }
            libertateContext.stopLoader();
          });
      }
    } else if (isOTPSent) {
      let isValid = isValide();
      if (isValid) {
        libertateContext.startLoader();
        var user_name = resetEmailId?.["resetUserEmail"];
        var password = resetEmailId?.["resetPassword"];
        var otp = resetEmailId?.["otp"];
        Auth.forgotPasswordSubmit(user_name.toLowerCase(), otp, password)
          .then((res) => {
            setIsSubmitted(true);

            libertateContext.stopLoader();
          })
          .catch((err) => {
            console.log("err", err);
            if (err.code === "CodeMismatchException") {
              setFormLoginValidation((prevState) => ({
                ...prevState,
                userDoesNotExists: true,
                errMessage:
                  "Invalid verification code provided, please try again.",
              }));
            }
            libertateContext.stopLoader();
          });
      }
    }
  };
  const isValide = () => {
    let flag = true;
    if (resetEmailId?.["resetUserEmail"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        resetUserEmail: true,
      }));
      flag = false;
    }

    if (resetEmailId?.["otp"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        otp: true,
      }));
      flag = false;
    }
    if (resetEmailId?.["resetPassword"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        resetPassword: true,
      }));
      flag = false;
    }
    if (resetEmailId?.["resetConfirmPassword"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        resetConfirmPassword: true,
      }));
      flag = false;
    }

    if (
      resetEmailId?.["resetPassword"] !== resetEmailId?.["resetConfirmPassword"]
    ) {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        resetConfirmPassword: true,
      }));

      flag = false;
    }
    return flag;
  };

  return (
    <div className="tab-pane fade-in active">
      <div id="formReset" className={styles.formReset}>
        {isSubmitted && (
          <p className="text-center text-success">
            Your password has been reset successfully! <br />
          </p>
        )}
        {!isSubmitted && (
          <span>
            <p className={`${styles.formTitle} text-center`}>RESET PASSWORD</p>
            <hr />
          </span>
        )}
        {!isSubmitted && (
          <div>
            <form>
              {resetEmailId?.["error_resetUserEmail"] && (
                <span className={styles.errMsg}>john.doe@example.com</span>
              )}

              {formLoginValidation["resetUserEmail"] && (
                <span className={styles.errMsg}>Email is required.</span>
              )}
              <input
                autoComplete="off"
                id="resetId"
                type="email"
                placeholder="Enter Email Address"
                onBlur={validEmail}
                name="resetUserEmail"
                onChange={resetEmailHandler}
                value={resetEmailId?.["resetUserEmail"] || ""}
                style={{
                  borderColor: `${
                    resetEmailId?.["error_resetUserEmail"] ||
                    formLoginValidation["resetUserEmail"]
                      ? "red"
                      : ""
                  }`,
                }}
              />
              {/* </div> */}
              {isOTPSent && (
                <div>
                  {resetEmailId?.["error_otp"] && (
                    <span className={styles.errMsg}>
                      {resetEmailId?.["otp"]?.length > 0
                        ? "invalid"
                        : "required"}
                    </span>
                  )}
                  {formLoginValidation["otp"] && (
                    <span className={styles.errMsg}>OTP is required.</span>
                  )}
                  <input
                    autoComplete="off"
                    id="otp"
                    type="text"
                    name="otp"
                    placeholder="Enter OTP"
                    maxLength="6"
                    minLength="6"
                    onBlur={onlyNum}
                    onChange={resetEmailHandler}
                    value={resetEmailId?.["otp"]}
                    style={{
                      borderColor: `${
                        resetEmailId?.["error_otp"] ||
                        formLoginValidation["otp"]
                          ? "red"
                          : ""
                      }`,
                    }}
                  />
                </div>
              )}
              {isOTPSent && (
                <div>
                  {resetEmailId?.["error_resetPassword"] && (
                    <span className={styles.errMsg}>
                      Password at least 8 digits with upper, lower case
                      alphabet, Numbers and special characters.
                    </span>
                  )}
                  {formLoginValidation?.["resetPassword"] && (
                    <span className={styles.errMsg}>
                      Please enter a password.
                    </span>
                  )}
                  <input
                    autoComplete="off"
                    id="resetPassword"
                    type="password"
                    name="resetPassword"
                    placeholder="Enter Password"
                    onBlur={validPassword}
                    onChange={resetEmailHandler}
                    value={resetEmailId?.["resetPassword"]}
                    style={{
                      borderColor: `${
                        resetEmailId?.["error_resetPassword"] ? "red" : ""
                      }`,
                    }}
                  />
                </div>
              )}
              {isOTPSent && (
                <div>
                  {resetEmailId?.["error_resetConfirmPassword"] && (
                    <span className={styles.errMsg}>
                      Confirm Password and Password must be equal.
                    </span>
                  )}
                  {formLoginValidation?.["resetConfirmPassword"] && (
                    <span className={styles.errMsg}>
                      {resetEmailId?.["resetConfirmPassword"]?.length > 0
                        ? "Confirm Password and Password must be equal."
                        : "Please confirm your password."}
                    </span>
                  )}
                  <input
                    autoComplete="off"
                    id="resetConfirmPassword"
                    type="password"
                    name="resetConfirmPassword"
                    placeholder="Confirm Password"
                    value={resetEmailId?.["resetConfirmPassword"]}
                    onChange={resetEmailHandler}
                    style={{
                      borderColor: `${
                        resetEmailId?.["error_resetConfirmPassword"] ||
                        formLoginValidation?.["resetConfirmPassword"]
                          ? "red"
                          : ""
                      }`,
                    }}
                  />
                </div>
              )}
              <div>
                {formLoginValidation?.["userDoesNotExists"] && (
                  <span className={styles.errMsg}>
                    {formLoginValidation?.["errMessage"]}
                  </span>
                )}
                <button
                  type="button"
                  id="resetSubmit"
                  className="btnFormFinal"
                  onClick={forgotPassword}
                >
                  SUBMIT
                </button>
              </div>
            </form>
          </div>
        )}
        <div
          className={`${styles.formBottomLinks}  text-center ${styles.resetSubmitDiv}`}
          style={{
            backgroundColor: isSubmitted ? "#095d98" : "",
            width: isSubmitted ? "100% " : "",
            boxShadow: isSubmitted ? "3px 3px 5px #555555" : "",
            borderRadius: isSubmitted ? "4px" : "",
          }}
        >
          <a
            data-toggle="tab"
            aria-expanded="true"
            style={{
              color: isSubmitted ? "white" : "",
            }}
            onClick={() => {
              loginPageHandler();
            }}
          >
            Login
          </a>
        </div>
      </div>
    </div>
  );
};

export default FormReset;
