import React, { Component } from "react";

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }
  onChangeHandler(target) {
    console.log("target", target);
    this.setState({ value: target });
  }

  render() {
    let { value } = this.state;
    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{ width: "100%" }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          let dataToSave = {};
          dataToSave.user_email_id = this.props.rowData.user_email_id;
          dataToSave.sortKeyList = this.props.rowData.uuidCarriers;
          dataToSave.agent_comments = e.target.value;
          this.props.callback(dataToSave);
        }}
      ></textarea>
    );
  }
}

export default TextField;