import React, { Component } from "react";
import DescriptionCode from "../../subcompo/DescriptionCode";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Form } from "react-bootstrap";
import styles from "./NewCode.module.scss";

class Newcode extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidUpdate() {
    if (
      JSON.stringify(this.props.classCodesInfo) !==
      JSON.stringify(this.state.classCodesInfo)
    ) {
      this.setState({ classCodesInfo: this.props.classCodesInfo });
    }
  }

  componentDidMount() {
    const {
      classCodesInfo,
      addressBlockNo,
      addressChildNo,
      handleMinMax,
      childrenLoc,
    } = this.props;

    for (let index in classCodesInfo) {
      if (classCodesInfo?.[index].classCodeDescription) {
        if (
          Object.keys(classCodesInfo[index].classCodeDescription).length === 0
        ) {
          classCodesInfo[index].classCodeDescription =
            childrenLoc[addressBlockNo].classCodesInfo[
              index
            ].classCodeDescription;
        }
      }
    }
    handleMinMax(addressBlockNo, addressChildNo);
    this.setState({ classCodesInfo: classCodesInfo });
  }
  conditional_Render_FT_PT = (value) => {
    let style = {};
    if (window.screen.width < 993) {
      style = {
        maxWidth: "100%",
        paddingInline: "15px",
      };

      return style;
    }
    if (value === "FT" && window.screen.width > 993) {
      style = {
        width: "13%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "PT" && window.screen.width > 993) {
      style = {
        width: "14%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "annual" && window.screen.width > 993) {
      style = {
        width: "18%",
        paddingInline: "15px",
      };
      return style;
    }
    if (value === "WC" && window.screen.width > 993) {
      style = {
        width: "16%",
        paddingInline: "15px",
      };
      return style;
    }
  };
  render() {
    const {
      addressChildNo,
      addressBlockNo,
      state,
      removeClassCode,
      updateClassCodesInfo,
      handleMinMax,
      updateLocationError,
    } = this.props;

    const { classCodesInfo } = this.state;

    let codeCount = 1;
    try {
      codeCount = Object.keys(classCodesInfo).length;
    } catch (e) {}

    return (
      <div
        className={`row ${styles.calcPremium} grey ${styles.Workerscomp_margin}`}
      >
        <div className={styles.descriptionCode}></div>
        <DescriptionCode
          addressBlockNo={addressBlockNo}
          addressChildNo={addressChildNo}
          state={(state || "").toLowerCase()}
          handleMinMax={handleMinMax}
          classCodesInfo={classCodesInfo}
          updateLocationError={updateLocationError}
          updateClassCodesInfo={updateClassCodesInfo}
        />
        {/* <span
          id={"invalidCode" + addressBlockNo + addressChildNo}
          style={{ color: "red" }}
        ></span> */}
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("FT")}
        >
          <Form.Group className={styles.form_group}>
            <label
              htmlFor={"classCodes" + addressBlockNo + addressChildNo + "1"}
              title="Number of full time employees"
            >
              <b className="font-family-montserrat-semi-bold"># Full-Time *</b>
            </label>
            <PatternFormat
              title="Number of full time employees"
              id={"classCodes" + addressBlockNo + addressChildNo + "1"}
              isnumericstring={"true"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "ft",
                  event.target.value.trim()
                );
              }}
              style={{
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].ft &&
                  classCodesInfo[addressChildNo].ft.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo && classCodesInfo[addressChildNo].ft
                  ? classCodesInfo[addressChildNo].ft.value
                  : ""
              }
              disabled={false}
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              name="FT"
              className="text-center"
              format="#####"
              placeholder="# FT"
              type="text"
              autoComplete="off"
            />
          </Form.Group>
        </div>
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("PT")}
        >
          <Form.Group className={styles.form_group}>
            <label
              htmlFor={"classCodes" + addressBlockNo + addressChildNo + "2"}
              title="Number of part time employees"
            >
              <b className="font-family-montserrat-semi-bold"># Part-Time *</b>
            </label>
            <PatternFormat
              title="Number of part time employees"
              id={"classCodes" + addressBlockNo + addressChildNo + "2"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "pt",
                  event.target.value.trim()
                );
              }}
              style={{
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].pt &&
                  classCodesInfo[addressChildNo].pt.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo && classCodesInfo[addressChildNo].pt
                  ? classCodesInfo[addressChildNo].pt.value
                  : ""
              }
              disabled={false}
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              name="PT"
              className="text-center"
              format="#####"
              placeholder="# PT"
              type="text"
              autoComplete="off"
            />
          </Form.Group>
        </div>
        <div
          className="text-left grey"
          style={this.conditional_Render_FT_PT("annual")}
        >
          <Form.Group className={styles.form_group}>
            <label
              htmlFor={"classCodes" + addressBlockNo + addressChildNo + "3"}
              title="Annual Payroll"
            >
              <b className="font-family-montserrat-semi-bold">
                Annual Payroll *
              </b>
            </label>
            <NumericFormat
              title="Annual Payroll"
              id={"classCodes" + addressBlockNo + addressChildNo + "3"}
              onKeyUp={(event) => {
                updateClassCodesInfo(
                  addressBlockNo,
                  addressChildNo,
                  "payroll",
                  event.target.value
                );
              }}
              style={{
                borderColor:
                  classCodesInfo &&
                  classCodesInfo[addressChildNo] &&
                  classCodesInfo[addressChildNo].payroll &&
                  classCodesInfo[addressChildNo].payroll.error
                    ? "red"
                    : "",
              }}
              value={
                classCodesInfo && classCodesInfo[addressChildNo].payroll
                  ? classCodesInfo[addressChildNo].payroll.value
                  : ""
              }
              disabled={false}
              name="payroll"
              onBlur={() => handleMinMax(addressBlockNo, addressChildNo)}
              placeholder="Annual Payroll"
              thousandSeparator={true}
              prefix={"$"}
              className="text-center"
              type="text"
              autoComplete="off"
              allowNegative={false}
            />
          </Form.Group>
        </div>
        <div className={`text-center ${styles.code_delete}`}>
          <br />
          {codeCount > 1 && (
            <button
              id={"delete-class-code" + addressBlockNo + addressChildNo + "4"}
              type="button"
              className={`rmvLoc btnInvisible ${styles.transpButton}`}
              data-address={addressBlockNo}
              data-classno={addressChildNo}
            >
              <img
                onClick={(event) => {
                  removeClassCode(addressBlockNo, addressChildNo);
                }}
                src={require("../../../images/delete4.png")}
                data-address={addressBlockNo}
                data-classno={addressChildNo}
                alt=""
                className={styles.delete_records_btn}
              />
            </button>
          )}
        </div>
      </div>
    );
  }
}
export default Newcode;
