import axios from "axios";
import { awsUrl, awsUrl2 } from "../config";
import { getHeader } from "../utils/common";
// getting the header

export function getExpModStateCategory(stateList) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/getExpModStateCategory",
        JSON.stringify({ states: stateList }, header)
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        reject("Error in API request:" + error);
      });
  });
}

export function getStateAndCity(zipCode, stateList) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/getStateAndCity/" + zipCode,
        JSON.stringify({ stateList }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function raterMcpStateData(stateList, programList) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/raterMcpStateData",
        JSON.stringify({ stateList, programList }),
        header
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getDomainInputData(domain) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + "/api/getDomainInputData/" + domain, header)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getUserDataStatus(user, type) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl + "/api/getUserDataStatus/" + user + `/${type}`, header)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function userTableData(userDetails, type) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + `/api/userTableData/${type}`, userDetails, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function updateUserStatusTable(requestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/updateUserStatusTable", requestBody, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getScheduleDebitCreditRange(state) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl + "/api/getScheduleDebitCreditRange/" + state, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function postTablesData(tableName, data) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + "/api/postTablesData/" + tableName,
        JSON.stringify(data),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getQuoteDetails(etQuoteDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/getQuoteDetails", etQuoteDetails, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function generateQuotePDF(getQuoteDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + "/api/generateQuotePDF",
        JSON.stringify(getQuoteDetails),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getRecalculateQuoteId(dataBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + `/api/getRecalculateQuoteId`,
        JSON.stringify({ body: dataBody }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getRecalculateQuote(id) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl + `/api/getRecalculateQuote/${id}`, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function reCalculateQuote(body) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/reCalculateQuote", body, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function underWriterData(data, type) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + `/api/underWriterData/${type}`, data, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function raterTableData(req_body, type) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + `/api/raterTableData/${type}`, req_body, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getAgentBindRatio(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getAgentBindRatio/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function updateSolveTrackingDataGeneric(requestBody, type) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + `/api/updateSolveTrackingDataGeneric/${type}`,
        JSON.stringify(requestBody),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function updateSolveTrackingData(requestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/updateSolveTrackingData",
        JSON.stringify(requestBody),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getNetRateAndManualrate(request) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/getNetRateAndManualrate", request, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getCcodes(state, value) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(
        awsUrl2 + "/api/getCcodes/" + state?.toLocaleLowerCase() + "/" + value,
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function deleteProsepectData(userName) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .delete(awsUrl + "/api/deleteProsepectData/" + userName, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function verifyDemoRedirectUuid(id, path) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(
        demoAuthUrl + `/api/verifyDemoRedirectUuid/${id}/libertate/${path}`,
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getNoLossData(requestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/getNoLossData", JSON.stringify(requestBody), header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getMapCarrierAll(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getMapCarrierAll/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getAggregateData(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getAggregateData/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function sendCallbackMail(email, name, phone, type) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/sendCallbackMail",
        JSON.stringify({ email, name, phone, type }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getStateCodesAndDesc(state) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + `/api/getStateCodesAndDesc/${state}`, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function setQuoteStatusfucntion(setQuoteStatus) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + "/api/setQuoteStatus",
        JSON.stringify(setQuoteStatus),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function generateProposal(etQuoteDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/generateProposal",
        JSON.stringify(etQuoteDetails),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getLossRunForm(lossRunData) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/getLossRunForm", JSON.stringify(lossRunData), header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function generateBindForm(body) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/generateBindForm/", body, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function sendStatusEmail({
  sendEmailTo,
  sendEmailFrom,
  company_name,
  contact_person,
  effective_date,
  phone_number,
  user_email_id,
  emod,
}) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/sendStatusEmail",
        JSON.stringify({
          sendEmailTo,
          sendEmailFrom,
          company_name,
          contact_person,
          effective_date,
          phone_number,
          user_email_id,
          emod,
        }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function generateAccordForm(etQuoteDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/generateAccordForm/", etQuoteDetails, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getCarrierDataStatus() {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(
        awsUrl + "/api/getCarrierDataStatus/" + "carrier_n@gmail.com",
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getQuotesExpiry(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getQuotesExpiry/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getExpiry(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getExpiry/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function updateUsersData(data2send) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/updateUsersData/", data2send, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getPreUnderwriter() {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + "/api/getPreUnderwriter", header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function updateEngagedWithEmailCampaign(domain) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/updateEngagedWithEmailCampaign/" + domain,
        JSON.stringify(domain),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getCarrierQuotes(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getCarrierQuotes/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getCarrierPremium(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getCarrierPremium/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getPie(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getPie/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getBarGraphAll(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getBarGraphAll/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getBarGraph(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getBarGraph/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getPremiumByCarrier(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getPremiumByCarrier/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getQuotesBoundRatio(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getQuotesBoundRatio/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getTotalQuotedPremium(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(dashboardUrl + "/api/getTotalQuotedPremium/" + user, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

// amtrust Api call
export function getAmtrustData(uuid, apiType) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + `/api/getAmtrustData/${uuid}/${apiType}`, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
