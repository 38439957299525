import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { Link } from "react-router-dom";
import styles from "./DropZones.module.scss";

class CustomDropZone extends React.Component {
  render() {
    let {
      fileList,
      updateFileList,
      removeFile,
      heading,
      uploadMsg,
      flag,
      downloadFileName,
    } = this.props;
    let listToShow = [];

    for (let fileName in fileList) {
      listToShow.push(
        <li
          className="list-group-item d-flex justify-content-between"
          key={fileName}
        >
          <span>{fileName}</span>
          {fileList[fileName] !== "#empty" && uploadMsg !== "files uploaded" && (
            <span
              className="fw-bold"
              onClick={() => removeFile(fileName, flag)}
            >
              {"X"}
            </span>
          )}
        </li>
      );
    }

    return (
      <div className={styles.customDropZone}>
        <div className="d-flex justify-content-between align-items-center">
          <h6 className="m-0">{heading}</h6>
          <Link
            to={"/" + downloadFileName}
            target="_blank"
            className="d-flex align-items-center "
            download
          >
            <span>Download reference file</span>
            <div className={styles.downloadImage}>⬇</div>
          </Link>
        </div>
        <DropZone updateFileList={updateFileList} flag={flag} />
        {<ul className="list-group list-group-flush">{listToShow}</ul>}
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(file, props.flag);
    });
  });
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps({ className: styles.dropzone })}>
      <input {...getInputProps()} />
      <span>{"📂"}</span>
      <p className={styles.dropZoneText}>Drag &amp; drop or browse to select file</p>
    </div>
  );
};

export default CustomDropZone;
