import React from "react";
import carrierList from "../../utils/carrierList.json";

class Tab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      Statechange: "",
      NcciChnage: "",
    };
  }

  componentDidMount() {
    let {
      carrierSpecific = {},
      tabName,
      not_include_in_ncci = [],
    } = this.props;

    let carreierSelected = JSON.parse(
      JSON.stringify(carrierList.carrierMapping)
    );
    let stateCheck = carrierSpecific[tabName] || [];

    let ncciCheck = false;
    for (let key of stateCheck) {
      if (not_include_in_ncci.includes(key)) {
        ncciCheck = false;
      } else {
        ncciCheck = true;
        break;
      }
    }

    for (let _state of not_include_in_ncci) {
      if (stateCheck.includes(_state)) {
        this.setState({ NcciChnage: "" });
        this.setState({ StateChange: "bg-secondary text-white" });
      }
    }

    if (ncciCheck) {
      this.setState({ StateChange: "" });
      this.setState({ NcciChnage: "bg-secondary text-white" });
    }
  }
  handleTabClick = () => {
    const { tabName, handleTabClick } = this.props;
    handleTabClick(tabName);
  };

  stateSelectedChange = () => {
    this.setState({ NcciChnage: "" });
    this.setState({ StateChange: "bg-secondary text-white" });
  };

  NcciSelectedChange = () => {
    this.setState({ StateChange: "" });
    this.setState({ NcciChnage: "bg-secondary text-white" });
  };

  render() {
    let {
      carrierSpecific = {},
      activeTab,
      tabName,
      updateAcordSelectedState,
      state_to_show,
      not_include_in_ncci = [],
    } = this.props;

    let { NcciChnage, StateChange } = this.state;

    if (state_to_show === "Ncci" && !NcciChnage) this.NcciSelectedChange();
    else if (not_include_in_ncci.includes(state_to_show) && !StateChange)
      this.stateSelectedChange();

    let currProspect = sessionStorage.getItem("currProspect");
    try {
      currProspect = JSON.parse(currProspect);
    } catch (error) {
      currProspect = {};
    }

    let demoPeo = currProspect?.peoDetails?.selectedPeo === "demo";
    // ? true : false;

    let className = "tab-list-item ";
    let carreierSelected = JSON.parse(
      JSON.stringify(carrierList.carrierMapping)
    );
    let showList = false;
    if (activeTab === tabName) {
      showList = true;
      className += "tabSelected";
    }
    let originalTabName = tabName;

    let stateCheck = carrierSpecific[tabName] || [];

    let ncciCheck = false;
    for (let key of stateCheck) {
      if (not_include_in_ncci.includes(key)) {
        ncciCheck = false;
      } else {
        ncciCheck = true;
        break;
      }
      console.log(key, "?++++++");
    }

    let list_to_show = [];

    for (let _st of not_include_in_ncci) {
      if (stateCheck.includes(_st)) {
        list_to_show.push(
          <li
            onClick={() => {
              updateAcordSelectedState(tabName, _st.toUpperCase());
              this.handleTabClick();
              this.stateSelectedChange();
            }}
            className={
              state_to_show?.toUpperCase() === _st.toUpperCase()
                ? StateChange
                : ""
            }
            key={tabName}
            style={{ display: "inline-flex" }}
          >
            {_st.toUpperCase()}
          </li>
        );
      }
    }

    return (
      <>
        <span style={{ display: "inline-flex", flexDirection: "column" }}>
          <li onClick={this.handleTabClick} className={className} key={tabName}>
            <span className="TabNameSelected">
              {demoPeo ? tabName : carreierSelected[tabName]}
            </span>
          </li>
          {showList && (
            <ol
              className="acordSelectedList tab-list-item pl-0"
              style={{ listStyleType: "none" }}
            >
              {ncciCheck ? (
                <li
                  onClick={() => {
                    updateAcordSelectedState(tabName, "Ncci");
                    this.handleTabClick();
                    this.NcciSelectedChange();
                  }}
                  className={NcciChnage}
                  style={{
                    display: "inline-flex",
                    margin: "0px",
                    padding: "0px",
                  }}
                  key={tabName}
                >
                  Ncci
                </li>
              ) : (
                " "
              )}

              {list_to_show}
            </ol>
          )}
        </span>
      </>
    );
  }
}

export default Tab;
