import React, { Component } from "react";
import { Form } from "react-bootstrap";
import Datepick from "../../common/Datepick";
import { NumericFormat } from "react-number-format";
import stateMap from "../../../utils/state_map.json";
const moment = require("moment");
import styles from "./EmodQuestions.module.scss";

class EmodQuestions extends Component {
  constructor(props) {
    super(props);
    this.state = {
      emodData: props.emodData,
      state: props.state,
    };
  }

  componentDidUpdate = () => {
    if (this.state.emodData !== this.props.emodData) {
      this.setState({ emodData: this.props.emodData });
    }
  };
  render() {
    const { emodData, state } = this.state;
    const { updateEmodInfo, dontShowAddEmodSign } = this.props;

    let displayState;
    if (state === "ncci") {
      let stateListCaps = emodData.stateList.map((e) => e.toUpperCase());
      displayState = `NCCI states (${stateListCaps})`;
    } else {
      displayState = stateMap[state.toUpperCase()];
    }
    let question = `Experience modification factor/(s)`;

    return (
      <div
        className={`row align-items-center mt-2 mb-2 ${styles.row} ${styles.emod_section_ques}`}
      >
        <div
          className={`${styles.tableQues} ${styles.font_family_montserrat_semi_bold} col-sm-7`}
        >
          {question}
        </div>
        {/* {<div className="tableAns col-sm-2">
          <Form.Check
            inline
            value="Y"
            checked={emodData.response}
            label="Yes"
            type="radio"
            onChange={() => updateEmodInfo(state, "response", true)}
          />
          <Form.Check
            inline
            value="N"
            checked={!emodData.response}
            label="No"
            type="radio"
            onChange={() => updateEmodInfo(state, "response", false)}
          />
        </div>} */}
        <div className={`${styles.font_family_montserrat_semi_bold} col-sm-1`}>
          {state.toUpperCase()}
        </div>
        <div className={`${styles.tableRemarks} d-flex flex-column col-sm-4`}>
          <div className={`d-flex row ${styles.row} ${styles.emod_ques_date}`}>
            <NumericFormat
              id="rate-one"
              autoComplete="off"
              className="ques-input col-sm-4 mr-1 my-1"
              // className={
              //   !emodData.response
              //     ? "hidden ques-input"
              //     : "ques-input col-sm-4 mr-1"
              // }

              name="rate-one"
              // fixedDecimalScale={true}
              // decimalScale={2}
              type="text"
              style={{
                borderColor:
                  emodData.rate1 && emodData.rate1.error ? "red" : "",
              }}
              onChange={(e) => {
                updateEmodInfo(state, "rate1", e.target.value);
              }}
              onBlur={(e) => {
                if (e.target.value) {
                  if (this.props.getAllRates) {
                    this.props.getAllRates();
                  }
                  updateEmodInfo(state, "response", true);
                } else {
                  updateEmodInfo(state, "response", false);
                }
              }}
              value={emodData.rate1 ? emodData.rate1.value : ""}
              disabled={emodData.default === "N"}
              placeholder="Rate"
            />
            <div
              id="dateRate1"
              style={{
                borderColor:
                  emodData.date1 && emodData.date1.error ? "red" : "",
              }}
              className={`ques-input ${styles.emodDate} col-sm-6 my-1`}
            >
              <Datepick
                state={state}
                dateNo={"date1"}
                date={moment(emodData.date1.value)}
                updateEmodInfo={updateEmodInfo}
              />
            </div>
            {!dontShowAddEmodSign &&
              !emodData.displayMore &&
              emodData.response && (
                <div className="mt-2 col-sm-1">
                  <a
                    id="addInput"
                    className="ques-input"
                    onClick={() => {
                      updateEmodInfo(state, "displayMore", true);
                    }}
                  >
                    <img src={require("../../../images/plus4.png")} alt="add" />
                  </a>
                </div>
              )}
          </div>

          {emodData.response && (
            <>
              {emodData.displayMore && (
                <div id="extraInput" class="d-flex row">
                  <input
                    id="rate-two"
                    className="ques-input1 col-sm-4 mr-1 my-1"
                    name="rate-one"
                    fixedDecimalScale={true}
                    decimalScale={2}
                    suffix="%"
                    type="text"
                    placeholder="Rate"
                    autocomplete="off"
                    style={{
                      borderColor:
                        emodData.rate2 && emodData.rate2.error ? "red" : "",
                    }}
                    onChange={(e) =>
                      updateEmodInfo(state, "rate2", e.target.value)
                    }
                    value={emodData.rate2 ? emodData.rate2.value : ""}
                  />
                  <div
                    id="dateRate2"
                    className={`ques-input1 ${styles.emodDate} col-sm-6 my-1`}
                  >
                    <Datepick
                      state={state}
                      dateNo={"date2"}
                      date={moment(emodData.date2.value)}
                      updateEmodInfo={updateEmodInfo}
                    />
                  </div>
                  <div className="mt-2 col-sm-1">
                    <a
                      id="addInput"
                      className="ques-input"
                      onClick={() => {
                        updateEmodInfo(state, "displayMore", false);
                      }}
                    >
                      <img
                        src={require("../../../images/delete4.png")}
                        alt="remove"
                      />
                    </a>
                  </div>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    );
  }
}

export default EmodQuestions;
