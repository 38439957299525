import React from "react";
import { useEffect, useState } from "react";
import numeral from "numeral";
import Select from "react-select";
import Clamp from "react-multiline-clamp";
import carrierList from "../../../../utils/carrierList.json";
import { useLibertateContext } from "../../../../context/ContextProvider";
const carrierListMapping = carrierList.carrierMapping;
import { LibertateContext } from "../../../../context/Context";
import style from "./RiskProfile.module.scss";

// if you import this Riskprofile function. you need to send the userData which has currprospect details as props.
const RiskProfile = (props) => {
  let commonPercentage = 100;
  const [data, setData] = useState({});
  const [carrierDropdown, setCarrierDropdown] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState();
  const [selectedPremium, setSelectedPremium] = useState({
    value: "manual_premium",
    label: "Manual Premium",
  });
  const [premiumDropdown, setPremiumDropdown] = useState([
    {
      value: "manual_premium",
      label: "Manual Premium",
    },
  ]);

  let libertateContext = useLibertateContext();

  useEffect(() => {
    try {
      let currProspect = libertateContext.currProspect;
      let { riskProfile } = currProspect;
      if (riskProfile) {
        setData(riskProfile);
      }
    } catch (error) {
      console.log("risk profile not available");
    }
  }, [libertateContext?.currProspect?.riskProfile]);

  // once we get the data we call the setcarrierlist function which create the dropdown list for selected program
  useEffect(() => {
    setCarrierList();
  }, [data]);

  //prepare the dropdown for selected program.
  const setCarrierList = () => {
    let carrierList = [];
    let carrierMap = {};
    let peoDetails = {};
    try {
      peoDetails = libertateContext.currProspect.peoDetails;
    } catch (error) {}

    for (let key in data) {
      carrierList.push({
        label:
          key && peoDetails?.selectedPeo !== "demo"
            ? carrierListMapping[key]
            : key?.toUpperCase(),
        value: key,
      });
    }

    carrierList.sort((a, b) => (a.label > b.label ? 1 : -1));
    setCarrierDropdown(carrierList);
  };

  const createTable = (table, allTableData) => {
    let rows = [];
    let tableDetailsMap = {
      eligibility: {
        tableDataKey: "eligibilityData",
        tableName: "Eligibility Profile",
        tableHeader: "Eligibility",
      },
      sic: {
        tableDataKey: "sicData",
        tableName: "SIC Group Profile",
        tableHeader: "Industry Classification",
      },
      hazard: {
        tableDataKey: "hazardData",
        tableName: "Best Hazard Profile",
        tableHeader: "Best Composite",
      },
      naics: {
        tableDataKey: "naicsData",
        tableName: "NAICS Group Profile",
        tableHeader: "Industry Classification",
      },
      state: {
        tableDataKey: "stateData",
        tableName: "State Summary",
        tableHeader: "States",
      },
    };

    if (table in tableDetailsMap) {
      let tableData = allTableData[tableDetailsMap[table].tableDataKey];
      tableData = sortTableData(tableData);
      for (let key in tableData) {
        rows.push(
          <tr key={table + "_" + key + "row"} className={style.table_rows}>
            <td className={style.key} title={key}>
              <Clamp lines={1}>{key}</Clamp>
            </td>
            <td className={style.payroll}>
              {numeral(tableData[key].payroll).format("$0,0")}
            </td>
            <td className={style.premium}>
              {numeral(tableData[key].manualPremium).format("$0,0")}
            </td>
            <td className={style.mix_rate}>
              {(tableData[key].mixRate * 100).toFixed(2)}%
            </td>
            <td className={style.wage_mix}>
              {(tableData[key].wageMix * 100).toFixed(2)}%
            </td>
            <td className={style.premium_mix}>
              {(tableData[key].premiumMix * 100).toFixed(2)}%
            </td>
          </tr>
        );
      }

      rows.push(
        <tr key={table + "_" + "total"} className={style.totals_row}>
          <td className={style.Total_riskprofile}>Total</td>
          <td>{numeral(allTableData.totalData.payroll).format("$0,0")}</td>
          <td>
            {numeral(allTableData.totalData.manualPremium).format("$0,0")}
          </td>
          <td>{(allTableData.totalData.mixRate * 100).toFixed(2)}%</td>
          <td>{commonPercentage.toFixed(2)}%</td>
          <td>{commonPercentage.toFixed(2)}%</td>
        </tr>
      );

      return (
        <div className={style.riskProfile_table_responsive}>
          <table
            key={table + "_table"}
            className={`table ${style[`${table}_table`]}`}
          >
            <tr className={style.table_header}>
              <th rowSpan={rows.length + 1} className={style.table_name}>
                {tableDetailsMap[table].tableName}
              </th>
              <th className={style.key_header}>
                {tableDetailsMap[table].tableHeader}
              </th>
              <th className={style.payroll_header}>Est. Payroll</th>
              <th className={style.premium_header}>Man. Prem.</th>
              <th className={style.mix_rate_header}>Mix Rate</th>
              <th className={style.wage_mix_header}>Payroll Mix</th>
              <th className={style.premium_mix_header}>Prem Mix</th>
            </tr>
            {rows}
          </table>
        </div>
      );
    }
  };

  const sortTableData = (tableData) => {
    let response = {};
    let sortList = [];
    try {
      for (let key in tableData) {
        sortList.push({
          key,
          ...tableData[key],
        });
      }
      sortList.sort((a, b) => a.manualPremium - b.manualPremium);
      for (let element of sortList) {
        let key = element.key;
        delete element.key;
        response[key] = element;
      }
    } catch (error) {
      console.log(error);
      response = tableData;
    }

    return response;
  };

  const sortTables = (carrierData) => {
    let response = {};
    let sortOrder = ["eligibility", "sic", "naics", "hazard", "state"];
    try {
      for (let key of sortOrder) {
        if (key in carrierData) {
          response[key] = carrierData[key];
        }
      }
    } catch (error) {
      console.log(error);
      response = carrierData;
    }

    return response;
  };

  // Tables that we show on select of dropdown.
  const createAllTables = (carrier) => {
    let tables = [];
    if (carrier in data) {
      let carrierData = JSON.parse(JSON.stringify(data[carrier]));
      carrierData = sortTables(carrierData);
      for (let tableKey in carrierData) {
        tables.push(createTable(tableKey, carrierData[tableKey]));
        tables.push(<div key={tableKey + "spacing"} className="mt-5"></div>);
      }
    } else if (carrier) {
      tables = <div className="mt-4">No data for the selected carrier</div>;
    } else {
      tables = <div className="mt-4">Please select a carrier</div>;
    }
    return tables;
  };

  // select Program DropDown
  const SelectProgramDropDown = () => {
    return (
      <div className="d-flex align-items-center">
        <label className="font-weight-bold pl-3">Select Program</label>
        <div className="pl-3">
          <Select
            placeholder={"Please Select"}
            value={selectedCarrier}
            className={`${style.carrier_dropdown}  ${style.dropdown}`}
            options={carrierDropdown}
            onChange={(selectOption) => {
              setSelectedCarrier(selectOption);
            }}
          />
        </div>
      </div>
    );
  };
  // premiun Type DropDown
  const PremiumTypeDropDown = () => {
    return (
      <div className="d-flex align-items-center">
        <label className="font-weight-bold pl-5">Premium Type</label>
        <div className="pl-3">
          <Select
            isDisabled={true}
            placeholder="Manual Premium"
            className={`${style.premium_dropdown} ${style.dropdown}`}
            value={selectedPremium}
            options={premiumDropdown}
            onChange={(selectOption) => setSelectedPremium(selectOption)}
          />
        </div>
      </div>
    );
  };

  return (
    <div>
      <div
        className={`${style.pink_header} font-family-montserrat-bold`}
        style={{ fontSize: "x-large" }}
      >
        Risk Profile
      </div>
      <div id={style.riskprofile_table} className="container-fluid">
        <div className="row no-gutters pb-5">
          <div className={`d-flex ${style.dropdown__container}`}>
            <SelectProgramDropDown />
            <PremiumTypeDropDown />
          </div>
        </div>

        {createAllTables(selectedCarrier?.value)}
      </div>
    </div>
  );
};
export default function RiskProfileWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => <RiskProfile userData={context.userData} />}
    </LibertateContext.Consumer>
  );
}
