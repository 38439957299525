import React, { Component } from "react";
import numeral from "numeral";
import { LibertateContext } from "../../../context/Context";
import {
  deleteAllCookies,
  showErrAlert,
  blobPdfFromBase64String,
  clearErrHistoricalClaims,
} from "../../../utils/common";
import {
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
  handleCheck,
} from "../../../utils/form_cmprRates";
import QuoteTable from "../../common/QuoteTable";
import Tabs from "../../common/Tabs";
import NewProgress from "../../../newcomponent/common/NewProgress";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import carrierPeoProgramList from "../../../utils/carrierList.json";
import HistoricalClaims from "../HistoricalClaims/HistoricalClaims";
import GenericDropZone from "../GenericDropZone/GenericDropZone";

import { isDeepEquals } from "../../../utils/isDeepEquals";

import {
  userTableData,
  updateUserStatusTable,
  generateQuotePDF,
  updateSolveTrackingDataGeneric,
  getNoLossData,
} from "../../../api/commonApi";

import {
  generateAcordNcci,
  generateAcordCA,
  generateAcordFL,
} from "../../../api/formCmprQuoteApi";
import styles from "./FormCmprQuote.module.scss";

let mono_states = require("../../../utils/mono_states.json");
const not_include_in_ncci = ["oh", "wa", "wy", "nd", "fl", "ca"];

let isMobile = window.innerWidth < 1000;
class FormCmprQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      visible: false,
      uuid_carrier: [],
      fileURL_Map: {},
      fileURL_Map1: {},
      fileURL2: undefined,
      loadingAccord: {},
      isNoLossChckd: false,
      loadingNoLoss: false,
      selectedCarrier: "",
      historicalClaimsError: false,
      historicalClaims: {},
      historicalClaimsCheck: {},
      historicalClaimsErrorStore: {},
      emodStatesData: {},
      peoData: {},
      show_acord: {},
      acordSelectedState: {},
      carrierSpecific: [],
      stateRecalculateObject: {},
      acordIncludPremium: {},
      latest_ncci_key_program: {},
      uploadDocsClicked: {
        loss_run: false,
        other_docs: false,
      },
      loadingEAcord: false,
      fileList: {
        other_docs: {},
        loss_run: {},
      },
    };
    this.getAllDataWRTCarrier = this.getAllDataWRTCarrier.bind(this);
    this.open = this.open.bind(this);
    this.close = this.close.bind(this);
    this.QuoteView = this.QuoteView.bind(this);
    this.handleCheck = handleCheck.bind(this);
    this.clearErr = clearErrHistoricalClaims.bind(this);
  }

  setLoadingEAcord = (loading) => {
    this.setState({ loadingEAcord: loading });
  };

  componentDidMount = () => {
    let _curr_user_status_data = this.props.userStatusData || {};
    let formStage = this.props.formStage;
    if (this.isValidStage(formStage)) {
      this.fillQuotesPage(_curr_user_status_data);
    }
  };

  componentDidUpdate = (prevProps) => {
    let formStage = this.props.formStage;

    let _curr_user_status_data = this.props.userStatusData || {};
    let _prev_user_status_data = prevProps.userStatusData || {};

    if (
      this.isValidStage(formStage) &&
      (!isDeepEquals(_curr_user_status_data, _prev_user_status_data) ||
        (this.props.getQuoteClicked &&
          Object.keys(_curr_user_status_data).length > 0))
    ) {
      this.props.setContextData("getQuoteClicked", false);
      this.fillQuotesPage(_curr_user_status_data);
    }
  };

  isValidStage = (stage) => {
    return stage === "five" ||
      stage === "six" ||
      stage === "three" ||
      stage === "four"
      ? true
      : false;
  };

  fillQuotesPage = (dataFromAPI) => {
    try {
      let peoData = {};
      let currProspectDetails = this.props.currProspect;

      if (currProspectDetails !== null) {
        let peo_value;
        try {
          if (dataFromAPI && Object.keys(dataFromAPI).length) {
            let quoteTableData = {};
            let perStateQuoteTableData = {};
            let programs = [];
            let fileListToStore;
            let historicalClaims,
              historicalClaimsCheck,
              isNoLossChckd,
              startDate;
            let masterWithoutFundRateMap = {};
            let programStateType = {};
            let currentCarrierMap = {};
            let excludedClassCodeMap = {};
            let stateCarrierData = {};
            let uw_net_rates;
            let _uwFileList;
            let carrierBasedData = {};

            let statearray = [];
            let sdr_factor = {};
            let quoteSubmitted = false;
            let submissionDate;

            // creating the specific carrierspecific state
            let newObj = {};
            let secondObj = {};
            let newarray = [];

            let states_has_sdr_in_algo = [];

            let _locations = [];
            let _quoteData = {};

            let fileList = {
              other_docs: {},
              loss_run: {},
            };

            //set stateCarrierData : for status and comments on UW page.
            if (dataFromAPI.stateCarrierData) {
              stateCarrierData = dataFromAPI.stateCarrierData;
            }
            if (dataFromAPI.carrierBasedData) {
              carrierBasedData["data"] = dataFromAPI.carrierBasedData;
              carrierBasedData["uuid"] = dataFromAPI?.uuid;
            }
            this.props.setCarrierBasedData(carrierBasedData);

            for (let location in dataFromAPI.carrierBasedData) {
              _locations.push(location);
              for (let peo in dataFromAPI.carrierBasedData[location]) {
                let state_payroll;
                ({
                  historicalClaims,
                  historicalClaimsCheck,
                  isNoLossChckd,
                  startDate,
                  state_payroll,
                  sdr_factor,
                  quoteSubmitted,
                  submissionDate,
                  fileList: fileListToStore,
                  uwFileList: _uwFileList,
                } = dataFromAPI.carrierBasedData[location][peo]);

                if (quoteSubmitted && submissionDate) {
                  this.setState({ quoteSubmitted: true });
                  this.props.setSubmissionDate(submissionDate);
                }

                if (fileListToStore) {
                  for (let row in fileListToStore) {
                    if (!fileList[fileListToStore[row].keyToFile])
                      fileList[fileListToStore[row].keyToFile] = {};
                    fileList[fileListToStore[row].keyToFile][
                      fileListToStore[row].key.split("/").reverse()[0]
                    ] = "#empty";
                  }
                }

                if (_uwFileList) {
                  let fileList = {
                    third: {},
                  };

                  for (let row in _uwFileList) {
                    if (!fileList[_uwFileList[row].keyToFile])
                      fileList[_uwFileList[row].keyToFile] = {};
                    fileList[_uwFileList[row].keyToFile][
                      _uwFileList[row].key.split("/").reverse()[0]
                    ] = "#empty";
                  }
                  this.props.setUwFileList(fileList);
                }

                if (!peo_value) {
                  peo_value = peo;
                }

                if (
                  dataFromAPI.carrierBasedData[location][peo]["uw_net_rates"]
                ) {
                  uw_net_rates =
                    dataFromAPI.carrierBasedData[location][peo]["uw_net_rates"];
                }

                for (let carrier in dataFromAPI.carrierBasedData[location][peo]
                  .carrier_location_data) {
                  let totalPayrollStateWise = 0;
                  let {
                    state,
                    program,
                    masterOrMcp: stateType,
                    total_estimated_annual_premium: teap,
                    employees,
                    net_rate_values: netRates,
                    fundRateOrigin,
                    manual_rate_values,
                    manual_premium_values,
                    net_rate_values,
                    scheduled_rating_factor,
                  } = dataFromAPI.carrierBasedData[location][peo]
                    .carrier_location_data[carrier];
                  statearray.push({
                    carrier: carrier,
                    state: state,
                  });

                  if (!(carrier in stateCarrierData)) {
                    stateCarrierData[carrier] = {};
                  }

                  if (!(state in stateCarrierData[carrier])) {
                    stateCarrierData[carrier][state] = {
                      comments: "",
                      status: "select",
                      debit_credit: {
                        isChecked: false,
                        value: "",
                      },
                      isChecked: false,
                    };
                  }

                  if (
                    scheduled_rating_factor ||
                    scheduled_rating_factor === 0
                  ) {
                    states_has_sdr_in_algo.push({
                      state,
                    });
                  }

                  let ccEligData =
                    dataFromAPI.carrierBasedData[location][peo]
                      ?.classCodeEligibility?.[carrier];
                  if (ccEligData) {
                    for (let classCode in ccEligData) {
                      if (ccEligData[classCode] === "excluded") {
                        if (!excludedClassCodeMap[carrier]) {
                          excludedClassCodeMap[carrier] = {};
                        }
                        if (!excludedClassCodeMap[carrier][state]) {
                          excludedClassCodeMap[carrier][state] = [];
                        }
                        excludedClassCodeMap[carrier][state].push(classCode);
                      }
                    }
                  }

                  if (!(carrier in currentCarrierMap)) {
                    currentCarrierMap[carrier] = false;
                  }

                  //THIS SECTION IS FOR CALCULATING DATA FOR TABLE SHOWN IN QUOTE PDF
                  //**table calculations starts here **/
                  if (employees) {
                    if (!(program in peoData)) {
                      peoData[program] = {};
                    }
                    let peoStateDataList = [];

                    for (let eIndx in employees) {
                      try {
                        let currObj = {};

                        currObj.state = state;
                        currObj.eligibility =
                          dataFromAPI?.carrierBasedData?.[location]?.[peo]
                            ?.classCodeEligibility?.[carrier]?.[
                            employees[eIndx].code
                          ] || "";

                        currObj.wc_code = employees[eIndx].code;
                        currObj.wc_desc = employees[eIndx].desc;

                        currObj.manual_rate = manual_rate_values
                          ? manual_rate_values[employees[eIndx].code]
                          : 0;
                        currObj.net_rate = net_rate_values
                          ? net_rate_values[employees[eIndx].code]
                          : 0;

                        currObj.manual_premium = manual_premium_values
                          ? manual_premium_values[employees[eIndx].code]
                          : 0;

                        currObj.payroll =
                          employees[eIndx].original_payroll ||
                          employees[eIndx].payroll;

                        totalPayrollStateWise +=
                          Number(employees[eIndx].original_payroll) ||
                          Number(employees[eIndx].payroll) ||
                          0;

                        try {
                          currObj.teap = numeral(employees[eIndx].payroll)
                            .multiply(currObj.net_rate)
                            .divide(100)
                            .value();
                        } catch (error) {}

                        peoStateDataList.push(currObj);
                      } catch (error) {}
                    }
                    peoData[program][state] = peoStateDataList;
                  }
                  //**table calculations ends here **/

                  //THIS SECTION IS FOR CALCULATING QUOTE TABLE DATA
                  //**quote table calc starts here */
                  //Note: Its working under a peo->carrier based loop
                  let key = state;
                  let state_key = state;
                  let premium = teap ? Number(teap) : 0;

                  if (!programStateType[program]) {
                    programStateType[program] = {};
                  }

                  if (stateType === "master") {
                    key = "master";
                    if (!programStateType[program].master) {
                      programStateType[program].master = {};
                    }
                    if (fundRateOrigin === "emod") {
                      if (!masterWithoutFundRateMap[program]) {
                        masterWithoutFundRateMap[program] = [];
                      }
                      masterWithoutFundRateMap[program].push(state);
                      if (!programStateType[program].master.without_fundrate) {
                        programStateType[program].master.without_fundrate = [];
                      }
                      programStateType[program].master.without_fundrate.push(
                        state
                      );
                    } else if (fundRateOrigin === "fundrate") {
                      premium = this.getPremium(employees, netRates);
                      if (!programStateType[program].master.with_fundrate) {
                        programStateType[program].master.with_fundrate = [];
                      }
                      programStateType[program].master.with_fundrate.push(
                        state
                      );
                    }
                  } else {
                    programStateType[program][state] = "mcp";
                  }
                  if (mono_states.includes(state_key.toLowerCase()))
                    stateType = "mono";
                  if (!quoteTableData[state_key]) {
                    quoteTableData[state_key] = {
                      checked: { name: false },
                    };
                  }
                  if (!quoteTableData.total) {
                    quoteTableData.total = {
                      checked: { name: false },
                    };
                  }
                  if (!quoteTableData[state_key][program]) {
                    quoteTableData[state_key][program] = {
                      premium: 0,
                      payroll: 0,
                      state_type: stateType,
                    };
                    quoteTableData[state_key].checked[program] = false;
                  }
                  if (!quoteTableData.total[program]) {
                    quoteTableData.total[program] = {
                      premium: 0,
                      payroll: 0,
                    };
                    quoteTableData.total.checked[program] = false;
                  }
                  quoteTableData[state_key][program].premium += premium;

                  quoteTableData[state_key][program].payroll =
                    (state_key === "master"
                      ? quoteTableData[state_key][program].payroll
                      : 0) + totalPayrollStateWise;
                  quoteTableData.total[program].premium += premium;
                  quoteTableData.total[program].payroll +=
                    totalPayrollStateWise;

                  //prepare perStateQuoteTableData here
                  if (!perStateQuoteTableData[state_key]) {
                    if (mono_states.includes(state_key.toLowerCase()))
                      stateType = "mono";
                    perStateQuoteTableData[state_key] = {
                      checked: { name: false },
                      type: stateType,
                    };
                  }
                  if (!perStateQuoteTableData.total) {
                    perStateQuoteTableData.total = {
                      checked: { name: false },
                    };
                  }
                  if (!perStateQuoteTableData[state_key][program]) {
                    perStateQuoteTableData[state_key][program] = {
                      premium: 0,
                      payroll: 0,
                    };
                    perStateQuoteTableData[state_key].checked[program] = false;
                  }
                  if (!perStateQuoteTableData.total[program]) {
                    perStateQuoteTableData.total[program] = {
                      premium: 0,
                      payroll: 0,
                    };
                    perStateQuoteTableData.total.checked[program] = false;
                  }
                  perStateQuoteTableData[state_key][program].premium += premium;
                  perStateQuoteTableData[state_key][program].payroll =
                    totalPayrollStateWise;
                  perStateQuoteTableData.total[program].premium += premium;
                  perStateQuoteTableData.total[program].payroll +=
                    totalPayrollStateWise;

                  this.props.setPerStateQuoteTableData(perStateQuoteTableData);
                  if (!programs.includes(program)) {
                    programs.push(program);
                  }
                  //**quote table calc end here */
                }
              }
            }

            if (stateCarrierData && Object.keys(stateCarrierData).length > 0) {
              this.props.setStateCarrierData(stateCarrierData);
            }

            for (let key of statearray) {
              newarray.push(key.carrier);
            }

            let uniqueCarrier = new Set(newarray);

            for (let key1 of uniqueCarrier) {
              newObj[key1] = [];
              secondObj[key1] = [];
            }
            for (let key of statearray) {
              newObj[key.carrier].push(key.state);
            }

            //THIS SECTION IS FOR CHECKING FOR EXCLUDED CLASS CODES
            //**excluded cc starts here */
            if (
              excludedClassCodeMap &&
              Object.keys(excludedClassCodeMap || {}).length > 0
            ) {
              let excludedPopupDataList = [];
              for (let carrier in excludedClassCodeMap) {
                for (let state in excludedClassCodeMap[carrier]) {
                  for (let classCode of excludedClassCodeMap[carrier][state]) {
                    let value = `${state.toUpperCase()}-${classCode}`;
                    if (!excludedPopupDataList.includes(value)) {
                      excludedPopupDataList.push(value);
                    }
                  }
                }
              }
              this.props.updatePopUpData({
                show: true,
                title:
                  "Some class codes have been excluded for certain carriers!",
                disableNoButton: true,
                children: (
                  <>
                    <br></br>
                    <span style={{ fontWeight: "bold" }}>
                      {excludedPopupDataList.map((e) => (
                        <>
                          <div>{e}</div>
                        </>
                      ))}
                    </span>
                  </>
                ),
                acceptBtn: "OK",
                acceptBtnCallback: () => {
                  console.log("okay");
                },
              });
            }
            //**excluded cc ends here */

            let stateRecalculateObject = {};
            for (let key of states_has_sdr_in_algo) {
              if (sdr_factor && sdr_factor[key.state]) {
                let value = Number(sdr_factor[key.state]);
                stateRecalculateObject[key.state] = {
                  selected: value < 0 ? true : false,
                  value: Math.abs(value),
                };
              } else {
                stateRecalculateObject[key.state] = {
                  selected: false,
                  value: "",
                };
              }
            }

            if (dataFromAPI?.uuid?.length) {
              try {
                let _sort_key1 = dataFromAPI?.uuid?.[0];
                let _date_and_uuid = _sort_key1
                  .split("_")
                  .reverse()
                  .pop()
                  .split("@");
                _quoteData.date = _date_and_uuid[0];
                _quoteData.uuid = _date_and_uuid[1];
              } catch (error) {}
            }

            this.setState({
              quoteTableData,
              programs,
              masterWithoutFundRateMap,
              programStateType,
              peoData,
              startDate: startDate ? moment(startDate, "YYYY-MM-DD") : "",
              historicalClaims: historicalClaims || {},
              historicalClaimsCheck: historicalClaimsCheck || {},
              isNoLossChckd: isNoLossChckd || false,
              selectedPeo: peo_value,
              currentCarrierMap,
              excludedClassCodeMap,
              stateRecalculateObject,
              carrierSpecific: newObj,
              carrierWseSelectedState: secondObj,
              fileList,
            });

            this.props.setPrograms(programs);
            this.props.setProgramStateType(programStateType);
            this.props.setMasterWithoutFundRateMap(masterWithoutFundRateMap);
            this.props.setPeoData(peoData);
            this.props.setExcludedClassCodeMap(excludedClassCodeMap);
            this.props.setCurrentCarrierMap(currentCarrierMap);
            this.props.setEnableUnderwritingPage(true);
            this.props.setUwNetRates(uw_net_rates || {});
            this.props.setContextData("address", _locations);
            this.props.setContextData("sortKeyList", dataFromAPI.uuid);
            this.props.setContextData("quoteData", _quoteData);
            this.props.stopLoader();
          }
        } catch (error) {
          console.log("error: ", error);
          showErrAlert(error);
        }
      } else {
        this.props.setFormStage("one");
        deleteAllCookies();
        sessionStorage.clear();
      }
    } catch (error) {
      console.log("error in filling data in quotes page: ", error);
    }
  };

  setHistoricalClaimsErrorStore = (_historicalClaimsErrorStore) => {
    this.setState({ historicalClaimsErrorStore: _historicalClaimsErrorStore });
  };

  setHistoricalClaimsCheck = (_historicalClaimsCheck) => {
    this.setState({ historicalClaimsCheck: _historicalClaimsCheck });
  };

  setHistoricalClaims = (_historicalClaims) => {
    this.setState({ historicalClaims: _historicalClaims });
  };

  acordCheckboxHandler = (event, carrier, acordselected) => {
    console.log("acordselected: ", acordselected);
    console.log("carrier: ", carrier);
    let { acordIncludPremium, acordSelectedState } = JSON.parse(
      JSON.stringify(this.state)
    );
    acordIncludPremium[carrier][acordselected] =
      !acordIncludPremium?.[carrier]?.[acordselected];

    this.setState({ acordIncludPremium, acordSelectedState });
  };

  updateAcordSelectedState = async (carrier, state) => {
    let { acordSelectedState } = JSON.parse(JSON.stringify(this.state));
    acordSelectedState[carrier] = state;
    this.setState({ acordSelectedState });
  };

  updateSelectedCarrierMap = async (
    carr,
    val,
    program,
    state,
    state_value,
    selectedStateList
  ) => {
    try {
      let {
        currentCarrierMap,
        selectedPeo,
        show_acord,
        acordSelectedState,
        carrierSpecific,
        loadingAccord,
        quoteTableData,
        acordIncludPremium,
        carrierWseSelectedState,
        fileURL_Map,
        fileURL_Map1,
      } = JSON.parse(JSON.stringify(this.state));
      let sessionData = this.props.sessionData;

      let ncci_state_list = [];

      if (!selectedStateList) selectedStateList = [];

      carrierWseSelectedState[carr] = selectedStateList;

      currentCarrierMap[carr] = val;

      if (state && program) {
        if (quoteTableData[state][program]) {
          quoteTableData[state].checked[program] = state_value;
        }
      }

      if (!state && program) {
        for (let _state in quoteTableData) {
          if (quoteTableData[_state][program]) {
            quoteTableData[_state].checked[program] = val;
          }
        }
      }

      let ncciStateCheck = false;

      let stateList = carrierSpecific[carr];

      for (let state of selectedStateList) {
        if (!not_include_in_ncci.includes(state)) {
          ncciStateCheck = true;
          ncci_state_list.push(state);
        }
      }

      let acordStateList = [],
        non_ncci_available = false;
      // create a list of states to send to the acord API for the selected carrier

      for (let _state of not_include_in_ncci) {
        if (selectedStateList.includes(_state.toLowerCase())) {
          acordStateList.push(_state.toUpperCase());
          acordSelectedState[carr] = _state.toUpperCase();
          non_ncci_available = true;
        }
      }

      if (ncciStateCheck) {
        acordStateList.push("Ncci");
      }

      // create an object containig the selected carriers
      if (ncciStateCheck) {
        acordSelectedState[carr] = "Ncci";
      } else if (!non_ncci_available && !ncciStateCheck) {
        if (acordSelectedState[carr]) delete acordSelectedState[carr];
      }

      if (!show_acord) show_acord = {};

      if (selectedStateList.length > 0) show_acord[carr] = true;
      else delete show_acord[carr];

      let currProspect = this.props.currProspect;

      let producerDetails = {
        producerName:
          currProspect?.["companyProfile"]?.["producer"]?.["value"] ||
          "Paul Hughes",
        producerEmail: sessionData.attributes.email || " ",
        producerFax: sessionData.attributes["custom:fax"] || " ",
        producerPhone: sessionData.attributes["custom:phoneNo."] || " ",
        producerMobile: sessionData.attributes["custom:mobile"] || " ",
      };

      loadingAccord[carr] = true;

      this.setState(
        {
          show_acord,
          carrierWseSelectedState,
          selectedStateList,
          currentCarrierMap,
          quoteTableData,
          acordSelectedState,
          loadingAccord,
        },
        async () => {
          if (selectedStateList.length) {
            let promiseList = [];

            // load acord for all states of the selected carrier at once
            let stateList = carrierSpecific[carr];
            let acordStateList = [];

            // create a list of states to send to the acord API for the selected carrier
            for (let _state of not_include_in_ncci) {
              if (stateList.includes(_state.toLowerCase())) {
                acordStateList.push(_state.toUpperCase());
              }
            }

            if (ncciStateCheck) {
              acordStateList.push("Ncci");
            }

            for (let state of acordStateList) {
              let tempCarrierStateMap = {
                [carr]: state,
              };

              promiseList.push(
                this.generateAccordAndStore(
                  carr,
                  selectedPeo,
                  fileURL_Map,
                  show_acord,
                  producerDetails,
                  tempCarrierStateMap,
                  "false",
                  selectedStateList,
                  ncci_state_list.sort().join("_")
                )
              );
              promiseList.push(
                this.generateAccordAndStore(
                  carr,
                  selectedPeo,
                  fileURL_Map1,
                  show_acord,
                  producerDetails,
                  tempCarrierStateMap,
                  "true",
                  selectedStateList,
                  ncci_state_list.sort().join("_")
                )
              );
            }

            await Promise.all(promiseList).then((res) => {
              let { loadingAccord, latest_ncci_key_program } = JSON.parse(
                JSON.stringify(this.state)
              );

              for (let row of res) {
                let {
                  key,
                  selectedCarrier,
                  url,
                  showAcord,
                  state,
                  ncci_key,
                  excludeAnnualPremium,
                } = row;

                let map_to_use =
                  excludeAnnualPremium === "true" ? fileURL_Map1 : fileURL_Map;

                if (!map_to_use[selectedCarrier]) {
                  map_to_use[selectedCarrier] = {};
                }

                let value_to_use;

                if (state === "Ncci") {
                  value_to_use = map_to_use[selectedCarrier]?.["Ncci"] || {};
                  value_to_use[ncci_key] = url;
                } else value_to_use = url;

                map_to_use[selectedCarrier][state] = value_to_use;

                if (state === "Ncci") {
                  if (ncci_key) latest_ncci_key_program[carr] = ncci_key;
                  else delete latest_ncci_key_program[carr];
                }

                if (excludeAnnualPremium === "true") {
                  fileURL_Map1 = map_to_use;
                  acordIncludPremium[selectedCarrier] = {
                    CA: false,
                    FL: false,
                    Ncci: false,
                  };
                } else fileURL_Map = map_to_use;
              }

              loadingAccord[carr] = false;

              this.setState((prevState, props) => ({
                loadingAccord,
                acordIncludPremium,
                latest_ncci_key_program,
                fileURL_Map: { ...prevState.fileURL_Map, ...fileURL_Map },
                fileURL_Map1: { ...prevState.fileURL_Map1, ...fileURL_Map1 },
              }));
            });
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  getPremium = (employees, netRates) => {
    let premium = 0;
    try {
      for (let employee of employees) {
        let { code, payroll } = employee;
        premium += numeral(netRates[code])
          .multiply(payroll)
          .divide(100)
          .value();
      }
    } catch (error) {
      console.log(error);
    }

    return Math.round(premium);
  };

  open() {
    this.setState({
      visible: true,
    });
  }
  close() {
    this.setState({
      visible: false,
    });
  }
  QuoteView(event) {
    let carrierTemplate = event.target.id.split("#+");
    let carrier = carrierTemplate[0];
    let timestamp = carrierTemplate[1];
    let getQuoteDetails = {};
    let email = JSON.parse(sessionStorage.getItem("currProspect")).emailAddress;
    getQuoteDetails.email = email;
    getQuoteDetails.timestamp = timestamp;
    getQuoteDetails.carrier = carrier;

    generateQuotePDF(getQuoteDetails)
      .then((res) => {
        let pdf = res.data.data;
        let fileName = "Quote.pdf";
        let file = blobPdfFromBase64String(pdf);
        var fileURL = URL.createObjectURL(file);
        let newWindow = window.open("./QuotePDF", "_blank");
        newWindow.onload = () => {
          newWindow.location = fileURL;
        };
      })
      .catch((err) => {
        console.log("Err", err);
        showErrAlert("View Quote is not available due to error");
      });
  }

  generateAccordAndStore = async (
    selectedCarrier,
    selectedPeo,
    fileURL_Map,
    show_acord,
    producerDetails,
    acordSelectedState,
    excludeAnnualPremium,
    selectedStateList,
    ncci_key
  ) => {
    return new Promise((resolve, reject) => {
      let { currProspect, address, quoteData, currentUser } = this.props;

      let childrenLoc = currProspect?.childrenLoc;
      let companyName = currProspect?.companyProfile?.companyName?.value || "";
      let stateSet = new Set();
      let key;

      for (let row of carrierPeoProgramList.carrierPeoProgramList) {
        if (row.carrier === selectedCarrier) {
          key = `${companyName}-${row.actualCarrierName}-Acord 130`;
          break;
        }
      }

      for (let childrenLocIndx in childrenLoc) {
        let childrenLocObj = childrenLoc[childrenLocIndx];
        if (childrenLocObj.state && childrenLocObj.state.value) {
          stateSet.add(childrenLocObj.state.value);
        }
      }

      if (
        Object.keys(currProspect)?.length &&
        address?.length &&
        Object.keys(quoteData)?.length &&
        selectedStateList?.length
      ) {
        let _selectedStateList = [];
        let _address = [];
        let ncciStateCheck = false;

        for (let state of selectedStateList) {
          _selectedStateList.push(state.toUpperCase());
          for (let add of address) {
            if (add.includes(state.toUpperCase())) {
              _address.push(add);
            }
          }
        }

        for (let key of _selectedStateList) {
          if (not_include_in_ncci.includes(key)) {
            continue;
          } else {
            ncciStateCheck = true;
          }
        }

        let etQuoteDetails = {
          email: currentUser,
          timestamp: quoteData.date,
          carrier: selectedCarrier,
          mail_address: "",
          address: address,
          state: _selectedStateList,
          peo: selectedPeo,
          producerDetails,
        };

        if (excludeAnnualPremium === "true") {
          etQuoteDetails["excludeAnnualPremium"] = "true";
        }

        if (
          acordSelectedState[selectedCarrier] !== "CA" &&
          acordSelectedState[selectedCarrier] !== "FL" &&
          not_include_in_ncci.includes(
            acordSelectedState[selectedCarrier].toLowerCase()
          ) &&
          etQuoteDetails.state.includes(acordSelectedState[selectedCarrier])
        ) {
          let _state_to_send = acordSelectedState[selectedCarrier],
            _address_to_send = [];

          for (let add of address) {
            if (add.includes(_state_to_send)) {
              _address_to_send.push(add);
            }
          }

          etQuoteDetails.address = _address_to_send;
          etQuoteDetails.state = [_state_to_send];

          if (!fileURL_Map?.[selectedCarrier]?.[_state_to_send]) {
            generateAcordNcci(etQuoteDetails)
              .then((res) => {
                let pdf = res.data.data;
                let file = blobPdfFromBase64String(pdf);

                var fileURL = URL.createObjectURL(file);

                resolve({
                  key,
                  selectedCarrier,
                  url: fileURL,
                  showAcord: true,
                  excludeAnnualPremium,
                  state: _state_to_send,
                });
              })
              .catch((err) => {
                console.log("Err", err);
                showErrAlert("View Quote is not available due to error.");
                fileURL_Map[selectedCarrier] = "";
                resolve({
                  key,
                  selectedCarrier,
                  url: "",
                  showAcord: false,
                  excludeAnnualPremium,
                });
              });
          } else {
            resolve({
              key,
              selectedCarrier,
              url: fileURL_Map[selectedCarrier][_state_to_send],
              showAcord: true,
              state: _state_to_send,
              excludeAnnualPremium,
            });
          }
        } else if (
          acordSelectedState[selectedCarrier] == "CA" &&
          etQuoteDetails.state.includes("CA")
        ) {
          // with the Total Annaul Premium for CA state

          if (!fileURL_Map?.[selectedCarrier]?.["CA"]) {
            generateAcordCA(etQuoteDetails)
              .then((res) => {
                let pdf = res.data.data;
                let file = blobPdfFromBase64String(pdf);

                var fileURL = URL.createObjectURL(file);

                resolve({
                  key,
                  selectedCarrier,
                  url: fileURL,
                  showAcord: true,
                  excludeAnnualPremium,
                  state: "CA",
                });
              })
              .catch((err) => {
                console.log("Err", err);
                showErrAlert("View Quote is not available due to error.");
                fileURL_Map[selectedCarrier] = "";
                resolve({
                  key,
                  selectedCarrier,
                  url: "",
                  showAcord: false,
                  excludeAnnualPremium,
                });
              });
          } else {
            resolve({
              key,
              selectedCarrier,
              url: fileURL_Map[selectedCarrier]["CA"],
              showAcord: true,
              state: "CA",
              excludeAnnualPremium,
            });
          }
          console.log("excludeAnnualPremium", excludeAnnualPremium);
        } else if (
          acordSelectedState[selectedCarrier] == "FL" &&
          etQuoteDetails.state.includes("FL")
        ) {
          // with the Total Annaul Premium for FL state

          if (!fileURL_Map?.[selectedCarrier]?.["FL"]) {
            generateAcordFL(etQuoteDetails)
              .then((res) => {
                let pdf = res.data.data;
                let file = blobPdfFromBase64String(pdf);

                var fileURL = URL.createObjectURL(file);

                resolve({
                  key,
                  selectedCarrier,
                  url: fileURL,
                  showAcord: true,
                  state: "FL",
                  excludeAnnualPremium,
                });
              })
              .catch((err) => {
                console.log("Err", err);
                showErrAlert("View Quote is not available due to error.");
                fileURL_Map[selectedCarrier] = "";
                resolve({
                  key,
                  selectedCarrier,
                  url: "",
                  showAcord: false,
                  excludeAnnualPremium,
                });
              });
          } else {
            resolve({
              key,
              selectedCarrier,
              url: fileURL_Map[selectedCarrier]["FL"],
              showAcord: true,
              state: "FL",
              excludeAnnualPremium,
            });
          }
        } else if (
          ncciStateCheck &&
          acordSelectedState[selectedCarrier] == "Ncci"
        ) {
          if (!fileURL_Map?.[selectedCarrier]?.["Ncci"]?.[ncci_key]) {
            let _list_to_send = [];
            let _curr_list = etQuoteDetails.state;

            for (let st of _curr_list) {
              if (!not_include_in_ncci.includes(st.toLowerCase())) {
                _list_to_send.push(st);
              }
            }

            etQuoteDetails.state = _list_to_send;

            generateAcordNcci(etQuoteDetails)
              .then((res) => {
                let pdf = res.data.data;
                let file = blobPdfFromBase64String(pdf);

                var fileURL = URL.createObjectURL(file);

                resolve({
                  key,
                  selectedCarrier,
                  url: fileURL,
                  showAcord: true,
                  state: "Ncci",
                  ncci_key,
                  excludeAnnualPremium,
                });
              })
              .catch((err) => {
                console.log("Err", err);
                showErrAlert("View Quote is not available due to error.");
                fileURL_Map[selectedCarrier] = "";
                resolve({
                  key,
                  selectedCarrier,
                  url: "",
                  showAcord: false,
                  excludeAnnualPremium,
                });
              });
          } else {
            resolve({
              key,
              selectedCarrier,
              url: fileURL_Map[selectedCarrier]["Ncci"][ncci_key],
              showAcord: true,
              state: "Ncci",
              ncci_key,
              excludeAnnualPremium,
            });
          }
        } else {
          resolve({
            key,
            selectedCarrier,
            url: fileURL_Map[key],
            showAcord: true,
          });
        }
      } else {
        resolve({
          key,
          selectedCarrier,
          url: fileURL_Map[key],
          showAcord: true,
        });
      }
    });
  };

  generateNoLoss = async (date) => {
    let { currProspect } = this.props;

    if (currProspect) {
      this.setState({ loadingNoLoss: true });

      let cName = `${currProspect.companyProfile.firstName.value} ${currProspect.companyProfile.lastName.value}`;
      let requestBody = {
        company: currProspect.companyProfile.companyName.value,
        name: cName,
        dFrom: date,
        fein: currProspect.companyProfile.fein.value,
      };
      getNoLossData(requestBody)
        .then(async (res) => {
          let pdf = res.data.data;

          let file = blobPdfFromBase64String(pdf);

          var fileURL2 = URL.createObjectURL(file);

          this.setState({ fileURL2, loadingNoLoss: false });
        })
        .catch((err) => {
          showErrAlert("View Quote is not available due to error.");

          this.setState({ loadingNoLoss: false });
        });
    }
  };

  getAllDataWRTCarrier(carrier, data) {
    let calculated_data = "";
    let requireData = {};
    let places = [];
    let teapSum = 0;
    for (let addres in data) {
      requireData["status"] = data[addres].quote_status;
      requireData["timestamp"] = data[addres].date;
      places.push(addres);
      teapSum += Number(data[addres].teap);
      calculated_data = data[addres].calculated_data;
    }

    requireData["places"] = places;
    requireData["teapSum"] = teapSum;
    requireData["calculated_data"] = calculated_data;
    return requireData;
  }

  startFresh() {
    sessionStorage.clear();
    //window.location.reload();
  }

  handleBind = () => {
    let currProspect = JSON.parse(sessionStorage.getItem("currProspect"));
    let quoteData = JSON.parse(sessionStorage.getItem("quoteData"));
    let domain = sessionStorage.getItem("domain");

    let body = {
      companyName: currProspect.companyProfile.companyName.value,
      date: quoteData.date,
      uuid: quoteData.uuid,
      email: currProspect.companyProfile.emailId.value,
    };

    try {
      let requestBody = {
        domain,
        email: currProspect.companyProfile.emailId.value,
      };

      updateSolveTrackingDataGeneric(requestBody, "updateProposalStatus")
        .then((res) => {
          console.log("updated proposal count");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (error) {
      console.log("error", error);
    }

    this.props.setFormStage("four");
  };

  handleCheckClicked = async () => {
    let { isNoLossChckd } = this.state;
    this.setState({
      isNoLossChckd: !isNoLossChckd,
    });
  };

  handleDateSelected = (date) => {
    this.setState({ startDate: date });
    this.generateNoLoss(date);
  };

  handleTotPaidBlur = (e) => {
    // zeroDefault(e);
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];
    historicalClaims = histClaimCalcTP(historicalClaims, year);
    this.setState({ historicalClaims });
  };

  handleChange = (e, key) => {
    let { historicalClaims } = this.state;
    let year = e.target.id.split("-")[1];

    if (!historicalClaims[year]) {
      historicalClaims[year] = {};
    }
    historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      historicalClaims = histClaimCalcTI(historicalClaims, year);
    }
    if (key === "totPaid") {
      historicalClaims = histClaimCalcTP(historicalClaims, year);
    }
    if (key === "totRes") {
      historicalClaims = histClaimCalcTR(historicalClaims, year);
    }
    this.setState({ historicalClaims });
  };

  handleSubmit = async () => {
    let { updatePopUpData, currProspect, address, quoteData } = this.props;

    let { historicalClaims, historicalClaimsCheck, isNoLossChckd, startDate } =
      this.state;

    let user_id = sessionStorage.getItem("user");

    try {
      try {
        if (startDate) {
          startDate = moment(startDate).format("YYYY-MM-DD");
        } else {
          throw "invalid date";
        }
      } catch (error) {
        startDate = "";
      }

      let requestBody = {
        user_email_id: user_id,
        historicalClaims,
        historicalClaimsCheck,
        isNoLossChckd,
        startDate,
        address,
        timestamp: quoteData.date,
        uuid: quoteData.uuid,
        peo: currProspect.peoDetails.selectedPeo,
      };
      updateUserStatusTable(requestBody)
        .then((res) => {})
        .catch((error) => {
          console.log("error in API-updateFourthUserStatusTable", error);
        });
    } catch (error) {
      console.log("error while saving claims info", error);
    } finally {
      updatePopUpData({
        show: true,
        title: "Thank You for the Opportunity",
        children: (
          <>
            <br></br>
            <span style={{ fontWeight: "bold" }}>
              Our Team will get back to you in the next 24 Hours.
            </span>
          </>
        ),
        acceptBtn: "Okay",
        disableNoButton: true,
      });
    }
  };

  submitToUnderWriter = async () => {
    try {
      let {
        startLoader,
        stopLoader,
        setHideButtons,
        address,
        currProspect: currProspectDetails,
        currentUser: user_id,
        quoteData,
      } = this.props;

      startLoader();

      let req_body_userstatus = {
        user_email_id: user_id,
        quoteSubmitted: "true",
        submissionDate: moment().format("x"),
        address: address,
        timestamp: quoteData.date,
        uuid: quoteData.uuid,
        peo: currProspectDetails.peoDetails.selectedPeo,
      };

      let req_body_users = {
        user_email_id: user_id,
        quoteSubmitted: "true",
        submissionDate: moment().format("x"),
      };

      this.saveInUserstatusTable(req_body_userstatus);
      this.saveInUserTable(req_body_users);

      this.setState({ quoteSubmitted: true });
      setHideButtons(true);
      stopLoader();
    } catch (error) {
      console.log("error: ", error);
      stopLoader();
    }
  };

  saveInUserstatusTable = async (requestBody) => {
    updateUserStatusTable(requestBody);
  };

  saveInUserTable = (requestBody) => {
    userTableData(requestBody, "update");
  };

  setUploadingFileFlag = (key) => {
    return (val) => {
      this.setState((prevState) => {
        let uploadDocsClicked = JSON.parse(
          JSON.stringify(prevState.uploadDocsClicked)
        );
        uploadDocsClicked[key] = val;
        return {
          ...prevState,
          uploadDocsClicked,
        };
      });
    };
  };

  setFileList = (_files) => {
    this.setState({ fileList: _files });
  };

  render() {
    let {
      updatePopUpData,
      sessionData,
      currProspect: currProspectDetails,
      hideButtons,
      address,
      uw_flow,
      quoteData,
      currentUser: email,
    } = this.props;

    let {
      fileURL_Map,
      fileList,
      quoteStatus,
      isNoLossChckd,
      fileURL2,
      loadingNoLoss,
      startDate,
      peoType,
      quoteTableData,
      programs,
      emodStatesData,
      masterWithoutFundRateMap,
      programStateType,
      peoData,
      currentCarrierMap,
      loadingAccord,
      show_acord,
      excludedClassCodeMap,
      acordSelectedState,
      carrierSpecific,
      stateRecalculateObject,
      quoteSubmitted,
      hasRecalGroup,
      fileURL_Map1,
      acordIncludPremium,
      carrierWseSelectedState,
      latest_ncci_key_program,
      uploadDocsClicked,
      historicalClaims,
      historicalClaimsCheck,
      historicalClaimsErrorStore,
      loadingEAcord,
    } = this.state;
    // const table = this.createTable();

    const downloadEAcord = async () => {
      let currProspectDetails = this.props.currProspect;

      try {
        let eAcordPdf;
        // let currProspect = sessionStorage.getItem("currProspect")
        //   ? JSON.parse(sessionStorage.getItem("currProspect"))
        //   : undefined;
        // let address = sessionStorage.getItem("address")
        //   ? JSON.parse(sessionStorage.getItem("address"))
        //   : undefined;
        // let quoteData = sessionStorage.getItem("quoteData")
        //   ? JSON.parse(sessionStorage.getItem("quoteData"))
        //   : undefined;
        let agentID = currentUser;

        let childrenLoc = currProspectDetails.childrenLoc;
        let stateList = [];
        for (let childrenLocIndx in childrenLoc) {
          let childrenLocObj = childrenLoc[childrenLocIndx];
          if (childrenLocObj.state && childrenLocObj.state.value) {
            stateList.push(childrenLocObj.state.value.toLowerCase());
          }
        }
        let isLoggedIn;
        try {
          isLoggedIn = await Auth.currentAuthenticatedUser();
        } catch (error) {
          console.log("Authentication error here", error);
        }

        if (currProspectDetails && address && quoteData) {
          let etQuoteDetails = {
            email: agentID,
            timestamp: quoteData.date,
            carrier: `carrier_ax`,
            mail_address: "",
            address: address,
            state: stateList,
            netRateMap: {},
            agent_name: isLoggedIn?.attributes.name || " ",
            agent_email: isLoggedIn?.attributes.email || " ",
            agent_agency: isLoggedIn?.attributes["custom:agency"] || " ",
            uw_name: isLoggedIn?.attributes["custom:uw"] || " ",
          };
          axios
            .post(
              awsUrl2 + "/api/generateEAcord/fl",
              JSON.stringify(etQuoteDetails)
            )
            .then((res) => {
              eAcordPdf = res.data;

              const uint8Array = new Uint8Array(Object.values(eAcordPdf));
              const blob = new Blob([uint8Array], { type: "application/pdf" });
              const link = document.createElement("a");
              link.href = window.URL.createObjectURL(blob);
              link.download = "editable_acord.pdf";

              // Append link to body and trigger download
              document.body.appendChild(link);
              link.click();

              // Cleanup
              document.body.removeChild(link);
              setLoadingEAcord(false);
            });
        }
      } catch (error) {
        console.error("Error:", error);
        alert("Failed to download editable Acord. Please try again later.");
      }
    };
    let tabList = [];

    let _quoteSubmitted = quoteSubmitted ? true : false;

    if (isNoLossChckd && fileURL2 && startDate) {
      tabList.push({
        tabName: "No Loss",
        children: (
          <iframe
            id={`iframeNoLoss`}
            src={fileURL2 + "#toolbar=0"}
            style={{ border: "none", height: "645px" }}
            width="100%"
          ></iframe>
        ),
      });
    }

    for (let carr in show_acord) {
      let fileURL,
        state_shown,
        non_ncci_used = false;

      for (let _state of not_include_in_ncci) {
        let __state = _state.toUpperCase();
        if (acordSelectedState?.[carr] === __state) {
          state_shown = __state;
          non_ncci_used = true;
          if (acordIncludPremium?.[carr]?.[__state]) {
            fileURL = fileURL_Map1?.[carr]?.[__state];
          } else {
            fileURL = fileURL_Map?.[carr]?.[__state];
          }
        }
      }

      if (non_ncci_used === true) {
      } else if (
        latest_ncci_key_program?.[carr] &&
        fileURL_Map?.[carr]?.["Ncci"]?.[latest_ncci_key_program[carr]]
      ) {
        state_shown = "Ncci";
        if (acordIncludPremium?.[carr]?.["Ncci"]) {
          fileURL = fileURL_Map1[carr]["Ncci"][latest_ncci_key_program[carr]];
        } else {
          fileURL = fileURL_Map[carr]["Ncci"][latest_ncci_key_program[carr]];
        }
      }

      if (fileURL)
        tabList.push({
          tabName: carr,
          state_shown: state_shown,
          children: (
            <div
              className={
                isMobile ? "accord-container-mobile" : "accord-container"
              }
            >
              <div
                className={
                  isMobile ? "accord-toolbar-mobile" : "accord-toolbar"
                }
              >
                {isMobile ? (
                  ""
                ) : (
                  <a href={fileURL} download={carr} title={`Download: ${carr}`}>
                    <img
                      height="30"
                      src={require("../../../images/download-file.png")}
                    ></img>
                  </a>
                )}
                <div className="ml-2">
                  <label>
                    <input
                      type="checkbox"
                      checked={
                        this.state.acordIncludPremium?.[carr]?.[
                          acordSelectedState?.[carr]
                        ]
                      }
                      onChange={(event) =>
                        this.acordCheckboxHandler(
                          event,
                          carr,
                          acordSelectedState?.[carr]
                        )
                      }
                    />
                  </label>
                  <b style={{ fontSize: "13px" }}>
                    {" "}
                    Include Premium and Carrier Name
                  </b>
                </div>
                {isMobile ? (
                  <a
                    href={fileURL}
                    download={carr}
                    title={`Download: ${carr}`}
                    style={{ paddingLeft: "2rem", paddingTop: "15px" }}
                  >
                    <img
                      height="30"
                      src={require("../../../images/download-file.png")}
                    ></img>
                    <span style={{ color: "white" }}>
                      {isMobile ? "Download Acord" : ""}
                    </span>
                  </a>
                ) : (
                  ""
                )}
              </div>
              {isMobile ? (
                ""
              ) : (
                <iframe
                  id={`iframeAccord`}
                  src={fileURL + "#toolbar=0"}
                  style={{ border: "none", height: "645px" }}
                  width="100%"
                ></iframe>
              )}
            </div>
          ),
        });
    }

    if (this.state.data !== undefined && this.state.data !== null) {
      for (let carrier in this.state.data) {
        let requireData = this.getAllDataWRTCarrier(
          carrier,
          this.state.data[carrier]
        );
        calculated_data = requireData.calculated_data;
      }
    }

    return (
      <div id="cmprQts" className={`container-fluid per98 ${styles.cmprQts}`}>
        <NewProgress />
        <div id="compareQuote" className={styles.compareQuote}>
          <QuoteTable
            quoteTableData={quoteTableData}
            programs={programs}
            masterWithoutFundRateMap={masterWithoutFundRateMap}
            programStateType={programStateType}
            peoData={peoData}
            updateSelectedCarrierMap={this.updateSelectedCarrierMap}
            excludedClassCodeMap={excludedClassCodeMap}
            currentCarrierMap={currentCarrierMap}
            acordSelectedState={acordSelectedState}
          />
          {quoteTableData && (
            <div className={`${styles.submit_to_underwriter} mt-3  mb-3`}>
              <div>
                <button
                  className="btn btn-warning"
                  onClick={(e) => {
                    if (!_quoteSubmitted) {
                      updatePopUpData({
                        show: true,
                        title: <b>{"Do you wish to continue?"}</b>,
                        children: <></>,
                        acceptBtn: "Yes",
                        acceptBtnCallback: async () => {
                          await this.submitToUnderWriter();
                        },
                      });
                    }
                  }}
                  disabled={_quoteSubmitted}
                >
                  Submit to Underwriter
                </button>
              </div>
              <div>
                {/* <button
                  className="btn btn-primary"
                  onClick={() => {
                    downloadEAcord();
                    setLoadingEAcord(true);
                  }}
                >
                  {loadingEAcord ? "Downloading..." : "Download EAcord"}
                </button> */}
              </div>
            </div>
          )}
          <div className="row mt-5">
            <div
              className="pink-header font-family-montserrat-bold"
              style={{ fontSize: "x-large" }}
            >
              Please provide the following information
            </div>
            <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-6">
              {quoteData && (
                <GenericDropZone
                  fileList={fileList}
                  setFileList={this.setFileList}
                  fileKeyName={"fileList"}
                  heading={"Upload Loss Runs"}
                  updatePopUpData={updatePopUpData}
                  folder1={`${email}/${quoteData.uuid}_${quoteData.date}`}
                  folder2={"loss_run"}
                  upload_file_details={[
                    {
                      api_name: "updateUserStatusTable",
                      request_body: {
                        user_email_id: email,
                        address: address,
                        timestamp: quoteData.date,
                        uuid: quoteData.uuid,
                        peo: currProspectDetails?.peoDetails?.selectedPeo,
                      },
                    },
                  ]}
                  uploadDoc={uploadDocsClicked["loss_run"]}
                  setUploadingFileFlag={this.setUploadingFileFlag}
                  parent={true}
                />
              )}
            </div>
            <div className="col col-xs-12 col-sm-12 col-md-12 col-lg-6">
              {quoteData && (
                <GenericDropZone
                  fileList={fileList}
                  setFileList={this.setFileList}
                  fileKeyName={"fileList"}
                  heading={"Upload Other Documents"}
                  updatePopUpData={updatePopUpData}
                  folder1={`${email}/${quoteData.uuid}_${quoteData.date}`}
                  folder2={"other_docs"}
                  upload_file_details={[
                    {
                      api_name: "updateUserStatusTable",
                      request_body: {
                        user_email_id: email,
                        address: address,
                        timestamp: quoteData.date,
                        uuid: quoteData.uuid,
                        peo: currProspectDetails?.peoDetails?.selectedPeo,
                      },
                    },
                  ]}
                  uploadDoc={uploadDocsClicked["other_docs"]}
                  setUploadingFileFlag={this.setUploadingFileFlag}
                  parent={false}
                />
              )}
            </div>
          </div>
          <div className="d-flex flex-column">
            <button
              className="btn btn-warning btn-sm font-family-montserrat-regular mt-3"
              style={{
                width: "auto",
                color: "white",
                alignSelf: "center",
                backgroundColor: "#feae01",
                boxShadow: "0px 5px 12px grey",
                fontSize: "16px",
                borderRadius: "10px",
                fontWeight: "800",
                fontFamily: "MontserratRegular",
              }}
              onClick={(e) => {
                updatePopUpData({
                  show: true,
                  title: "Do you wish to continue?",
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        Files once uploaded cannot be deleted
                      </span>
                    </>
                  ),
                  acceptBtn: "Yes",
                  acceptBtnCallback: async () => {
                    uploadDocsClicked["other_docs"] = true;
                    uploadDocsClicked["loss_run"] = true;
                    this.setState({ uploadDocsClicked });
                  },
                });
              }}
              disabled={
                uploadDocsClicked["other_docs"] || uploadDocsClicked["loss_run"]
                  ? true
                  : false
              }
            >
              Upload Docs
            </button>
          </div>
          <div className="d-flex mt-4 p-1 historical_no_claims">
            <input
              className="mr-1"
              type="checkbox"
              id="chck-1"
              name={"chck"}
              checked={isNoLossChckd}
              disabled={loadingNoLoss || quoteStatus === "proceed_uw"}
              onChange={this.handleCheckClicked}
            />
            <label htmlFor="chck-1">No historical claims</label>
          </div>
          <div id="datepicker-startDate">
            {isNoLossChckd && (
              <>
                <label
                  htmlFor="startDate"
                  title="Business start date"
                  className="mr-2 "
                >
                  Business Start Date
                </label>
                <span id="startDate">
                  <DatePicker
                    selected={startDate || null}
                    onChange={this.handleDateSelected}
                    maxDate={moment().toDate()}
                    showYearDropdown
                    showMonthDropdown
                    useShortMonthInDropdown
                    disabled={quoteStatus === "proceed_uw"}
                  />
                </span>
              </>
            )}
          </div>
          {!isNoLossChckd && (
            <HistoricalClaims
              currProspect={currProspectDetails}
              setHistoricalClaimsErrorStore={this.setHistoricalClaimsErrorStore}
              setHistoricalClaimsCheck={this.setHistoricalClaimsCheck}
              setHistoricalClaims={this.setHistoricalClaims}
              historicalClaims={historicalClaims}
              historicalClaimsCheck={historicalClaimsCheck}
              historicalClaimsErrorStore={historicalClaimsErrorStore}
            />
          )}
          {tabList.length > 0 && (
            <div className="mt-2">
              <div className="row no-gutters d-flex justify-content-end w-100">
                <div>
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={() => {
                      if (document.getElementById("iframeAccord"))
                        document.getElementById("iframeAccord").content; //window.location.reload();

                      if (document.getElementById("iframeNoLoss"))
                        document.getElementById("iframeNoLoss").content; //window.location.reload();
                    }}
                  >
                    {isMobile ? "" : "refresh"}
                  </button>
                </div>

                <Tabs
                  updateAcordSelectedState={this.updateAcordSelectedState}
                  carrierSpecific={carrierWseSelectedState}
                  tabList={tabList}
                  loadingAccord={loadingAccord}
                  not_include_in_ncci={not_include_in_ncci}
                  className="mt-4"
                />
              </div>
            </div>
          )}
          <div className="row mt-5 justify-content-center">
            <div className="d-flex flex-column">
              <button
                onClick={(e) => {
                  if (!hideButtons) this.handleSubmit();
                }}
                type="button"
                className="align-self-center btn btn-warning btn-lg btn-warning-shadow proceed-bind"
                disabled={hideButtons}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default function FormCmprQuoteWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => <FormCmprQuote {...props} {...context} />}
    </LibertateContext.Consumer>
  );
}
