import { EmodDataDto, NewRatesDto } from "./NewRatesDtos";

interface INewRatesModel {
  programs: SelectOptions;
  states: NewRatesStates[];
}

export class NewRatesModel implements INewRatesModel {
  constructor(
    public programs: SelectOptions = new SelectOptions(),
    public states: NewRatesStates[] = [new NewRatesStates()]
  ) {}
}

interface INewRatesStates {
  state: SelectOptions;
  classCodes: NewRatesClassCodes[];
}

export class NewRatesStates implements INewRatesStates {
  constructor(
    public state: SelectOptions = new SelectOptions(),
    public classCodes: NewRatesClassCodes[] = [new NewRatesClassCodes()]
  ) {}
}

interface INewRatesClassCodes {
  classCode: SelectOptions;
  newRatesResponse: NewRatesDto[];
}

export class NewRatesClassCodes implements INewRatesClassCodes {
  constructor(
    public classCode: SelectOptions = new SelectOptions(),
    public newRatesResponse: NewRatesDto[] = [new NewRatesDto()]
  ) {}
}

interface ISelectOptions {
  value: string;
  label: string;
}

export class SelectOptions implements ISelectOptions {
  constructor(
    public value: string = "0",
    public label: string = "--Select--",
    public eligibility?: object
  ) {}
}

export interface IClassCodeDropDownProps {
  newRatesModel: NewRatesModel;
  setNewRatesModel: React.Dispatch<React.SetStateAction<NewRatesModel>>;
  selectedProgram: SelectOptions[] | undefined;
  effectiveDate: any;
  stateIndex: number;
  classCodeIndex: number;
  classcode: any;
  emodStatesData: EmodDataDto;
  carrierEligibility: any;
  setCarrierEligibility: React.Dispatch<React.SetStateAction<any>>;
}

export interface ISideSliderProps {
  list: any;
  listToDisplay: any;
  displayHeaders: Boolean;
  showRatesValues: Boolean;
  codeDesc: any;
  state: any;
  carrierEligibility: any;
}

interface IPopupOptions {
  title: string;
  children: any;
  acceptBtn: string;
  acceptBtnCallback: Function;
  rejectBtnCallback?: Function;
  show: boolean;
  disableNoButton?: boolean;
}

export class PopUpOptions implements IPopupOptions {
  constructor(
    public title: string = "",
    public children: any = "<></>",
    public acceptBtn: string = "",
    public acceptBtnCallback: Function = () => {},
    public rejectBtnCallback?: Function,
    public show: boolean = false,
    public disableNoButton?: boolean
  ) {}
}
