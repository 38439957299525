"use strict";
import React, { useState } from "react";
import UnderWriterTab from "./UnderWriterTab";
import SideTab from "../../../newcomponent/common/SideTab";
import Header from "../../common/Header";
import Loader from "../../common/Loader";
import styles from "./UnderWriter.module.scss";

let isMobile = window.innerWidth < 900;

const UnderWriterFlow = (props) => {
  const [dealMap, setDealMap] = useState({});

  const updateDealMap = (key, value) => {
    let dealMapCopy = JSON.parse(JSON.stringify(dealMap));
    dealMapCopy[key] = value;
    setDealMap(dealMapCopy);
  };

  return (
    <div className={styles.dashboard_container}>
      <div className={`d-flex bg-light ${styles.dashboard_container_header}`}>
        <Header sessionData={props.sessionData} />
      </div>
      <div className={styles.submission_uw_dashboard}>
        {isMobile ? <SideTab /> : " "}
        <h1 className={`${styles.UWdashboard_title} mt-3 mb-3`}>
          Underwriter Dashboard
        </h1>
        <UnderWriterTab dealMap={dealMap} updateDealMap={updateDealMap} />
      </div>
      {/* <Loader /> */}
    </div>
  );
};

export default UnderWriterFlow;
