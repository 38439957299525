import React, { Component } from "react";
import Loader from "../../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../../common/Header";
import SideTab from "../../../newcomponent/common/SideTab";
import LibertateDashboard from "./LibertateDashboard";
import styles from "./DashboardLibertate.module.scss"

let isMobile_sidenav = window.innerWidth < 900;
class DashboardLibertateTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  render() {
    let { selected } = this.state;
    const tabsMap = {
      tab1: "User Dashboard",
    };

    return (
      <div className="dashboard-container">
        <div
          className={`d-flex bg-light ${styles.dashboardHeader}`}
        >
          <Header sessionData={this.props.sessionData} />
        </div>

        <div className="position-relative">
          {isMobile_sidenav ? <SideTab /> : " "}

          <div className={`tabs  ${styles.lib_dashboard}`}>
            <div className="d-flex justify-content-center mb-3 text-center">
              <h2 className="mt-3 mb-3">
                <b>Dashboard</b>
              </h2>
            </div>
            <Nav
              className="userdashboard__button"
              variant="pills"
              defaultActiveKey={selected}
              style={{ fontSize: "16px" }}
            >
              {Object.keys(tabsMap).map((tab) => (
                <Nav.Item>
                  <Nav.Link
                    eventKey={tab}
                    onClick={() => this.setSelected(tab)}
                  >
                    {tabsMap[tab]}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {Object.keys(tabsMap).map((tab) => (
              <div key={tab} className="Admin__Dashboard">
                {
                  <LibertateDashboard
                    tabName={tab}
                    selected={selected}
                    sessionData={this.props.sessionData}
                  />
                }
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  }
}

export default DashboardLibertateTab;
