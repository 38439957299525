import React, { useState, useEffect } from "react";

function TextField(props) {
  const [value, setValue] = useState("");

  useEffect(() => {
    setValue(props.value);
  }, [props.value]);

  return (
    <textarea
      value={value || ""}
      className="underwriter_comment"
      rows="1"
      style={{ width: "100%" }}
      onChange={(e) => setValue(e.target.value)}
      disabled={props.agent_comments ? true : false}
      onBlur={(e) => {
        let dataToSave = {};
        dataToSave.user_email_id = props.rowData.user_email_id;
        dataToSave.sortKeyList = props.rowData.uuidCarriers;
        props.agent_comments
          ? (dataToSave.agent_comments = e.target.value)
          : (dataToSave.uw_comments = e.target.value);
        props.callback(dataToSave);
      }}
    ></textarea>
  );
}

export default TextField;
