import React, { Component } from "react";
import { LibertateContext } from "./Context";

export class LibertateContextProvider extends Component {
  state = {
    perStateQuoteTableData: {},
    programs: [],
    masterWithoutFundRateMap: {},
    programStateType: {},
    peoData: {},
    excludedClassCodeMap: {},
    currentCarrierMap: {},
    stateCarrierData: {},
    uwNetRates: {},
    uwFileList: {
      third: {},
    },
    carrierBasedData: {},
    hideButtons: false,
    userStatusData: {},
    userData: {},
    formStage: "one",
    currProspect: {},
    displayLoader: false,
    acordUwQues: [],
    isUwFlow: false,
    submissionDate: "",
    currentUser: "",
    visitTimestamp: "",
    uuid: "",
    sortKeyList: [],
    quoteData: {},
    address: [],
    quoteFactors: {},
    getQuoteClicked: false,
  };

  updateSelectedCarrierMap = async (
    carrier,
    value,
    program,
    state,
    state_value,
    selectedStateList
  ) => {
    let { currentCarrierMap, perStateQuoteTableData } = this.state;
    let _currentCarrierMap = JSON.parse(JSON.stringify(currentCarrierMap));

    _currentCarrierMap[carrier] = value;

    if (state && program) {
      if (perStateQuoteTableData[state][program]) {
        perStateQuoteTableData[state].checked[program] = state_value;
      }
    }

    if (!state && program) {
      for (let _state in perStateQuoteTableData) {
        if (perStateQuoteTableData[_state][program]) {
          perStateQuoteTableData[_state].checked[program] = value;
        }
      }
    }

    this.setState({
      perStateQuoteTableData,
      currentCarrierMap: _currentCarrierMap,
    });
  };

  render() {
    let { displayLoader } = this.state;
    return (
      <LibertateContext.Provider
        value={{
          ...this.state,
          setContextData: (key, data) => {
            this.setState({ [key]: data });
          },
          setUserData: (data) => {
            this.setState({ userData: data });
          },
          setUserStatusData: (data) => {
            this.setState({ userStatusData: data });
          },
          setUwNetRates: (data) => {
            this.setState({ uwNetRates: data });
          },
          updateSelectedCarrierMap: this.updateSelectedCarrierMap,
          setPerStateQuoteTableData: (data) => {
            this.setState({ perStateQuoteTableData: data });
          },

          setPrograms: (data) => {
            this.setState({ programs: data });
          },
          setMasterWithoutFundRateMap: (data) => {
            this.setState({ masterWithoutFundRateMap: data });
          },
          setProgramStateType: (data) => {
            this.setState({ programStateType: data });
          },
          setPeoData: (data) => {
            this.setState({ peoData: data });
          },
          setExcludedClassCodeMap: (data) => {
            this.setState({ excludedClassCodeMap: data });
          },
          setCurrentCarrierMap: (data) => {
            this.setState({ currentCarrierMap: data });
          },
          setStateCarrierData: (data) => {
            this.setState({ stateCarrierData: data });
          },
          setUwFileList: (data) => {
            this.setState({ uwFileList: data });
          },
          setCarrierBasedData: (data) => {
            this.setState({ carrierBasedData: data });
          },
          setHideButtons: (data) => {
            this.setState({ hideButtons: data });
          },
          setFormStage: (data) => {
            this.setState({ formStage: data });
          },
          setCurrProspect: (data) => {
            this.setState({ currProspect: data });
          },
          startLoader: () => {
            this.setState((prevState) => {
              if (prevState.displayLoader === false)
                return { displayLoader: true };
            });
          },
          stopLoader: () => {
            this.setState((prevState) => {
              if (prevState.displayLoader === true)
                return { displayLoader: false };
            });
          },
          setIsUwFlow: (val) => {
            this.setState({ isUwFlow: val });
          },
          setSubmissionDate: (data) => {
            this.setState({ submissionDate: data });
          },
          setCurrentUser: (data) => {
            this.setState({ currentUser: data });
          },
          resetEntireState: () => {
            this.setState({
              perStateQuoteTableData: {},
              programs: [],
              masterWithoutFundRateMap: {},
              programStateType: {},
              peoData: {},
              excludedClassCodeMap: {},
              currentCarrierMap: {},
              stateCarrierData: {},
              uwNetRates: {},
              uwFileList: {
                third: {},
              },
              carrierBasedData: {},
              hideButtons: false,
              userStatusData: {},
              userData: {},
              formStage: "one",
              currProspect: {},
              displayLoader: false,
              acordUwQues: [],
              isUwFlow: false,
              submissionDate: "",
              currentUser: "",
              visitTimestamp: "",
              uuid: "",
              sortKeyList: [],
              quoteData: {},
              address: [],
              quoteFactors: {},
              address: [],
              quoteFactors: {},
              getQuoteClicked: false,
            });
          },
        }}
      >
        {this.props.children}
      </LibertateContext.Provider>
    );
  }
}

export const useLibertateContext = () => React.useContext(LibertateContext);
