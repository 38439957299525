import axios from "axios";
import { awsUrl, awsUrl2 } from "../config";
import { getHeader } from "../utils/common";
export function verifyOtp(email, otp) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/verifyOtp", { email, otp }, header)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function sendOtpMail(email) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/sendOtpMail", { email }, header)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getDomain(user) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + "/api/getDomain/" + user, header)
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function validateClassCodesAndDescription(childrenLoc) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/validateClassCodesAndDescription",
        {
          childrenLoc,
        },
        header
      )
      .then((result) => {
        resolve(result);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function getRiskProfileDataId(riskProfileRequestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + `/api/getRiskProfileDataId`,
        JSON.stringify({ body: riskProfileRequestBody }), header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function fetchRiskProfileData(id) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl + `/api/getRiskProfileData/${id}`, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function sendRandomPassword(details) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/sendRandomPassword", details, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function putUserIntoGroup(username) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl + "/api/putUserIntoGroup/" + username, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getEntityType() {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl + "/api/getEntityType", header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
