import axios from "axios";
import { awsUrl, awsUrl2 } from "../config";
import { getHeader } from "../utils/common";

export function generateQAduotePDF(requestBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + "/api/generateQAduotePDF",
        JSON.stringify(requestBody),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function generateAcordNcci(etQuoteDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/generateAcordNcci",
        JSON.stringify(etQuoteDetails),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function generateAcordCA(etQuoteDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/generateAcordCA",
        JSON.stringify(etQuoteDetails),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function generateAcordFL(etQuoteDetails) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/generateAcordFL",
        JSON.stringify(etQuoteDetails),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function updateSolveUsersData(updateBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/updateSolveUsersData",
        JSON.stringify(updateBody),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function sendBindMail(body) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/sendBindMail", JSON.stringify(body), header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
export function updateSolveUserStatusTable(dataToSend) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl2 + "/api/updateSolveUserStatusTable", dataToSend, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
