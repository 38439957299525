import React from "react";
import CustomDropZone from "./CustomDropZone3";
import axios from "axios";
import LoadingOverlay from "react-loading-overlay";
import { Auth } from "aws-amplify";
import { raterFileData } from "../../api/dropzoneApi";
import styles from "./DropZones.module.scss";
const uuidv4 = require("uuid/v4");
const moment = require("moment");

export default class DropZones extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      fileList: {},
      uploadingInProgress: false,
      uploadMsg: undefined,
      errMsg: undefined,
      intervalID: undefined,
    };
  }

  componentDidMount() {
    // sessionStorage.clear();
  }

  updateFileList = (newFile) => {
    let { fileList } = this.state;
    let files;

    files = fileList;
    if (newFile && newFile.name) {
      let new_file_extension = newFile.name.split(".").pop();
      if (new_file_extension !== "csv") {
        this.setState({
          errMsg: "Please select a csv file",
          uploadMsg: undefined,
        });

        return;
      }
    }

    if (files[newFile.name] && files[newFile.name] === "#empty") {
      this.setState({ errMsg: "File already exist", uploadMsg: undefined });

      return;
    }
    files[newFile.name] = newFile;

    fileList = files;

    this.setState({
      fileList,
      errMsg: undefined,
      uploadMsg: undefined,
    });
  };

  removeFile = (fileName) => {
    let { fileList } = this.state;
    let files;

    files = fileList;

    delete files[fileName];

    fileList = files;

    this.setState({ fileList });
  };

  uploadFile = async (fileData, postDataS3) => {
    return new Promise((resolve, reject) => {
      raterFileData(postDataS3, "getSignedURL")
        .then((response) => {
          let returnData = response.data;
          let lossSignedURL = returnData.lossSignedURL;
          let options = { header: { "Content-Type": postDataS3.fileType } };
          axios
            .put(lossSignedURL, fileData, options)
            .then((result) => {
              console.log("file upload res: ", result);
              resolve(returnData.url);
            })
            .catch((error) => {
              console.log("error in uploading", error);
              reject();
            });
        })
        .catch((error) => {
          console.log("error in uploadLossForm ", error);
          reject();
        });
    });
  };

  putRaterTableData = (dataToSend) => {
    return new Promise((resolve, reject) => {
      raterFileData(dataToSend, "insert")
        .then((res) => {
          this.setState({
            uploadMsg: "files uploaded",
            errMsg: undefined,
          });

          resolve();
        })
        .catch((error) => {
          console.log("error in API-raterFileData", error);
          reject();
        });
    });
  };

  getRaterTableData = (dataToSend) => {
    return new Promise((resolve, reject) => {
      raterFileData(dataToSend, "get")
        .then((res) => {
          resolve({ res: res.data });
        })
        .catch((error) => {
          console.log("error in API-raterFileData", error);
          resolve({ err: "err" });
        });
    });
  };

  uploadAndSaveInDB = (dataToSend, fileData, postDataS3) => {
    return new Promise(async (resolve, reject) => {
      try {
        let url = await this.uploadFile(fileData, postDataS3);
        dataToSend = { ...dataToSend, url };
        await this.putRaterTableData(dataToSend);
      } catch (error) {
        console.log("error in uploading: ", error);
      } finally {
        resolve();
      }
    });
  };

  handleUpload = async () => {
    try {
      let { fileList } = this.state;
      this.setState({ uploadingInProgress: true });
      let promiseList = [];

      let files = Object.keys(fileList);
      let uuid = uuidv4();
      if (files.length > 0) {
        for (let file of files) {
          let extension = file.split(".").pop();
          if (fileList[file] !== "#empty") {
            let postDataS3 = {
              fileName: `${uuid}/${file}`,
              fileType: extension,
            };
            let dataToSend = {
              record_number: uuid,
              file_name: file,
              file_status: "new",
            };
            promiseList.push(
              this.uploadAndSaveInDB(dataToSend, fileList[file], postDataS3)
            );
          }
          break;
        }
        Promise.all(promiseList)
          .then(async (res) => {
            this.setState({
              uploadMsg: "file received, processing...",
              errMsg: undefined,
            });
            let rates_data, invalid_input;
            while (true) {
              new Promise((resolve, reject) => {
                setTimeout(() => {
                  resolve();
                }, 5000);
              });
              let response = await this.getRaterTableData({
                record_number: uuid,
              });
              console.log(response);
              if (response.err) {
                this.setState({
                  uploadingInProgress: false,
                  uploadMsg: undefined,
                  errMsg: "Error processing file",
                });
              } else {
                if (response.res.file_status === "completed") {
                  rates_data = response?.res?.response?.data;
                  invalid_input = response?.res?.response?.error;
                  break;
                } else if (response.res.file_status === "error") {
                  this.setState({
                    uploadingInProgress: false,
                    uploadMsg: undefined,
                    errMsg: "Error processing file",
                  });
                  break;
                }
              }
            }
            sessionStorage.clear();
            if (rates_data) {
              sessionStorage.setItem("rates_data", JSON.stringify(rates_data));
              if (invalid_input)
                sessionStorage.setItem(
                  "invalid_input",
                  JSON.stringify(invalid_input)
                );
              window.location.href = "/Rates";
            } else {
              this.setState({
                uploadingInProgress: false,
                uploadMsg: undefined,
                errMsg: "Error processing file",
              });
            }
          })
          .catch((error) => console.log("error in promise all", error));
      } else {
        this.setState({ uploadingInProgress: false });
      }
    } catch (error) {
      console.log("error: ", error);
      this.setState({
        uploadingInProgress: false,
        uploadMsg: undefined,
        errMsg: "error in uploading",
      });
    }
  };

  render() {
    let { fileList, uploadingInProgress, uploadMsg, errMsg } = this.state;
    return (
      <>
        <div className={styles.dropZonesContainer}>
          <div className="row justify-content-center">
            <div className="col-sm-12 col-md-6 p-4">
              <LoadingOverlay active={uploadingInProgress} spinner>
                <CustomDropZone
                  fileList={fileList}
                  updateFileList={this.updateFileList}
                  removeFile={this.removeFile}
                  heading={<b>Rater Data</b>}
                  uploadMsg={uploadMsg}
                  flag={"first"}
                  downloadFileName={"Rater_template.csv"}
                />
                <DropButton
                  uploadMsg={uploadMsg}
                  errMsg={errMsg}
                  handleUpload={this.handleUpload}
                  uploadingInProgress={uploadingInProgress}
                  flag={"first"}
                />
              </LoadingOverlay>
            </div>
          </div>
        </div>
      </>
    );
  }
}

const DropButton = (props) => {
  return (
    <div className="d-flex flex-column">
      <pre
        className={
          "text-center text-capitalize " +
          (props.uploadMsg ? "text-success" : "text-danger")
        }
      >
        {props.uploadMsg || props.errMsg}
      </pre>
      <button
        className={`btn btn-warning btn-sm btn-warning-shadow upload-docs ${styles.uploadButton}`}
        onClick={() => props.handleUpload(props.flag)}
        disabled={props.uploadingInProgress}
      >
        Upload
      </button>
    </div>
  );
};

const TabsForDropzones = (props) => {
  return (
    <div className="row table-tabs mx-5 mt-5 mb-0 tabs-for-dropzones">
      <ul
        className="nav justify-content-center nav-pills mb-4"
        id="myTab"
        role="tablist"
      >
        <li className="nav-item mr-3" role="presentation">
          <button
            className={
              props.selectedTab === "profile" ? "nav-link active" : "nav-link"
            }
            id="company-profile-tab "
            type="button"
            role="tab"
            data-bs-toggle="tab"
            data-bs-target="#company-profile"
            aria-controls="company-profile"
            aria-selected={props.selectedTab === "profile" ? "true" : "false"}
            onClick={() => props.setSelectedState("profile")}
          >
            Company Profile Data
          </button>
        </li>
        <li className="nav-item mr-3" role="presentation">
          <button
            className={
              props.selectedTab === "historical"
                ? "nav-link active"
                : "nav-link"
            }
            id="historical-tab"
            type="button"
            role="tab"
            data-bs-toggle="tab"
            data-bs-target="#historical"
            aria-controls="historical"
            aria-selected={
              props.selectedTab === "historical" ? "true" : "false"
            }
            onClick={() => props.setSelectedState("historical")}
          >
            Historical Loss Data
          </button>
        </li>
      </ul>
      <div className="tab-content" id="myTabContent">
        <div
          class={
            props.selectedTab === "profile"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="company-profile"
          role="tabpanel"
          aria-labelledby="company-profile-tab"
        >
          <TableForTab data={props.dataFromAPI["first"]} />
        </div>
        <div
          class={
            props.selectedTab === "historical"
              ? "tab-pane fade show active"
              : "tab-pane fade"
          }
          id="historical"
          role="tabpanel"
          aria-labelledby="historical-tab"
        >
          <TableForTab data={props.dataFromAPI["second"]} />
        </div>
      </div>
    </div>
  );
};

const TableForTab = (props) => {
  let rowsData = props.data
    ? props.data.map((row, indx) => {
        return (
          <tr>
            <th scope="row">{indx + 1}</th>
            <td>
              {moment(Number(row.date))
                .utcOffset("-0500")
                .format("M/D/YYYY - hh:mm A")}
            </td>
            <td>{row.email_client.split("_")[0]}</td>
            <td>{row.key.split("/").reverse()[0]}</td>
            <td>{row.status}</td>
          </tr>
        );
      })
    : [];

  return (
    <table class="table table-striped">
      <thead>
        <tr>
          <th scope="col">#</th>
          <th scope="col">Date</th>
          <th scope="col">Email</th>
          <th scope="col">File name</th>
          <th scope="col">Status</th>
        </tr>
      </thead>
      <tbody>{rowsData}</tbody>
    </table>
  );
};
