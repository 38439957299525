import React, { useEffect, useState } from "react";
import { useLibertateContext } from "../context/ContextProvider";
import { getUserDataStatus, userTableData } from "../api/commonApi";

function FetchQuotesData(props) {
  const [userStatusData, setUserStatusData] = useState(null);
  const [userData, setUserData] = useState(null);
  const [alreadyRunning, setAlreadyRunning] = useState(false);

  let libertateContext = useLibertateContext();

  let fetchUserStatusData = (user_id) => {
    try {
      getUserDataStatus(user_id, "quote")
        .then((response) => {
          if (response?.data) {
            setUserStatusData(response.data)
            libertateContext.stopLoader();
          }
        })
        .catch((err) => {
          console.log("error while fetching userStatusData: ", err)
          libertateContext.stopLoader();
        });
    } catch (error) {
      console.log(error);
      libertateContext.stopLoader();
    }
  };

  let fetchUserData = (user_id) => {
    try {
      userTableData({ user_email_id: user_id }, "get")
        .then((response) => {
          if (response?.data?.[0]) {
            setUserData(response.data[0]);

            if (!userStatusData) {
              fetchUserStatusData(user_id);
            }
          }
        })
        .catch((err) =>
          console.log("error while fetching userTableData: ", err)
        );
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let user_id = urlParams.get("user") || sessionStorage.getItem("user");

    if (user_id && !alreadyRunning) {
      setAlreadyRunning(true);

      libertateContext.setCurrentUser(user_id);

      if (!userData) {
        libertateContext.startLoader();
        fetchUserData(user_id);
      }
    }
  });

  useEffect(() => {
    if (libertateContext.setUserData && userData) {
      libertateContext.setUserData(userData);
      // we are getting the form stage to two when we have the data in user table
      if (Object.keys(userData).length > 0) {
        libertateContext.setFormStage("two");
      }
    }
  }, [userData]);

  useEffect(() => {
    if (libertateContext.setUserStatusData && userStatusData) {
      libertateContext.setUserStatusData(userStatusData);
      // we are getting the form stage to three when we have the data in userStatus table
      if (Object.keys(userStatusData).length > 0) {
        libertateContext.setFormStage("three");
      }
    }
  }, [userStatusData]);

  return <div className="fetch-quote-container">{props.children}</div>;
}

export default FetchQuotesData;
