import React, { Component } from "react";
import { Link } from "react-router-dom";
import HeaderSubPart from "./HeaderSubPart";
import styles from "../common/Header.module.scss"

let libertatelogo = require("../../images/libertate.png");
let insurecomplogo = require("../../images/new-logo.png");


let isMobile = window.innerWidth < 900;

class Header extends Component {
  constructor(props) {
    super(props);
    this.state = {
      logoSize: "large",
    };
  }
  render() {
    let { logoSize } = this.state;

    return (
      <>
        <nav
          id="navbarMain"
          className={`${styles.new_navbar} container-fluid  ${styles.nav_fontsize}`}
        >
          <div className="d-flex justify-content-between align-items-center w-100 no-gutters">
            {process.env.REACT_APP_MODE !== "beta" ? (
              <Link
                to={"/"}
                className="navbar-brand mx-0 d-flex align-items-center navlisting"
              >
                <img
                  className={styles.imagesizelogo}
                  id="imagesizelogo"
                  style={
                    logoSize === "large"
                      ? { height: "100px", marginLeft: "2rem" }
                      : { height: "100px", marginLeft: "1.6rem" }
                  }
                  src={libertatelogo}
                  alt="libertate logo"
                />
              </Link>
            ) : (
              <div></div>
            )}

            {isMobile ? " " : <HeaderSubPart sessionData={this.props.sessionData} />}

            <Link
              to={"/"}
              className={`navbar-brand mx-0 d-flex align-items-center ${styles.icomplink}`}
            >
              <img
                className={styles.imagesizelogoicomp}
                id="imagesizelogoicomp"
                style={
                  logoSize === "large"
                    ? {
                        height: "70px",
                        marginRight: "2rem",
                      }
                    : {
                        height: "71px",
                        marginRight: "1rem",
                      }
                }
                src={insurecomplogo}
                alt="insurecomp logo"
              />
            </Link>
          </div>
        </nav>
      </>
    );
  }
}

export default Header;
