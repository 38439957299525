import React, { useContext, useState } from "react";
import { Auth } from "aws-amplify";
import { deleteAllCookies } from "../../../utils/common";
import { LibertateContext } from "../../../context/Context";
import styles from "../../pages/Login.module.scss"
const FormChangePass = ({ loginPageHandler }) => {
  let libertateContext = useContext(LibertateContext);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [changeUserDetails, setChangeUserDetials] = useState({
    changeUserName: "",
    changeCurrentPassword: "",
    changeNewPassword: "",
    changeConfirmPassword: "",
  });
  const [formLoginValidation, setFormLoginValidation] = useState({});

  const validEmail = (e) => {
    var element = e.target;
    const name = event.target.name;
    var reg =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    var isValid = reg.test(element.value);
    if (!isValid && element.value !== "") {
      setChangeUserDetials((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    }
  };
  const validPassword = (e) => {
    var element = e.target;
    const name = event.target.name;
    var reg =
      /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*]{8,32}$/;
    var isValid = reg.test(element.value);
    if (!isValid && element.value !== "") {
      setChangeUserDetials((prevState) => ({
        ...prevState,
        [`error_${name}`]: true,
      }));
    }
  };
  const changeUserDeatailsHandler = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    setChangeUserDetials((prevState) => ({
      ...prevState,
      [name]: value,
      [`error_${name}`]: false,
    }));
    setFormLoginValidation((prevState) => ({
      ...prevState,
      [name]: false,
      userDoesNotExists: false,
      errMessage: "",
    }));
  };
  const handleSubmit = async () => {
    const valid = await changePassword();
    if (valid) {
      setIsSubmitted(true);
    }
  };

  const changePassword = async () => {
    var username = changeUserDetails["changeUserName"];
    var oldPass = changeUserDetails["changeCurrentPassword"];
    var newPass = changeUserDetails["changeNewPassword"];
    var confPass = changeUserDetails["changeConfirmPassword"];
    let validForm = validation();
    let submitted = false;

    if (validForm) {
      try {
        libertateContext.startLoader();
        const signinUser = await Auth.signIn(username.toLowerCase(), oldPass);
        console.log("signinUser: ", signinUser);
        if (signinUser.challengeName === "NEW_PASSWORD_REQUIRED") {
          await Auth.completeNewPassword(signinUser, newPass)
            .then(async (user) => {
              console.log("respo1", user);
              deleteAllCookies();
              sessionStorage.clear();
              localStorage.clear();
              submitted = true;
              libertateContext.stopLoader();
            })
            .catch((error) => {
              console.log("Error while setting new password", error);
              libertateContext.stopLoader();
              setFormLoginValidation((prevstate) => ({
                ...prevstate,
                userDoesNotExists: true,
                errMessage: error.message,
              }));
            });
        } else {
          await Auth.currentAuthenticatedUser()
            .then(async (user) => {
              await Auth.changePassword(user, oldPass, newPass)
                .then((res) => {
                  console.log("respo", res);
                  deleteAllCookies();
                  sessionStorage.clear();
                  localStorage.clear();
                  submitted = true;
                  libertateContext.stopLoader();
                })
                .catch((error) => {
                  setFormLoginValidation((prevstate) => ({
                    ...prevstate,
                    userDoesNotExists: true,
                    errMessage: error.message,
                  }));
                });
            })
            .catch((error) => {
              console.log(
                "Error while getting current authenticated user",
                error
              );
              setFormLoginValidation((prevstate) => ({
                ...prevstate,
                userDoesNotExists: true,
                errMessage: error,
              }));
            });
        }
        await Auth.signOut();
      } catch (err) {
        console.log("--------------", err);
        setFormLoginValidation((prevstate) => ({
          ...prevstate,
          userDoesNotExists: true,
          errMessage: err.message,
        }));
      }
    }
    return submitted;
  };

  function validation() {
    let valid = true;

    if (changeUserDetails["changeUserName"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        changeUserName: true,
      }));
      valid = false;
    }
    if (changeUserDetails["changeCurrentPassword"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        changeCurrentPassword: true,
      }));
      valid = false;
    }
    if (changeUserDetails["changeNewPassword"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        changeNewPassword: true,
      }));

      valid = false;
    }
    if (changeUserDetails["changeConfirmPassword"] === "") {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        changeConfirmPassword: true,
      }));
      valid = false;
    }
    if (
      changeUserDetails["changeNewPassword"] !==
      changeUserDetails["changeConfirmPassword"]
    ) {
      setFormLoginValidation((prevState) => ({
        ...prevState,
        changeConfirmPassword: true,
      }));
      valid = false;
    }
    console.log("---", valid);

    return valid;
  }
  return (
    <div className="tab-pane fade-in text-center active">
      <div id="formChange" className={styles.formChange}>
        {isSubmitted && (
          <p id="sucMsg" className="text-center text-success">
            Password changed successfully!
          </p>
        )}

        {!isSubmitted && (
          <>
            <span>
              <p className={styles.formTitle}>CHANGE PASSWORD</p>
              <hr />
              <form id="changePassForm">
                <div>
                  {changeUserDetails?.["error_changeUserName"] && (
                    <span className={styles.errMsg}>john.doe@example.com</span>
                  )}
                  {formLoginValidation?.["changeUserName"] && (
                    <span className={styles.errMsg}>Email is required.</span>
                  )}
                  <input
                    autoComplete="off"
                    id="emailId"
                    name="changeUserName"
                    type="text"
                    placeholder="Enter Email Id"
                    onBlur={validEmail}
                    onChange={changeUserDeatailsHandler}
                    value={changeUserDetails["changeUserName"] || ""}
                    style={{
                      borderColor: `${
                        changeUserDetails?.["error_changeUserName"] ||
                        formLoginValidation?.["changeUserName"]
                          ? "red"
                          : ""
                      }`,
                    }}
                  />
                </div>
                <div>
                  {changeUserDetails?.["error_changeCurrentPassword"] && (
                    <span className={styles.errMsg}>
                      Password at least 8 digits with upper, lower case
                      alphabet, Numbers and special characters.
                    </span>
                  )}
                  {formLoginValidation?.["changeCurrentPassword"] && (
                    <span className={styles.errMsg}>
                      Old Password is required.
                    </span>
                  )}
                  <input
                    autoComplete="off"
                    id="oldPass"
                    type="password"
                    name="changeCurrentPassword"
                    placeholder="Old Password"
                    onBlur={validPassword}
                    onChange={changeUserDeatailsHandler}
                    value={changeUserDetails["changeCurrentPassword"] || ""}
                    style={{
                      borderColor: `${
                        changeUserDetails?.["error_changeCurrentPassword"] ||
                        formLoginValidation?.["changeCurrentPassword"]
                          ? "red"
                          : ""
                      }`,
                    }}
                  />
                </div>
                <div>
                  {changeUserDetails?.["error_changeNewPassword"] && (
                    <span className={styles.errMsg}>
                      Password at least 8 digits with upper, lower case
                      alphabet, Numbers and special characters.
                    </span>
                  )}
                  {formLoginValidation?.["changeNewPassword"] && (
                    <span className={styles.errMsg}>
                      New Password is required.
                    </span>
                  )}
                  <input
                    autoComplete="off"
                    id="newPass"
                    type="password"
                    name="changeNewPassword"
                    placeholder="New Password"
                    onBlur={validPassword}
                    onChange={changeUserDeatailsHandler}
                    value={changeUserDetails["changeNewPassword"] || ""}
                    style={{
                      borderColor: `${
                        changeUserDetails?.["error_changeNewPassword"] ||
                        formLoginValidation?.["changeNewPassword"]
                          ? "red"
                          : ""
                      }`,
                    }}
                  />
                </div>
                <div>
                  {changeUserDetails?.["error_changeConfirmPassword"] && (
                    <span className={styles.errMsg}>
                      Password at least 8 digits with upper, lower case
                      alphabet, Numbers and special characters.
                    </span>
                  )}
                  {formLoginValidation?.["changeConfirmPassword"] && (
                    <span className={styles.errMsg}>
                      The passwords don't match.
                    </span>
                  )}
                  <input
                    autoComplete="off"
                    id="confPass"
                    name="changeConfirmPassword"
                    type="password"
                    placeholder="Confirm Password"
                    onBlur={validPassword}
                    onChange={changeUserDeatailsHandler}
                    value={changeUserDetails["changeConfirmPassword"] || ""}
                    style={{
                      borderColor: `${
                        changeUserDetails?.["error_changeConfirmPassword"] ||
                        formLoginValidation?.["changeConfirmPassword"]
                          ? "red"
                          : ""
                      }`,
                    }}
                  />
                </div>
                <span className={styles.errMsg} />
                {formLoginValidation?.["userDoesNotExists"] && (
                  <span className={styles.errMsg} >
                    {formLoginValidation?.["errMessage"]}
                  </span>
                )}
                <button
                  type="button"
                  id="changeSubmit"
                  className="btnFormFinal"
                  onClick={handleSubmit}
                >
                  SUBMIT
                </button>
              </form>
            </span>
            <div className={`${styles.formBottomLinks} changeLater`}>
              <a
                data-toggle="tab"
                aria-expanded="true"
                onClick={() => {
                  loginPageHandler();
                }}
              >
                I will change later
              </a>
            </div>
          </>
        )}
        {isSubmitted && (
          <div
            className={`${styles.formBottomLinks}  text-center ${styles.submitDiv}`}>
            <a
              data-toggle="tab"
              aria-expanded="true"
              style={{
                color: "white",
              }}
              onClick={() => {
                loginPageHandler();
              }}
            >
              Login
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default FormChangePass;
