import moment from "moment";
import numeral from "numeral";
import carrierList from "./carrierList.json";
import { raterMcpStateData } from "../api/commonApi";

function getEmodStatesData(statesData, emodStatesData) {
  let ncciStates = [...statesData.ncciStates, ...statesData.actingNcciStates];
  if (ncciStates.length > 0) {
    if (!emodStatesData.ncci) {
      emodStatesData.ncci = {
        stateList: [],
        response: false,
        displayMore: false,
        date1: { value: moment() },
        date2: { value: moment() },
      };
    }
    emodStatesData.ncci.stateList = [...new Set(ncciStates)];
  } else {
    delete emodStatesData.ncci;
  }
  for (let state in emodStatesData) {
    if (state !== "ncci" && !(state in statesData.indBureauStates)) {
      delete emodStatesData[state];
    }
  }
  for (let state of statesData.indBureauStates) {
    if (!(state in emodStatesData)) {
      emodStatesData[state] = {
        response: false,
        displayMore: false,
        date1: { value: moment() },
        date2: { value: moment() },
      };
    }
  }

  return emodStatesData;
}

let createEmodData = (data, key) => {
  return new Promise(async (resolve, reject) => {
    try {
      let stateSet = new Set();

      let carrierPeoProgramList = carrierList.carrierPeoProgramList;

      let programList = [];

      for (let carrierPeoProgramObj of carrierPeoProgramList) {
        programList.push({ display_name: carrierPeoProgramObj.program });
      }

      let stateEmodMap = {};

      for (let row of data) {
        let state = row["Location State"];
        if (state) stateSet.add(state.toLowerCase());
        if (!(state.toLowerCase() in stateEmodMap))
          stateEmodMap[state.toLowerCase()] = {
            emod: row["EMOD"],
            date: row["Emod Effective Date"],
          };
      }

      let stateList = Array.from(stateSet);
      // let statesData;
      //    getExpModStateCategory(stateList)
      //   .then((response) => {
      //     statesData = response.data;
      //     console.log("statesData: ", statesData);
      //     let emod = getEmodStatesData(statesData, {});
      //     resolve({ data: emod, key });
      //   })
      //   .catch((error) => {
      //     console.log("error", error);
      //     reject();
      //   });

      let emodStatesData = {};

      raterMcpStateData(stateList, programList)
        .then((response) => {
          if (response?.data?.length > 0) {
            for (let state of response.data) {
              if (!(state in emodStatesData)) {
                emodStatesData[state] = {
                  rate1: {
                    value: "",
                  },
                  response: false,
                  displayMore: false,
                  date1: { value: moment() },
                  date2: { value: moment() },
                };
                if (state in stateEmodMap) {
                  emodStatesData[state].rate1.value =
                    stateEmodMap[state]?.emod || " ";
                  emodStatesData[state].date1.value =
                    stateEmodMap[state]?.date || moment();
                }
              }
            }

            for (let state in emodStatesData) {
              if (!stateList.includes(state)) {
                delete emodStatesData[state];
              }
            }
          }
          resolve({ data: emodStatesData, key });
        })
        .catch((error) => {
          console.log("error", error);
          reject();
        });
    } catch (error) {
      console.log("error in createEmodData: ", error);
      reject();
    }
  });
};

let getLocationInfo = (data) => {
  let latestEffectiveDate;
  let stateMap = {};
  let locationIndexMap = {};
  // let stateIndex = 0;

  data.forEach((e) => {
    if (!latestEffectiveDate) {
      latestEffectiveDate = e["Expected Start Date"];
    }
    // if (e["Expected Start Date"] === latestEffectiveDate) {
    if (!(e["Location#"] in stateMap)) {
      stateMap[e["Location#"]] = {
        number: Number(e["Location#"]),
        key: Math.random(),
        state: { value: e["Location State"].toUpperCase() },
        classCodesInfo: {},
        cityName: { value: e["Location City"] },
        street: { value: e["Location Street Address"] },
        zipCode: { value: e["Location Zip"] },
      };

      if (e["Client Name"]) {
        stateMap[e["Location#"]].street.value =
          e["Client Name"] + " - " + stateMap[e["Location#"]].street.value;
      }
      // stateIndex++;
    }

    let largestKey;
    if (Object.keys(stateMap[e["Location#"]].classCodesInfo).length === 0) {
      largestKey = -1;
    } else {
      largestKey = Math.max(
        ...Object.keys(stateMap[e["Location#"]].classCodesInfo)
      );
    }

    let ccd;
    if (e["Class Code"] && e["Class Code Description"]) {
      ccd = {
        value: `${e["Class Code"]}: ${
          e["Class Code Description"] || "no description found"
        }`,
      };
    } else {
      ccd = { value: undefined };
    }
    stateMap[e["Location#"]].classCodesInfo[++largestKey] = {
      classCodeDescription: ccd,
      ft: isNaN(e["FT"]) ? { value: 0 } : { value: e["FT"] },
      pt: isNaN(e["PT"]) ? { value: 0 } : { value: e["PT"] },
      payroll: { value: numeral(e["Payroll"]).format("$0,0") },
    };
    // }
  });

  for (let location in stateMap) {
    locationIndexMap[stateMap[location].number] = stateMap[location];
  }

  return locationIndexMap;
};

let getCompanyProfile = (data) => {
  let companyProfile = {};
  let headerMap = {
    descriptionOfOperations: "Description of Operations",
    companyName: "Company Name",
    firstName: "First Name",
    lastName: "Last Name",
    phoneNumber: "Phone Number",
    producer: "Producer",
    fein: "fein",
    entityType: "Entity Type",
    cityName1: "Primary Location City",
    cityName2: "Mailing Address City",
    state1: "Primary Location State",
    state2: "Mailing Address State",
    street1: "Primary Location Street Address",
    street2: "Mailing Address Street Address",
    zipCode1: "Primary Location ZIP",
    zipCode2: "Mailing Address Zip",
    yearsInBusiness: "Years in Business",
  };
  if (data.length > 0) {
    for (let key in headerMap) {
      companyProfile[key] = { value: data[0][headerMap[key]] || "" };
    }
    companyProfile.effectiveDate = {
      value: moment(
        data[0]["Expected Start Date"] || moment(),
        "M/D/YYYY"
      ).format("YYYY-MM-DD"),
    };
    companyProfile.expectedStartDate = companyProfile.effectiveDate;
  }

  return companyProfile;
};

let sortInputData = (data) => {
  // get the company profile
  let companyProfile = getCompanyProfile(data);

  // get the location info
  let locationIndexMap = getLocationInfo(data);

  return { companyProfile, locationIndexMap };
};

export const parseInputData = async (fileData) => {
  return new Promise(async (resolve, reject) => {
    try {
      let data = {};
      for (let item of fileData) {
        if (!item.fein) continue;
        if (!(item.fein in data)) {
          data[item.fein] = [];
        }
        data[item.fein].push(item);
      }

      let response = {};

      for (let key in data) {
        response[key] = {};
        let { companyProfile, locationIndexMap } = sortInputData(data[key]);
        response[key].companyProfile = companyProfile;
        response[key].childrenLoc = locationIndexMap;
        response[key].emodStatesData = {};

        await createEmodData(data[key], key)
          .then((res) => {
            response[key].emodStatesData = res.data || {};
          })
          .catch((error) => {
            console.log(error);
          });
      }

      resolve(response[Object.keys(response)[0]]);
    } catch (error) {
      console.log("error in parseInputData: ", error);
      reject();
    }
  });
};
