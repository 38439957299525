import React, { useEffect, useState } from "react";
import { isOnlyNumAndTwoDecimal } from "../../utils/common";
import { Form, InputGroup } from "react-bootstrap";

const info_icon = require("../../images/info_icon.png");

function RecalculateQuoteRow({
  isDisabled,
  creditChecked,
  value,
  indx,
  infoText,
  updateDebitCreditData,
  scheduleRangeError,
}) {
  const [hasError, setHasError] = useState(false);

  return (
    <div className="row" id={indx}>
      <div className="text-center w-100 ml-1 mr-1">
        <Form>
          <div className="d-flex justify-content-around align-items-center debit_credit_num_container">
            <div className="debit_credit_num" style={{ width: "115px" }}>
              <InputGroup>
                <Form.Control
                  type="number"
                  style={
                    scheduleRangeError || hasError ? { borderColor: "red" } : {}
                  }
                  defaultValue={value}
                  disabled={isDisabled ? true : false}
                  step={0.01}
                  onBlur={(e) => {
                    if (isOnlyNumAndTwoDecimal(e))
                      updateDebitCreditData(creditChecked, e.target.value);
                  }}
                  onKeyUp={(e) => {
                    let val = isOnlyNumAndTwoDecimal(e);
                    if (!val) {
                      setHasError(true);
                    } else {
                      setHasError(false);
                    }
                  }}
                  placeholder={creditChecked ? "Cr" : "Dr"}
                  aria-label="Debit or Credit"
                  aria-describedby="basic-addon2"
                  max="100"
                />
                <InputGroup.Text>%</InputGroup.Text>
              </InputGroup>
            </div>
            <div className={isDisabled ? "" : "switch_container"}>
              <Form.Check
                type="switch"
                id={"credit-switch_" + indx}
                checked={creditChecked}
                disabled={isDisabled ? true : false}
                onChange={(e) => updateDebitCreditData(e.target.checked, value)}
                label={creditChecked ? "Cr" : "Dr"}
              />
            </div>
            <div className="ml-1">
              <img
                src={info_icon}
                alt="info icon"
                style={{
                  width: "1rem",
                }}
                title={infoText}
              />
            </div>
          </div>
        </Form>
      </div>
    </div>
  );
};

const ScheduledDebitCredit = (props) => {
  const [scheduleRange, setScheduleRange] = useState({ credit: 0, debit: 0 });
  const [infoText, setInfoText] = useState("");
  const [isDisabled, setIsDisabled] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    let range = props.cacheStateRange?.[props.state];

    if (range) {
      let _scheduleRange = { credit: range.credit, debit: range.debit };

      setScheduleRange(_scheduleRange);

      if (_scheduleRange.credit === 0 && _scheduleRange.debit === 0) {
        setInfoText("NOT range");
        setIsDisabled(true);
      } else {
        let str = props.creditChecked ? "credit" : "debit";
        setInfoText(`Your ${str} range is 0 - ${_scheduleRange[str]}`);
      }
    } else {
      setInfoText("NOT range");
      setIsDisabled(true);
    }
  }, [props.state]);

  const checkEligibility = (isChecked, value) => {
    let msg, eligible;
    if (scheduleRange.credit === 0 && scheduleRange.debit === 0)
      return { eligible: false, msg: "No Range Available!" };
    if (isChecked) {
      msg = `Your credit range is 0 - ${scheduleRange.credit}`;
      let rangeVal = scheduleRange.credit;
      eligible = value <= rangeVal && value >= 0;
      // new_sdr_factor[state] = -parseInt(sdr_factor).toString();
    } else {
      msg = `Your debit range is 0 - ${scheduleRange.debit}`;
      let rangeVal = scheduleRange.debit;
      eligible = value <= rangeVal && value >= 0;
      // new_sdr_factor[state] = parseInt(sdr_factor).toString();
    }
    return { msg, eligible };
  };

  const updateDebitCreditData = (isChecked, value) => {
    let { msg, eligible } = checkEligibility(isChecked, value || 0);

    if (eligible) {
      setHasError(false);

      //update value using parent function
      props.onChangeDebitCreditHandler(
        { isChecked, value, error: false },
        props.state,
        props.carrier
      );
    } else {
      setHasError(true);

      props.onChangeDebitCreditHandler(
        { isChecked, value: value, error: true },
        props.state,
        props.carrier
      );
    }
    setInfoText(msg);
  };

  return (
    <>
      <RecalculateQuoteRow
        indx={`${props.state}_${props.carrier}_recalc`}
        creditChecked={props.creditChecked}
        value={props.value}
        isDisabled={isDisabled}
        infoText={infoText}
        scheduleRangeError={hasError}
        updateDebitCreditData={updateDebitCreditData}
      />
    </>
  );
};

export default ScheduledDebitCredit;
