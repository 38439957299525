import { setCookie } from "./session_data";
import { Auth } from "aws-amplify";
import $ from "jquery";
import { getUserAuthStatus, showErrAlert, getHeader } from "./common";
import {
  getStateAndCity,
  getExpModStateCategory,
  raterMcpStateData,
  postTablesData,
} from "../api/commonApi";
import {
  sendRandomPassword,
  putUserIntoGroup,
  getEntityType,
} from "../api/formCompProApi";

import moment from "moment";
import carrierList from "./carrierList.json";
window.jQuery = $;
window.$ = $;
global.jQuery = $;
require("bootstrap");
let global_password = "";
export function stepOneGet() {
  $("#loader").css("display", "block");
  getEntityType().then(function (response) {
    let businessTypeData = response.data.entities;
    let businessTypeList = "<option key='' value=''>Select One</option>";
    for (let i = 0; i < businessTypeData.length; i++) {
      businessTypeList +=
        "<option key='" +
        i +
        "' value='" +
        businessTypeData[i] +
        "'>" +
        businessTypeData[i] +
        "</option>";
    }
    $("#businessTypeId").html(businessTypeList);

    let liabilityLimitData = response.data.limits;
    let liabilityLimitList = ""; //"<option key='' value=''>Select One</option>";
    for (let i = 0; i < liabilityLimitData.length; i++) {
      liabilityLimitList +=
        "<option key='" +
        i +
        "' value='" +
        liabilityLimitData[i] +
        "'>" +
        liabilityLimitData[i] +
        "</option>";
    }
    $("#liabilityLimitId").html(liabilityLimitList);
  });
}

export async function submitCompPro(selDateEff, selDateExp, selDateBus) {
  let addressDiv = $("#addressDiv");
  let locCount = addressDiv.children().length;
  let prospectAddresses = [];
  let checkErr = false;
  for (let i = 0; i < locCount; i++) {
    let form = true;
    let addressBlock = addressDiv.children()[i];

    if ($(addressBlock).find("input[name=addLine1]").val().trim() === "") {
      $(addressBlock).find("input[name=addLine1]").css("border-color", "red");
      $(addressBlock)
        .find("input[name=addLine1]")
        .siblings(".errMsg")
        .html(" required");
      $(addressBlock).find("input[name=addLine1]").focus();
      form = false;
    }
    if ($(addressBlock).find("input[name=zipCode]").val().trim().length !== 5) {
      let errMsg;
      if ($(addressBlock).find("input[name=zipCode]").val().trim().length > 0) {
        errMsg = " invalid";
      } else {
        errMsg = " required";
      }
      $(addressBlock).find("input[name=zipCode]").css("border-color", "red");
      $(addressBlock)
        .find("input[name=zipCode]")
        .siblings(".errMsg")
        .html(errMsg);
      $(addressBlock).find("input[name=zipCode]").focus();
      form = false;
    }
    if ($(addressBlock).find("select[name=stateCodes]").val() === "") {
      $(addressBlock)
        .find("select[name=stateCodes]")
        .css("border-color", "red");
      $(addressBlock)
        .find("select[name=stateCodes]")
        .siblings(".errMsg")
        .html(" required");
      $(addressBlock).find("select[name=stateCodes]").focus();
      form = false;
    }
    if ($(addressBlock).find("input[name=cityName]").val().trim() === "") {
      $(addressBlock).find("input[name=cityName]").css("border-color", "red");
      $(addressBlock)
        .find("input[name=cityName]")
        .siblings(".errMsg")
        .html(" required");
      $(addressBlock).find("input[name=cityName]").focus();
      form = false;
    }
    let validForm = validateForm(selDateEff, selDateExp, selDateBus);

    let noErr = noErrorInForm();

    if (!(validForm && noErr)) {
      form = false;
    }
    if (form) {
      let session = await Auth.currentSession()
        .then((res) => {
          let userRole = res.accessToken.payload["cognito:groups"][0];
          let userName = res.accessToken.payload.username.split("@")[0];
          if (userRole !== "User") {
            form = false;
          }
          let currProspectDetails = {
            emailAddress: res.accessToken.payload.userName,
          };
          sessionStorage.setItem(
            "currProspect",
            JSON.stringify(currProspectDetails)
          );
          $(".prominent").html(userName);
        })
        .catch((err) => {
          if (err === "No current user") {
            form = false;
            $("#profileSaveButton").css("border-color", "red");
            $("#profileSaveButton")
              .siblings(".errMsg")
              .html(" Please do SignIn or Confirm");
            $("#profileSaveButton").focus();
            $("#emailStatus").css("border-color", "red");
            $("#emailStatus").focus();
          }
        });
    }
    if (form) {
      let addressLine2 =
        $(addressBlock).find("input[name=addLine2]").val() === ""
          ? "None"
          : $(addressBlock).find("input[name=addLine2]").val();

      let addressObj = {
        addressId: i,
        streetLineOne: $(addressBlock).find("input[name=addLine1]").val(),
        streetLineTwo: addressLine2,
        zipCode: $(addressBlock).find("input[name=zipCode]").val(),
        city: $(addressBlock).find("input[name=cityName]").val(),
        stateCode: $(addressBlock).find("select[name=stateCodes]").val(),
      };
      prospectAddresses.push(addressObj);
    } else {
      checkErr = true;
      return form;
    }
  }
  if (!checkErr) {
    checkErr = false;
    let currProspectDetails = JSON.parse(
      sessionStorage.getItem("currProspect")
    );
    $("#loader").css("display", "block");
    let email = $("#emailAddress").val();
    if (
      $("#emailAddress").val() === null ||
      $("#emailAddress").val() === undefined
    ) {
      email = localStorage.getItem(
        "CognitoIdentityServiceProvider.4g3f16ghe5udk05udri1ihcl5t.LastAuthUser"
      );
    }

    let data = {
      companyName: $("#companyName").val(),
      description: $("#description").val(),
      contactPerson: $("#contactPerson").val(),
      phoneNumber: $("#phoneNumber")
        .val()
        .replace("(", "")
        .replace(")", "")
        .replace(" ", "")
        .replace("-", ""),
      emailAddress: email,
      businessTypeId: $("#businessTypeId").val(),
      federalEmployeeId: $("#federalEmployeeId").val().replace("-", ""),
      businessStartDate: $("#businessStartDate input").val(),
      effectiveDate: $("#effectiveDate input").val(),
      expirationDate: $("#expirationDate input").val(),
      liabilityLimitId: $("#liabilityLimitId").val(),
      createdBy: "USER",
      prospectAddresses: prospectAddresses,
    };

    if (currProspectDetails) {
      currProspectDetails = data;
      if (
        currProspectDetails.emailAddress === null ||
        (data.emailAddress !== undefined &&
          data.emailAddress !== currProspectDetails.emailAddress)
      ) {
        currProspectDetails.emailAddress = data.emailAddress.toLowerCase();
      }
      currProspectDetails.prospectAddresses = Object.assign(
        {},
        currProspectDetails.prospectAddresses
      );
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(currProspectDetails)
      );
    } else {
      currProspectDetails = data;
      currProspectDetails.prospectAddresses = Object.assign(
        {},
        currProspectDetails.prospectAddresses
      );
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(currProspectDetails)
      );
    }
    let tableName = "ProspectTable";
    data.formStage = "two";

    let stateList = { states: [] };
    prospectAddresses.forEach((e) => {
      if (!stateList.states.includes(e.stateCode.toLowerCase())) {
        stateList.states.push(e.stateCode.toLowerCase());
      }
    });

    getExpModStateCategory(stateList)
      .then((response) => {
        data.expModStates = response.data;
        postTablesData(tableName, data)
          .then(function (response) {
            try {
              setCookie(response.data, locCount, 1);
              sessionStorage.setItem("formStage", "two");
              sessionStorage.setItem("isLoggedIn", true);
              //window.location.reload();
            } catch (err) {
              showErrAlert(err.message);
            }
          })
          .catch(function (e) {
            try {
              setCookie("GI347YTGIW7YTN5I4UGYWG45546H4GJ354", locCount, 1);
              sessionStorage.setItem("formStage", "two");
              sessionStorage.setItem("isLoggedIn", true);
              //window.location.reload();
            } catch (err) {
              showErrAlert(err.message);
            }
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }
}

function validateForm(selDateEff, selDateExp, selDateBus) {
  let form = true;
  let fieldsToFocus = [];

  // compare dates
  if (selDateBus && selDateBus.isAfter(selDateEff)) {
    $("#effectiveDate")
      .siblings(".errMsg")
      .html(
        " effective date should not be earlier than the business start date"
      );
    form = false;
  } else {
    $("#effectiveDate").siblings(".errMsg").html("");
  }
  if (selDateEff.isAfter(selDateExp)) {
    $("#expirationDate")
      .siblings(".errMsg")
      .html(" expiration date should not be earlier than the effective date");
    form = false;
  } else {
    $("#expirationDate").siblings(".errMsg").html("");
  }

  if (
    $("#companyName").val().trim() === "" ||
    $("#companyName").css("border-left-color") === "rgb(255, 0, 0)"
  ) {
    $("#companyName").css("border-color", "red");
    $("#companyName").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#companyName");
    form = false;
  }
  if ($("#businessTypeId").val() === "") {
    $("#businessTypeId").css("border-color", "red");
    $("#businessTypeId").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#businessTypeId");
    form = false;
  }
  if ($("#description").val().trim() === "") {
    $("#description").css("border-color", "red");
    $("#description").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#description");
    form = false;
  }
  if (
    $("#phoneNumber").val() === "" ||
    $("#phoneNumber").val().trim().length < 14
  ) {
    let errMsg;
    if ($("#phoneNumber").val().trim() === "") {
      errMsg = " required";
    } else {
      errMsg = " invalid";
    }
    $("#phoneNumber").css("border-color", "red");
    $("#phoneNumber").siblings(".errMsg").html(errMsg);
    fieldsToFocus.push("#phoneNumber");
    form = false;
  }
  if (
    $("#contactPerson").val().trim() === "" ||
    $("#contactPerson").css("border-left-color") === "rgb(255, 0, 0)"
  ) {
    $("#contactPerson").css("border-color", "red");
    $("#contactPerson").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#contactPerson");
    form = false;
  }
  if ($("#liabilityLimitId").val() === "") {
    $("#liabilityLimitId").css("border-color", "red");
    $("#liabilityLimitId").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#liabilityLimitId");
    form = false;
  }
  if (sessionStorage.getItem("isLoggedIn")) {
    if ($("#emailAddress").val() === "") {
      $("#emailAddress").css("border-color", "red");
      $("#emailAddress").siblings(".errMsg").html(" required");
      fieldsToFocus.push("#emailAddress");
      form = false;
    }
  }
  if (
    $("#federalEmployeeId").val().trim() === "" ||
    $("#federalEmployeeId").val().trim().length < 10
  ) {
    let errMsg;
    if ($("#federalEmployeeId").val().trim() === "") {
      errMsg = " required";
    } else {
      errMsg = " invalid";
    }
    $("#federalEmployeeId").css("border-color", "red");
    $("#federalEmployeeId").siblings(".errMsg").html(errMsg);
    fieldsToFocus.push("#federalEmployeeId");
    form = false;
  }
  if ($("#businessStartDate input").val() === "") {
    $("#businessStartDate input").css("border-color", "red");
    $("#businessStartDate").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#businessStartDate input");
    form = false;
  }
  if ($("#effectiveDate input").val() === "") {
    $("#effectiveDate input").css("border-color", "red");
    $("#effectiveDate input").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#effectiveDate input");
    form = false;
  }
  if ($("#expirationDate input").val() === "") {
    $("#expirationDate input").css("border-color", "red");
    $("#expirationDate input").siblings(".errMsg").html(" required");
    fieldsToFocus.push("#expirationDate input");
    form = false;
  }
  if (fieldsToFocus.length) {
    $(fieldsToFocus[0]).focus();
  }
  return form;
}

function noErrorInForm() {
  let form = true;
  $(".errMsg").each(function () {
    if ($(this).html() !== "") {
      form = false;
      return false;
    }
  });
  return form;
}

export function resendOTPMethod() {
  if ($("#emailAddress").val() === "") {
    $("#emailAddress").css("border-color", "red");
    $("#emailAddress").siblings(".errMsg").html(" is required");
    $("#emailAddress").focus();
  } else if ($("#emailAddress").css("border-color") === "rgb(255, 0, 0)") {
    $("#emailAddress").focus();
    return false;
  } else {
    $("#resendOTP").css("display", "none");
    $("#otpSentMsg").css("display", "none");
    $("#verifyStatus").attr("disabled", false);
    $("#emailOTP").attr("disabled", false);
    $("#verifyStatus").html("Verify");
    let data = {
      emailAddress: $("#emailAddress").val(),
    };
    let username = data.emailAddress;
    Auth.resendSignUp(username)
      .then(function (res) {
        $("#emailStatus").css("display", "none");
        $("#otpSendMsg").css("display", "none");
        $("#otpSec").css("display", "block");
        $("#otpSentMsg").css("display", "block");
        let duration = 120;
        let display = $("#timerOTP");
        clearInterval(timerInterval);
        startTimer(duration, display);
        $("#timerOTP").css("display", "block");
      })
      .catch(function (err) {
        $("#otpSendMsg").css("display", "none");
        $("#emailStatus").css("border-color", "red");
        $("#emailStatus").siblings(".errMsg").html(err.message);
        $("#emailAddress").focus();
      });
  }
}

function generateRandomPassword(length) {
  let uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
  let lowercase = "abcdefghijklmnopqrstuvwxyz";
  let numbers = "0123456789";
  let symbols = "!@#$%^&*";
  let all = uppercase + lowercase + numbers + symbols;
  let password = "";
  let character = Math.floor(Math.random() * uppercase.length);
  password += uppercase.substring(character, character + 1);
  character = Math.floor(Math.random() * lowercase.length);
  password += lowercase.substring(character, character + 1);
  character = Math.floor(Math.random() * numbers.length);
  password += numbers.substring(character, character + 1);
  character = Math.floor(Math.random() * symbols.length);
  password += symbols.substring(character, character + 1);
  for (let index = 0; index < length - 4; index++) {
    character = Math.floor(Math.random() * all.length);
    password += all.substring(character, character + 1);
  }

  return password;
}

function signUp(username, password) {
  Auth.signUp({
    username,
    password,
    attributes: {
      email: username,
    },
  })
    .then((res) => {
      $("#emailStatus").css("display", "none");
      $("#otpSec").css("display", "block");
      $("#reset").css("display", "block");
      $("#otpSentMsg").css("display", "block");
      $("#otpSendMsg").css("display", "none");
      let duration = 120;
      let display = $("#timerOTP");
      clearInterval(timerInterval);
      startTimer(duration, display);
      $("#timerOTP").css("display", "block");
      global_password = password;
    })
    .catch((err) => {
      $("#otpSendMsg").css("display", "none");
      $("#emailStatus").css("border-color", "red");
      $("#emailStatus").siblings(".errMsg").html(err.message);
      $("#emailAddress").focus();
    });
}

export async function sendEmail() {
  $("#profileSaveButton").siblings(".errMsg").html("");
  if ($("#emailAddress").val() === "") {
    $("#emailAddress").css("border-color", "red");
    $("#emailAddress").siblings(".errMsg").html(" required");
    $("#emailAddress").focus();
  } else if ($("#emailAddress").css("border-color") === "rgb(255, 0, 0)") {
    $("#emailAddress").focus();
    return false;
  } else {
    $("#otpSendMsg").css("display", "block");
    $("#resendOTP").css("display", "none");
    $("#otpSentMsg").css("display", "none");
    $("#verifyStatus").attr("disabled", false);
    $("#emailOTP").attr("disabled", false);
    $("#verifyStatus").html("Verify");

    // $('#profileSaveButton').css('display', 'none');
    let data = {
      emailAddress: $("#emailAddress").val(),
    };
    let username = data.emailAddress;
    let password = generateRandomPassword(8);
    let userStatus = await getUserAuthStatus(username, password);

    switch (userStatus) {
      case "UserNotFoundException":
        signUp(username, password);
        break;
      case "UserNotConfirmedException":
        resendOTPMethod();
        break;
      case "NotAuthorizedException":
        signUp(username, password);
        break;
      default:
        showErrAlert(userStatus);
        return false;
    }
  }
}

let timerInterval;
function startTimer(duration, display) {
  let timer = duration,
    minutes,
    seconds;
  timerInterval = setInterval(function () {
    minutes = parseInt(timer / 60, 10);
    seconds = parseInt(timer % 60, 10);
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    display.html(minutes + ":" + seconds);
    if (--timer < 0) {
      display.html("Expired");
      $("#emailOTP").attr("disabled", true);
      $("#verifyStatus").html("Retry!");
      $("#verifyStatus").attr("disabled", true);
      $("#resendOTP").css("display", "block");
      clearInterval(timerInterval);
    } else {
    }
  }, 1000);
}

export function reset() {
  // alert("called");
  clearInterval(timerInterval);
  $("#emailOTP").attr("disabled", true);
  $("#timerOTP").html("");
  $("#emailStatus").css("display", "none");
  $("#emailOTP").attr("disabled", false);
  $("#otpSec").css("display", "none");
  $("#otpSentMsg").css("display", "none");
  $("#otpSendMsg").css("display", "none");
  $("#otpVerify").css("display", "none");
  $("#emailStatus").css("display", "block");
}

export async function verifyOTP() {
  if ($("#emailOTP").val() === "") {
    $("#emailOTP").css("border-color", "red");
    $("#emailOTP").siblings(".errMsg").html(" is required");
    $("#emailOTP").focus();
  } else if ($("#passwordField").css("border-color") === "rgb(255, 0, 0)") {
    $("#passwordField").focus();
    return false;
  } else if ($("#timerOTP").html() === "Expired") {
    $("#otpSec").css("display", "none");
    $("#emailStatus").css("display", "block");
    $("#emailOTP").attr("disabled", false);
    $("#timerOTP").html("");
  } else {
    let username = $("#emailAddress").val();
    let OTP = $("#emailOTP").val();
    Auth.confirmSignUp(username, OTP)
      .then((res) => {
        let details = {
          to_email: username,
          random_password: global_password,
        };
        sendRandomPassword(details)
          .then(function (res) {
            if (res.data === "Email sent successfully") {
              putUserIntoGroup(username)
                .then((res) => {
                  clearInterval(timerInterval);
                  $("#timerOTP").html("");
                  $("#emailStatus").css("display", "none");
                  $("#emailOTP").attr("disabled", false);
                  $("#otpSec").css("display", "none");
                  $("#otpSentMsg").css("display", "none");
                  $("#otpSendMsg").css("display", "none");
                  $("#otpVerify").css("display", "none");
                  $("#verifySuc").css("display", "block");
                  $("#emailStatus").css("border-color", "green");
                })
                .catch((err) => {
                  console.log("Error in User group", err);
                });
            }
          })
          .catch((err) => {
            console.log("Error in password sent", err);
          });
      })
      .catch((err) => console.log("error confirming signing up: ", err));
  }
}
export function adjustDates() {
  let effDate = $("#effectiveDate input").val();
  $("#expirationDate input").val(effDate);
}

export function getEmodStatesData(statesData, emodStatesData) {
  let validateEmodStateObject =
    JSON.parse(sessionStorage.getItem("validateEmodState")) || "";
  let ncciStates = [...statesData.ncciStates, ...statesData.actingNcciStates];
  console.log("ncciStates: ", ncciStates);
  if (ncciStates.length > 0) {
    if (!emodStatesData?.ncci) {
      emodStatesData.ncci = {
        stateList: [],
        response: false,
        displayMore: false,
        date1: { value: moment() },
        date2: { value: moment() },
      };
    }
    emodStatesData.ncci.stateList = [...new Set(ncciStates)];
  } else {
    delete emodStatesData.ncci;
  }
  for (let state in emodStatesData) {
    if (state !== "ncci" && !statesData?.indBureauStates?.includes(state)) {
      delete emodStatesData[state];
    }
  }
  for (let state of statesData.indBureauStates) {
    if (!(state in emodStatesData)) {
      emodStatesData[state] = {
        response: false,
        displayMore: false,
        date1: { value: moment() },
        date2: { value: moment() },
      };
    }
  }

  if (validateEmodStateObject) {
    for (let state of statesData.indBureauStates) {
      let valueToUse = "";
      emodStatesData[state] = {
        rate1: {
          value:
            validateEmodStateObject?.["validateEmodState"]?.[state]?.["emod"] ||
            "",
        },
        response: valueToUse ? true : false,
        displayMore: false,
        date1: {
          value: moment(
            validateEmodStateObject?.["validateEmodState"]?.[state]?.[
              "emodDate"
            ]
          ),
        },
        date2: {
          value: moment(
            validateEmodStateObject?.["validateEmodState"]?.[state]?.[
              "emodDate"
            ]
          ),
        },
      };
    }
    if (statesData.actingNcciStates.length > 0) {
      emodStatesData.ncci = {
        rate1: {
          value:
            validateEmodStateObject?.["validateEmodState"]?.[
              statesData?.actingNcciStates[0]
            ]?.["emod"] || "",
        },
        stateList: [],
        response: false,
        displayMore: false,
        date1: { value: moment() },
        date2: { value: moment() },
      };
    }
  }
  return emodStatesData;
}

export function setStateAndCity(zipCode, index) {
  let { childrenLoc, emodStatesData, peoDetails, carrierList } = this.state;
  getStateAndCity(zipCode, {})
    .then((res) => {
      const data = res.data;
      if (data !== `"Data not found"`) {
        childrenLoc[index].state = { value: data.state };
        childrenLoc[index].cityName = { value: data.city };

        this.refreshEmodDetails(
          peoDetails.selectedPeo === "insurecomp",
          emodStatesData,
          childrenLoc,
          peoDetails.selectedPeo,
          carrierList
        );

        this.setState({ childrenLoc });
        // let stateList = [];
        // for (let addressBlockNo in childrenLoc) {
        //   if (childrenLoc[addressBlockNo].state.value) {
        //     stateList.push(
        //       childrenLoc[addressBlockNo].state.value.toLowerCase()
        //     );
        //   }
        // }
        // if (stateList.length > 0) {
        //   let statesData = {
        //     ncciStates: [],
        //     actingNcciStates: [],
        //     indBureauStates: [],
        //   };

        //   let carrierPeoProgramList = carrierList.carrierPeoProgramList;

        //   let programList = [];

        //   for (let carrierPeoProgramObj of carrierPeoProgramList) {
        //     programList.push({ display_name: carrierPeoProgramObj.program });

        //   //
        //   // getExpModStateCategory(stateList)
        //   //   .then((response) => {
        //   //     statesData = response.data;
        //   //   })
        //   //   .catch((error) => {
        //   //     console.log("error", error);
        //   //   })
        //   //   .finally(() => {
        //   //     let newEmodData = getEmodStatesData(statesData, emodStatesData);
        //   //     this.setState({ childrenLoc, emodStatesData: newEmodData });
        //   //   });
        //   raterMcpStateData(stateList, programList)
        //     .then((response) => {
        //       if (response?.data?.length > 0) {
        //         for (let state of response.data) {
        //           if (!(state in emodStatesData)) {
        //             emodStatesData[state] = {
        //               rate1: {
        //                 value: "",
        //               },
        //               response: false,
        //               displayMore: false,
        //               date1: { value: moment() },
        //               date2: { value: moment() },
        //             };
        //           }
        //         }

        //         for (let state in emodStatesData) {
        //           if (!(stateList.includes(state))) {
        //             delete emodStatesData[state];
        //           }
        //         }
        //       }
        //     })
        //     .catch((error) => {
        //       console.log("error", error);
        //     })
        //     .finally(() => {
        //       this.setState({ childrenLoc, emodStatesData });
        //     });
        // } else {
        //   emodStatesData = {};
        //   this.setState({ childrenLoc, emodStatesData });
        // }
      }
    })
    .catch((err) => {
      console.log("Err in get state with zip code:", err);
    });
}

export function setStateAndCity1(zipCode) {
  let { companyProfile } = this.state;
  getStateAndCity(zipCode, {})
    .then((res) => {
      const data = res.data;
      if (data !== `"Data not found"`) {
        companyProfile.state1 = { value: data.state.toLowerCase() };
        companyProfile.cityName1 = { value: data.city };
        this.setState({ companyProfile });
      }
    })
    .catch((err) => {
      console.log("Err in get state with zip code:", err);
    });
}

export function setStateAndCity2(zipCode) {
  let { companyProfile } = this.state;
  getStateAndCity(zipCode, {})
    .then((res) => {
      const data = res.data;
      if (data !== `"Data not found"`) {
        companyProfile.state2 = { value: data.state.toLowerCase() };
        companyProfile.cityName2 = { value: data.city };
        this.setState({ companyProfile });
      }
    })
    .catch((err) => {
      console.log("Err in get state with zip code:", err);
    });
}

export function sumPayroll(childrenLoc) {
  let sum = 0;

  for (let userDataRow in childrenLoc) {
    let userChildlocData = childrenLoc[userDataRow];

    let classcodeInfo = userChildlocData.classCodesInfo;
    classcodeInfo = Object.entries(classcodeInfo);

    for (let i = 0; i < classcodeInfo.length; i++) {
      let paydata = classcodeInfo[i];
      const stringAmount = paydata[1]?.payroll?.value;
      if (!stringAmount) continue;
      const numberAmount = parseFloat(stringAmount.replace(/[^\d.-]/g, ""));
      sum = sum + numberAmount;
    }
  }

  return sum;
}
