import React, { useEffect, useState, useRef } from "react";
import ReactMultiSelectCheckboxes from "react-multiselect-checkboxes";
import { isDeepEquals } from "../../../utils/isDeepEquals";
import styles from "./ReactMultiSelect.module.scss";

export default function ReactMultiSelect(props) {
  const [listToDisplay, setListToDisplay] = useState(props.listToDisplay);

  let selectedList = props.selectedList;

  useEffect(() => {
    if (!isDeepEquals(props.listToDisplay, listToDisplay)) {
      setListToDisplay(props.listToDisplay);
    }
  }, [props.listToDisplay]);

  let isAllSelected =
    listToDisplay.length === selectedList.length ? true : false;

  return (
    <div
      className={
        selectedList?.length > 0 ? "" : styles.react_select_carrier_container
      }
      style={props.style}
    >
      <ReactMultiSelectCheckboxes
        className="react-select-checkbox "
        classNamePrefix="react-select-checkbox"
        getDropdownButtonLabel={() =>
          isAllSelected
            ? `${selectedList.length - 1} selected`
            : selectedList && selectedList.length > 0
              ? `${selectedList.length} selected`
              : "Select"
        }
        value={selectedList}
        options={listToDisplay}
        onChange={(val) => {
          //if select all selected and previously also selected

          if (
            isAllSelected &&
            val.some((item) => item.value === "select_all")
          ) {
            let _val = [];

            for (let v of val) {
              if (v.value !== "select_all") _val.push(v);
            }

            props.handleSelectedListCallback(JSON.parse(JSON.stringify(_val)));
          }
          //if select all selected and not previously selected
          else if (
            !isAllSelected &&
            val.some((item) => item.value === "select_all")
          ) {
            props.handleSelectedListCallback(
              JSON.parse(JSON.stringify(listToDisplay))
            );
          }

          //if select all is not selected but previously was selected
          else if (
            isAllSelected &&
            !val.some((item) => item.value === "select_all")
          ) {
            props.handleSelectedListCallback([]);
          }

          //if select all is not sleected and previously was also not selected
          else {
            props.handleSelectedListCallback(JSON.parse(JSON.stringify(val)));
          }
        }}
      />
    </div>
  );
}
