import axios from "axios";
import { awsUrl, awsUrl2 } from "../config";
import { getHeader } from "../utils/common";

export function getCarrierElig(carrierEligBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl + "/api/getCarrierElig",
        JSON.stringify(carrierEligBody),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getQuotesDataId(dataBody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + `/api/getQuotesDataId`,
        JSON.stringify({ body: dataBody }),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getQuotesData(id) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .get(awsUrl2 + `/api/getQuotesData/${id}`, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function postUsersData(uploadData) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/postUsersData", uploadData, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
