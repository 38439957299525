import React, { useState, useContext, useCallback } from "react";
import Loader from "../common/Loader";
import FormLogin from "../subcompo/sections-login/FormLogin";
import FormReset from "../subcompo/sections-login/FormReset";
import FormChangePass from "../subcompo/sections-login/FormChangePass";
import Header from "../common/Header";
import { Link } from "react-router-dom";
import SideTab from "../../newcomponent/common/SideTab";
import { LibertateContext } from "../../context/Context";
import { useLibertateContext } from "../../context/ContextProvider";
import styles from "./Login.module.scss";

let landingImage = require("../../images/GroupPhoto.png");
let isMobile = window.innerWidth < 900;

function Login(props) {
  let libertateContext = useContext(LibertateContext);
  const [formLogin, setFormLogin] = useState(true);
  const [formReset, setFormReset] = useState(false);
  const [formChangePass, setFormChangePass] = useState(false);

  // to Toggle between login page, reset page ,and change password page.
  const loginPageHandler = useCallback(
    (value) => {
      console.log(value);
      if (value === "changePassword") {
        setFormLogin(false);
        setFormReset(false);
        setFormChangePass(true);
      } else if (value === "resetPassword") {
        setFormLogin(false);
        setFormReset(true);
        setFormChangePass(false);
      } else {
        setFormLogin(true);
        setFormReset(false);
        setFormChangePass(false);
      }
    },
    [formLogin, formReset, formChangePass]
  );
  let loggedInUser = false,
    userGroups = [];

  try {
    if (props.sessionData) {
      let _userGroups =
        props.sessionData.signInUserSession.accessToken.payload[
          "cognito:groups"
        ];
      loggedInUser = true;
      userGroups = _userGroups;
    }
  } catch (error) {}

  return (
    <div>
      {/* <Navbar variant="large-logo" /> */}
      <div className="d-flex">
        <Header sessionData={props.sessionData} />
        {/* <Stats2 /> */}
      </div>
      <div className="position-relative">
        {isMobile && loggedInUser ? <SideTab /> : " "}
        <img
          width="100%"
          src={landingImage}
          alt="Landing Image"
          className={styles.Landing__page__image}
        />
        <div className={styles.workcomp_line}>
          <p className={styles.workcomp_line_paragraph}>
            Workers’ Compensation Rater
          </p>
        </div>
        {!loggedInUser ? (
          <div className={styles.tab_content}>
            {formLogin && <FormLogin loginPageHandler={loginPageHandler} />}
            {formReset && <FormReset loginPageHandler={loginPageHandler} />}
            {formChangePass && (
              <FormChangePass loginPageHandler={loginPageHandler} />
            )}
            {libertateContext?.displayLoader && <Loader />}
          </div>
        ) : userGroups.includes(process.env.REACT_APP_GET_QUOTE_GROUP) ? (
          <div className={styles.start_new_quote}>
            <h1 className={styles.Startnewquotefont}>
              <Link
                to={"/Quote"}
                className={styles.start_new_quote_link}
                onClick={() => {
                  libertateContext.resetEntireState();
                }}
              >
                Start New Quote
              </Link>
            </h1>
          </div>
        ) : (
          <div></div>
        )}
        {/* <Footer /> */}
      </div>
    </div>
  );
}

export default Login;
