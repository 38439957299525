"use strict";
import React, { forwardRef, useEffect, useState, useRef } from "react";
import axios from "axios";
import { awsUrl2, awsUrl } from "../../../config";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import MaterialTable from "material-table";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Search from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Check from "@mui/icons-material/Check";
import AddBox from "@mui/icons-material/AddBox";
import DeleteOutline from "@mui/icons-material/DeleteOutline";
import Edit from "@mui/icons-material/Edit";
import Remove from "@mui/icons-material/Remove";
import ViewColumn from "@mui/icons-material/ViewColumn";
import SaveAlt from "@mui/icons-material/SaveAlt";
import { TablePagination } from "@mui/material";
import parse from "html-react-parser";
import { Modal } from "react-bootstrap";
import { Link } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import Popup from "../../common/Popup";
import TextField from "./UWTextField";
import { ThemeProvider, createTheme } from "@mui/material";
import styles from "./UnderWriter.module.scss";
import downloadImage from "../../../images/file-download-circle-green.svg"

const {
  DownloadDashboardFiles,
} = require("../../subcompo/DownloadDashboardFiles.jsx");

let ismobile = window.innerWidth < 1600;

const tableIcons = {
  Add: forwardRef((props, ref) => <AddBox {...props} ref={ref} />),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Delete: forwardRef((props, ref) => <DeleteOutline {...props} ref={ref} />),
  DetailPanel: forwardRef((props, ref) => (
    <ChevronRight {...props} ref={ref} />
  )),
  Edit: forwardRef((props, ref) => <Edit {...props} ref={ref} />),
  Export: forwardRef((props, ref) => <SaveAlt {...props} ref={ref} />),
  Filter: () => <></>,
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  ThirdStateCheck: forwardRef((props, ref) => <Remove {...props} ref={ref} />),
  ViewColumn: forwardRef((props, ref) => <ViewColumn {...props} ref={ref} />),
};

// Fein format and adding 0 precedence
const formatFein = (value) => {
  if (!value) return "-";
  let fein = "-";
  value = value.split("-").join("");
  var len = value.length,
    dummystr = "000000000";

  if (len === 0) {
    fein = "-";
  } else if (len > 0 && len < 9) {
    value = dummystr.slice(0, 9 - len) + "" + value;
    fein = value[0] + value[1] + "-" + value.slice(2);
  } else {
    fein = value[0] + value[1] + "-" + value.slice(2);
  }

  return fein;
};

// Camel case for companyName
const camelCase = (str) => {
  if (str)
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  return str;
};

const fontfunciton = () => {
  if (window.innerWidth < 1600 && window.innerWidth > 1500) {
    return "0.84rem";
  }
  if (window.innerWidth < 1500 && window.innerWidth > 1410) {
    return "0.8rem";
  }
  if (window.innerWidth < 1410 && window.innerWidth > 1310) {
    return "0.76rem";
  }
  if (window.innerWidth < 1300) {
    return "0.72rem";
  }
};

export const updateData = async (data) => {
  new Promise((resolve, reject) => {
    try {
      axios
        .post(awsUrl + "/api/underWriterData/update", data)
        .then((res) => {
          console.log("data saved");
          resolve();
        })
        .catch((err) => {
          console.log("error while saving", err);
          reject();
        });
    } catch (error) {}
  });
};

const updateDataInUser = (data) => {
  new Promise((resolve, reject) => {
    try {
      let data_req = JSON.parse(JSON.stringify(data));
      if (data_req.sortKeyList) {
        delete data_req.sortKeyList;
      }
      axios
        .post(awsUrl2 + "/api/userTableData/update", data_req)
        .then((res) => {
          console.log("data saved");
          resolve();
        })
        .catch((err) => {
          console.log("error while saving", err);
          reject();
        });
    } catch (error) {}
  });
};

const toastSuccess = (text) =>
  toast.success(text, {
    duration: 3000,
  });

const toastError = (text) =>
  toast.error(text, {
    duration: 3000,
  });

const UnderWriterTab = (props) => {
  const myRef = useRef();
  const [rowDataList, setRowDataList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [modalData, setModalData] = useState({});
  const [domainButtonMap, setDomainButtonMap] = useState({});
  const [style, setStyle] = useState({});
  const [scrollLeft, setScrollLeft] = useState(false);
  const [scrollRight, setScrollRight] = useState(true);
  const [popupData, updatePopUpData] = useState({
    title: "",
    children: <></>,
    acceptBtn: "",
    acceptBtnCallback: () => {},
    rejectBtnCallback: () => {},
    show: false,
  });

  useEffect(() => {
    getAllData();
  }, []);

  const onReleaseClick = (rowData) => {
    let dataToSave = {};
    dataToSave.user_email_id = rowData.user_email_id;
    dataToSave.sortKeyList = rowData.uuidCarriers;
    dataToSave.uw_status = "";
    let recordDetails = {
      uuidList: rowData.uuidCarriers,
      userId: rowData.user_email_id,
    };
    axios
      .post(awsUrl2 + "/api/removeSalesPersonFlag", recordDetails)
      .then((res) => {
        //filter to only show the required fields
        setRowDataList((prevstate) => {
          const _rowDataList = prevstate.filter(
            (element) => element["user_email_id"] !== rowData["user_email_id"]
          );
          return _rowDataList;
        });
        updateData(dataToSave);
        updateDataInUser(dataToSave);
      })
      .catch((err) => console.log("error: ", err));
  };

  const onClickCompanyName = (rowData) => {
    if (rowData.currProspect) {
      sessionStorage.setItem(
        "currProspect",
        JSON.stringify(rowData.currProspect)
      );
    }
    if (rowData.user_email_id) {
      sessionStorage.setItem("user_id", rowData.user_email_id);
    }
    sessionStorage.setItem("uw_flow", "true");

    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }

    if (rowData.address) {
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
    }

    if (rowData.quoteData) {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
    }

    if (rowData.uuidCarriers) {
      sessionStorage.setItem(
        "sortKeyList",
        JSON.stringify(rowData.uuidCarriers)
      );
    }

    sessionStorage.setItem("formStage", "three");
    // sessionStorage.setItem("hideButtons", "true");
  };

  const columnData = [
    {
      title: "Submitted Date",
      field: "submittedDate",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      cellStyle: {
        wordBreak: "break-all",
        padding: "6px 0",
        minWidth: "80px",
        padding: "2px 0px 2px 16px",
      },
      headerStyle: {
        padding: "2px 0px 2px 16px",
        minWidth: "90px",
      },
    },
    {
      title: "Company Name",
      field: "companyname",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "200px",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "6px 5px 6px 10px",
      },
      render: (rowData) => {
        return (
          <Clamp lines={2}>
            <span onClick={() => onClickCompanyName(rowData)}>
              <Link to={"/Quote"}>{camelCase(rowData.companyname)}</Link>
            </span>
          </Clamp>
        );
      },
    },
    {
      title: "FEIN",
      field: "fein",
      filterPlaceholder: "Filter",
      customFilterAndSearch: (term, rowData, field) =>
        customFilterAll(term, rowData, field),

      cellStyle: {
        wordBreak: "break-all",
        padding: "2px 0px 2px 6px",
        minWidth: "90px",
      },
      headerStyle: {
        padding: "10px 0",
        minWidth: "90px",
      },
      render: (rowData) => formatFein(rowData.fein),
    },
    {
      title: "Payroll",
      field: "payroll",
      filtering: true,
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "120px",
      },
      headerStyle: {
        padding: " 0px 0px 0px 10px",
        minWidth: "120px",
      },

      render: (rowData) => <Clamp lines={1}>{rowData.payroll}</Clamp>,
    },
    {
      title: "Submitted By",
      field: "submittedUserName",
      filterPlaceholder: "Filter",

      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "00px",
        wordBreak: "break-word",
      },
      headerStyle: {
        padding: "6px 5px 6px 10px",
        minWidth: "00px",
      },
      render: (rowData) => {
        return (
          <Clamp lines={1}>
            {rowData.submittedUserName
              ? rowData.submittedUserName.split("@")[0].length <= 3
                ? rowData.submittedUserName.split("@")[0].toUpperCase()
                : rowData.submittedUserName.split("@")[0][0].toUpperCase() +
                  rowData.submittedUserName.split("@")[0].slice(1)
              : ""}
          </Clamp>
        );
      },
    },
    {
      title: "Comments",
      field: "uw_comments",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "140px",
      },
      render: (rowData) => {
        const callbackFunction = (data) => {
          updateData(data);
          updateDataInUser(data);
        };
        return (
          <TextField
            key={`${rowData.user_email_id}_comment`}
            value={
              typeof rowData?.uw_comments === "object"
                ? rowData?.uw_comments?.[0]?.comment
                : rowData?.uw_comments?.[0] || rowData?.uw_comments || ""
            }
            callback={callbackFunction}
            rowData={rowData}
            uw_comments="uw_comments"
          />
        );
      },
    },
    {
      title: "Agent Comments",
      field: "agent_comments",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "140px",
      },
      render: (rowData) => {
        const callbackFunction = (data) => {
          updateData(data);
          updateDataInUser(data);
        };
        return (
          <TextField
            key={`${rowData.user_email_id}_comment`}
            value={
              typeof rowData?.agent_comments === "object"
                ? rowData?.agent_comments?.[0]?.comment
                : rowData?.agent_comments?.[0] || rowData?.agent_comments || ""
            }
            callback={callbackFunction}
            rowData={rowData}
            agent_comments="agent_comments"
          />
        );
      },
    },
    {
      title: "Download",
      disableSortBy: true,
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "55px",
        textAlign: "center",
      },
      headerStyle: {
        textAlign: "right",
        padding: "6px 2px 4px 14px",
      },
      render: (rowData) => {
        let title = "";

        if (rowData?.fileListToStore?.length) {
          for (let file of rowData?.fileListToStore) {
            let fileToDisplay = file.split("/");
            title =
              title + fileToDisplay[fileToDisplay.length - 1] + " &#013; ";
          }
        }

        return (
          <button
            className="btn pt-0"
            type="button"
            title={parse(title)}
            data-html="true"
            onClick={() => {
              setModalData({
                showModel: true,
                modalTitle: <h1>{"Download"}</h1>,
                modalContent: (
                  <>
                    <DownloadDashboardFiles
                      fileListToStore={rowData.fileListToStore}
                    />
                  </>
                ),
              });
            }}
          >
            <img
              src={downloadImage}
              alt=""
              style={
                rowData?.fileListToStore?.length
                  ? {
                      width: "30px",
                      filter: "grayscale(0)",
                    }
                  : {
                      width: "30px",
                      filter: "grayscale(100%)  opacity(0.4)",
                    }
              }
            />
          </button>
        );
      },
    },
    {
      title: "Status",
      field: "uw_status",
      filterPlaceholder: "Filter",
      sorting: "false",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "80px",
      },
      render: (rowData) => {
        return (
          <>
            <select
              id="statusDrop"
              className={styles.statusDrop}
              value={domainButtonMap[rowData?.user_email_id]?.uw_status}
              onChange={(event) => {
                let statusMapping = {
                  Bound: "Bound",
                  Quoted: "Quoted",
                  Closed: "Closed",
                  Declined: "Declined",
                  "UW Quest": "UW Quest",
                  Submitted: "Submitted",
                };
                if (event.target.value === statusMapping[event.target.value]) {
                  updateButtonStatus(
                    rowData,
                    statusMapping[event.target.value]
                  );
                }
              }}
            >
              <option
                value={domainButtonMap[rowData?.user_email_id]?.uw_status}
              >
                {domainButtonMap[rowData?.user_email_id]?.uw_status}
              </option>
              <option value="Bound"> Bound</option>
              <option value="Quoted"> Quoted</option>
              <option value="Closed"> Closed </option>
              <option value="Declined"> Declined</option>
              <option value="UW Quest"> UW Quest</option>
              <option value="Submitted"> Submitted </option>
            </select>
          </>
        );
      },
    },
    {
      title: "Release",
      field: "Release",
      sorting: "false",
      filterPlaceholder: "Filter",
      cellStyle: {
        padding: "2px 0px 2px 6px",
        minWidth: "70px",
        width: "70px",
      },
      headerStyle: {
        width: "70px",
        minWidth: "70px",
      },
      render: (rowData) => {
        return (
          <button
            className="btn btn-sm btn-warning"
            disabled={rowData?.dealConfirmed}
            onClick={(e) => {
              if (!rowData?.dealConfirmed)
                updatePopUpData({
                  show: true,
                  title: "",
                  children: (
                    <>
                      <br></br>
                      <span style={{ fontWeight: "bold" }}>
                        This action will release the prospect back to Agent. Do
                        you want to Proceed?
                      </span>
                    </>
                  ),
                  acceptBtn: "Yes",
                  acceptBtnCallback: async () => {
                    try {
                      onReleaseClick(rowData);
                    } catch (error) {
                      console.log("error: ", error);
                    }
                  },
                });
            }}
          >
            Release
          </button>
        );
      },
    },
  ];

  const customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  const setTableData = () => {
    // getting the data from backend
    const defaultMaterialTheme = createTheme();

    // const scrollLeftHandler = () => {
    //   const tableContainerDiv =
    //     myRef.current.lastChild.lastChild.children[2].children[1].children[0]
    //       .children[0];
    //   if (tableContainerDiv) {
    //     tableContainerDiv.scrollLeft -= 150;
    //     if (tableContainerDiv.scrollLeft == 0) {
    //       setScrollLeft(false);
    //       setScrollRight(true);
    //     }

    //     const maxScrollLeft =
    //       tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    //     if (tableContainerDiv.scrollLeft < maxScrollLeft) {
    //       setScrollRight(true);
    //     }
    //   }
    // };
    // const scrollRightHandler = () => {
    //   const tableContainerDiv =
    //     myRef.current.lastChild.lastChild.children[2].children[1].children[0]
    //       .children[0];

    //   if (tableContainerDiv) {
    //     tableContainerDiv.scrollLeft += 150;
    //     setScrollLeft(true);
    //   }

    //   const maxScrollLeft =
    //     tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    //   if (tableContainerDiv.scrollLeft >= maxScrollLeft) {
    //     setScrollRight(false);
    //   }
    // };

    return (
      <div className={styles.materialTable_container}>
        <ThemeProvider theme={defaultMaterialTheme}>
          {/* {scrollLeft && (
            <button className="ChevronLeft" onClick={scrollLeftHandler}>
              &lt;
            </button>
          )}
          {scrollRight && (
            <button className="ChevronRight" onClick={scrollRightHandler}>
              &gt;
            </button>
          )} */}
          <MaterialTable
            icons={tableIcons}
            data={rowDataList}
            columns={columnData}
            options={{
              stickyHeader: true,
              filtering: true,
              sorting: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [10, 25, 50, 75, 100],
              headerStyle: {
                fontSize: ismobile ? fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile ? fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
            }}
            isLoading={isLoading}
          />
        </ThemeProvider>
      </div>
    );
  };

  const updateButtonStatus = (rowData, status) => {
    let dataToSave = {};
    dataToSave.user_email_id = rowData.user_email_id;
    dataToSave.sortKeyList = rowData.uuidCarriers;
    dataToSave.uw_status = status;
    let _domainButtonMap = Object.assign({}, domainButtonMap);

    _domainButtonMap[rowData?.user_email_id].uw_status = status;
    updateData(dataToSave);
    updateDataInUser(dataToSave);
    setDomainButtonMap(_domainButtonMap);
  };

  const getAllData = async () => {
    let rowDataList = [];
    let last_key;
    let indx = 0;
    let req_body = {
      first_time_loading: "true",
    };
    do {
      if (last_key) req_body["lastEvaluatedKey"] = last_key;

      if (indx === 1) delete req_body["first_time_loading"];

      await new Promise((resolve, reject) => {
        axios
          .post(awsUrl + "/api/underWriterData/getAll", req_body)
          .then((res) => {
            let dataFromAPI = res.data.list;
            let domainButtonMap = {};
            console.log(dataFromAPI);
            try {
              for (let domain in dataFromAPI) {
                let createPreparedData = true;
                let preparedData = {};

                for (let domainWiseQuoteData of dataFromAPI[domain]) {
                  let payroll;
                  let classcodeInfo;
                  let sum = 0;

                  let childloc =
                    domainWiseQuoteData["currProspectDetails"]["childrenLoc"];

                  for (let domainWiseQuoteData in childloc) {
                    let userChildlocData = childloc[domainWiseQuoteData];
                    classcodeInfo = userChildlocData.classCodesInfo;
                    classcodeInfo = Object.entries(classcodeInfo);
                    for (let i = 0; i < classcodeInfo.length; i++) {
                      let paydata = classcodeInfo[i];
                      const stringAmount = paydata[1]?.payroll?.value;
                      if (!stringAmount) continue;
                      const numberAmount = parseFloat(
                        stringAmount.replace(/[^\d.-]/g, "")
                      );
                      sum = sum + numberAmount;
                    }
                    payroll = sum;
                  }

                  if (createPreparedData) {
                    try {
                      preparedData = {
                        user_email_id: domainWiseQuoteData?.user_email_id,

                        submittedDate:
                          (domainWiseQuoteData?.submissionDate
                            ? moment(
                                Number(domainWiseQuoteData?.submissionDate),
                                "x"
                              ).format("M/D/YY")
                            : "-") || "",

                        submittedUserName:
                          domainWiseQuoteData?.producerEmail || "",

                        companyname:
                          domainWiseQuoteData?.currProspectDetails
                            ?.companyProfile?.companyName?.value || "",

                        fein:
                          domainWiseQuoteData?.currProspectDetails
                            ?.companyProfile?.fein?.value || "",

                        uw_comments: domainWiseQuoteData?.uw_comments
                          ? typeof domainWiseQuoteData?.uw_comments === "object"
                            ? domainWiseQuoteData?.uw_comments
                            : [domainWiseQuoteData?.uw_comments]
                          : [""],

                        agent_comments: domainWiseQuoteData?.agent_comments
                          ? typeof domainWiseQuoteData?.agent_comments ===
                            "object"
                            ? domainWiseQuoteData?.agent_comments
                            : [domainWiseQuoteData?.agent_comments]
                          : [""],

                        currProspect: domainWiseQuoteData?.currProspectDetails,

                        uuidCarriers: domainWiseQuoteData?.uuid_carrier
                          ? [domainWiseQuoteData.uuid_carrier]
                          : [],

                        address: domainWiseQuoteData?.uuid_carrier
                          ? [
                              domainWiseQuoteData?.uuid_carrier
                                ?.split("+")
                                .pop(),
                            ]
                          : [],

                        uw_status: domainWiseQuoteData?.uw_status,

                        fileList: domainWiseQuoteData?.fileList,

                        uwFileList: domainWiseQuoteData?.uwFileList,

                        dealConfirmed: domainWiseQuoteData?.dealConfirmed,

                        payroll: payroll?.toLocaleString("en-US", {
                          style: "currency",
                          currency: "USD",
                          minimumFractionDigits: 0,
                        }),
                      };

                      if (
                        domainWiseQuoteData?.date &&
                        domainWiseQuoteData?.uuid
                      ) {
                        preparedData.quoteData = {
                          uuid: domainWiseQuoteData.uuid,
                          date: domainWiseQuoteData.date,
                        };
                      }

                      let _fileListToStore = [];
                      if (domainWiseQuoteData?.fileList?.length) {
                        domainWiseQuoteData.fileList.forEach((ele) => {
                          _fileListToStore.push(ele.key);
                        });
                      }
                      if (domainWiseQuoteData?.uwFileList?.length) {
                        domainWiseQuoteData.uwFileList.forEach((ele) => {
                          _fileListToStore.push(ele.key);
                        });
                      }
                      preparedData["fileListToStore"] = _fileListToStore;

                      createPreparedData = false;
                    } catch (error) {}
                  } else {
                    if (domainWiseQuoteData?.uuid_carrier) {
                      preparedData.uuidCarriers.push(
                        domainWiseQuoteData.uuid_carrier
                      );
                      preparedData.address.push(
                        domainWiseQuoteData?.uuid_carrier?.split("+").pop()
                      );
                    }
                  }
                  if (!(domain in domainButtonMap))
                    domainButtonMap[domain] = {
                      uw_status: domainWiseQuoteData?.uw_status || "Select",
                    };
                }
                rowDataList.push(preparedData);
              }
            } catch (error) {}

            setRowDataList(rowDataList);
            setDomainButtonMap(domainButtonMap);
            console.log(rowDataList, "rowDatalist");
            setIsLoading(false);
            resolve();
          })
          .catch((err) => {
            setRowDataList([]);
            setIsLoading(false);
            reject();
          });
      });

      indx += 1;
    } while (indx === 1 || last_key);
  };

  let { showModel, modalTitle, modalContent } = modalData;

  return (
    <>
      <div className={styles.submission_uw_dashboard} style={style}>
        <div ref={myRef}>{setTableData()}</div>
        <Modal
          show={showModel}
          onHide={() => {
            setModalData({
              showModel: false,
              modalTitle: null,
              modalContent: null,
            });
          }}
          dialogClassName="model-download modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title>{modalTitle}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalContent}</Modal.Body>
        </Modal>
      </div>
      <Toaster />
      {popupData.show && (
        <Popup popupData={popupData} updatePopUpData={updatePopUpData} />
      )}
    </>
  );
};

export default UnderWriterTab;
