import React from "react";
import EmodQuestions from "../sections-quote/EmodQuestions";
import styles from "./Emod.module.scss";

export default function Emod({ emodStatesData, setEmodStatesData }) {
  const updateEmodInfo = (state, fieldName, value) => {
    try {
      console.log(state, fieldName, value, emodStatesData);
      let _emodStatesData = JSON.parse(JSON.stringify(emodStatesData));

      console.log(_emodStatesData);

      let dataFieldNames = ["rate1", "rate2", "date1", "date2"];
      if (dataFieldNames.includes(fieldName)) {
        _emodStatesData[state][fieldName] = {
          value: value,
        };
      } else {
        _emodStatesData[state][fieldName] = value;
      }

      setEmodStatesData(_emodStatesData);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={styles.emod_ques}>
      {Object.values(emodStatesData).length > 0 && (
        <h4 className={styles.emod_ques_heading}>
          <b>Experience Modification</b>
        </h4>
      )}
      {Object.keys(emodStatesData).map((e) => {
        return (
          <EmodQuestions
            key={e}
            state={e}
            emodData={emodStatesData[e]}
            updateEmodInfo={updateEmodInfo}
          />
        );
      })}
    </div>
  );
}
