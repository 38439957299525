import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import styles from "./GenericDropZone.module.scss";

class GenericDropZoneHelper extends React.Component {
  render() {
    let {
      fileList,
      removeFile,
      updateFileList,
      name,
      heading,
      uploadMsg,
      extraTest,
    } = this.props;
    return (
      <div className={styles.customDropZone}>
        <div className="d-flex">
          <h5 className="font-family-montserrat-semi-bold">{heading}</h5>
          <h5 className={styles.extraTest}>{extraTest ? extraTest : ""}</h5>
        </div>
        <DropZone
          listLen={Object.keys(fileList[name]).length}
          updateFileList={updateFileList}
          name={name}
        />
        <ul className="list-group list-group-flush">
          {Object.keys(fileList[name]).map((val) => (
            <li
              className="list-group-item d-flex justify-content-between"
              key={val}
            >
              <span>{val}</span>
              {fileList[name][val] !== "#empty" &&
                uploadMsg !== "files uploaded" && (
                  <span
                    className="fw-bold"
                    onClick={() => removeFile(name, val)}
                  >
                    {"X"}
                  </span>
                )}
            </li>
          ))}
        </ul>
      </div>
    );
  }
}

const DropZone = (props) => {
  let { updateFileList, listLen, name } = props;
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      updateFileList(name, file);
    });
  }, [listLen]);
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps({ className: `dropzone` })}>
      <input {...getInputProps()} />
      <span>{"📂"}</span>
      <p className={styles.dropZoneText}>
        Drag &amp; drop or browse to select files
      </p>
    </div>
  );
};

export default GenericDropZoneHelper;
