import React, { Component } from "react";
import { NumericFormat, PatternFormat } from "react-number-format";
import { Form } from "react-bootstrap";
import Newcode from "./Newcode";
import StateList from "../../common/StateList";
import { deleteAddress } from "../../../utils/session_data";
import moment from "moment";
import { getMinMaxPremium } from "../../../api/formCmprRatesApi";
let monoStates = require("../../../utils/mono_states.json");
import styles from "./CompLoc.module.scss";

class CompLoc extends Component {
  constructor(props) {
    super(props);
    this.state = {
      locationInfo: {
        state: {},
        zipCode: {},
        cityName: {},
        street: {},
        suta: {},
      },
    };
  }

  componentDidMount() {
    this.setState({ locationInfo: this.props.locationInfo });
  }

  componentDidUpdate() {
    if (this.props.locationInfo !== this.state.locationInfo) {
      this.setState({ locationInfo: this.props.locationInfo });
    }
  }

  addClassCode = (number, classCodesInfo, updateLocationInfo) => {
    let codeCount = Object.keys(classCodesInfo).reduce((prev, val) => {
      return Math.max(Number(val), Number(prev));
    });
    updateLocationInfo(null, number, null, Number(codeCount) + 1);
  };

  getMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const { effectiveDate, updateLocationError } = this.props;
    const { ft, pt, payroll, classCodeDescription } =
      locationInfo.classCodesInfo[addressChildNo];
    const state = locationInfo.state.value.toLowerCase();
    const zip = locationInfo.zipCode.value;

    let classCode = classCodeDescription.value.split(":")[0];

    let _payroll_value;

    try {
      if (payroll?.value?.includes("$") && payroll?.value?.split("$")?.[1]) {
        _payroll_value = payroll.value.split("$")[1].split(",").join("");
      } else {
        _payroll_value = payroll.value.split(",").join("");
      }
    } catch (error) {}

    let data = {
      code: classCode,
      pt: pt.value.trim(),
      ft: ft.value.trim(),
      payroll: _payroll_value,
    };

    let bodyData = {
      employees: [data],
      state: state,
      date_from: effectiveDate?.value
        ? moment(effectiveDate.value)?.format("YYYY-MM-DD")
        : "",
      location: zip,
    };

    if (!monoStates.includes(state)) {
      // please do check this
      getMinMaxPremium(bodyData, {})
        .then((response) => {
          if (response.data === "Not eligible") {
            updateLocationError(
              addressBlockNo,
              addressChildNo,
              "classCodeDescription",
              true
            );
          } else {
            updateLocationError(
              addressBlockNo,
              addressChildNo,
              "classCodeDescription",
              false
            );
          }
        })
        .catch((error) => {
          console.log(error);
          updateLocationError(
            addressBlockNo,
            addressChildNo,
            "classCodeDescription",
            true
          );
        });
    }
  };

  handleMinMax = (addressBlockNo, addressChildNo) => {
    const { locationInfo } = this.state;
    const keyList = ["ft", "pt", "payroll", "classCodeDescription"];
    let allClassCodeVals;
    if (locationInfo && locationInfo.classCodesInfo[addressChildNo]) {
      allClassCodeVals = keyList.every((e) => {
        return (
          e in locationInfo.classCodesInfo[addressChildNo] &&
          locationInfo.classCodesInfo[addressChildNo][e].value &&
          locationInfo.classCodesInfo[addressChildNo][e].value.trim() !== ""
        );
      });
    }
    if (allClassCodeVals && locationInfo.state.value) {
      this.getMinMax(addressBlockNo, addressChildNo);
    }
  };
  conditional_Render_street = (value) => {
    let style = {};

    if (value == "ST" && window.screen.width > 992) {
      style = { width: "52%", paddingInline: "15px" };
      return style;
    }
    if (value == "ZI" && window.screen.width > 993) {
      style = { width: "11%", paddingInline: "15px" };
      return style;
    }
    if (value == "CT" && window.screen.width > 993) {
      style = { width: "14%", paddingInline: "15px" };
      return style;
    }
    if (value == "State" && window.screen.width > 993) {
      style = { width: "11%", paddingInline: "15px" };
      return style;
    }
    if (
      value == "ST" ||
      value == "CT" ||
      value == "ZI" ||
      (value == "State" && window.screen.width < 993)
    ) {
      style = { width: "100%", paddingInline: "15px" };
      return style;
    }
  };

  render() {
    const {
      setStateAndCity,
      updateLocationInfo,
      removeLocation,
      removeClassCode,
      updateClassCodesInfo,
      resetClassCodeDescriptionData,
      index,
      updateLocationError,
      locationCount,
      deleteLocation,
      childrenLoc,
    } = this.props;
    const { locationInfo } = this.state;
    const number = locationInfo ? locationInfo.number : null;
    return (
      <div id={"officeLoc" + number} className="mb-2">
        <div className={`row grey ${styles.loc1street_margin}`}>
          <div
            className={`d-flex justify-content-center align-items-center ${styles.locHeading}`}
          >
            <p>Loc{index} </p>
          </div>

          <div
            style={this.conditional_Render_street("ST")}
            className="col-lg-6 col-md-12"
            id="street"
          >
            <Form.Group className={styles.form_group}>
              <label htmlFor={"street-" + number} title="Street Address">
                <b className="font-family-montserrat-semi-bold">
                  Street Address
                </b>
              </label>
              <input
                id={"street-" + number}
                name="street"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "street")
                }
                value={
                  locationInfo?.street?.value ? locationInfo.street.value : ""
                }
                style={
                  locationInfo?.street?.error ? { borderColor: "red" } : {}
                }
                placeholder="Street Address"
                autoComplete="off"
              />
              <p className={`${styles.errMsg} ${styles.addressErrs}`} />
            </Form.Group>
          </div>
          <div
            style={this.conditional_Render_street("ZI")}
            className="col-lg-6 col-md-12"
            id="zip"
          >
            <Form.Group className={styles.form_group}>
              <label
                htmlFor={"zipCode-" + number}
                className={styles.mandatory}
                title="Zip Code"
              >
                <b className="font-family-montserrat-semi-bold">Zip</b>
              </label>
              <PatternFormat
                name="zip"
                id={"zipCode" + number}
                format="#####"
                mask="_"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "zipCode")
                }
                value={
                  locationInfo?.zipCode?.value ? locationInfo.zipCode.value : ""
                }
                style={
                  locationInfo?.zipCode?.error ? { borderColor: "red" } : {}
                }
                onBlur={(event) => setStateAndCity(event.target.value, number)}
                placeholder="Zip"
                autoComplete="off"
              />
              <p className={`${styles.errMsg} ${styles.addressErrs}`} />
            </Form.Group>
          </div>
          <div
            style={this.conditional_Render_street("CT")}
            className="col-lg-6 col-md-12"
            id="city"
          >
            <Form.Group className={styles.form_group}>
              <label
                htmlFor={"cityName-" + number}
                className={styles.mandatory}
                title="City"
              >
                <b className="font-family-montserrat-semi-bold">City</b>
              </label>
              <input
                id={"cityName-" + number}
                name="cityName"
                type="text"
                onChange={(event) =>
                  updateLocationInfo(event.target.value, number, "cityName")
                }
                value={
                  locationInfo?.cityName?.value
                    ? locationInfo.cityName.value
                    : ""
                }
                style={
                  locationInfo?.cityName?.error ? { borderColor: "red" } : {}
                }
                placeholder="City Name"
                autoComplete="off"
              />
              <p className={`${styles.errMsg} ${styles.addressErrs}`} />
            </Form.Group>
          </div>
          <div
            style={this.conditional_Render_street("State")}
            className="col-lg-6 col-md-12"
            id="state"
          >
            <Form.Group className={styles.form_group}>
              <label className={styles.mandatory} title="State">
                <b className="font-family-montserrat-semi-bold">State</b>
              </label>
              <StateList
                state={
                  locationInfo?.state?.value ? locationInfo.state.value : null
                }
                addressBlockNo={number}
                locationInfo={locationInfo}
                updateLocationInfo={updateLocationInfo}
                resetClassCodeDescriptionData={resetClassCodeDescriptionData}
              />
              <p className={`${styles.errMsg} ${styles.addressErrs}`} />
            </Form.Group>
          </div>
          {locationCount > 1 && (
            <div className={styles.address_delete}>
              <button
                type="button"
                onClick={(event) => {
                  deleteLocation(number);
                }}
                className={styles.transpButton}
              >
                <img
                  className={styles.address_delete_btn}
                  onClick={(event) => deleteAddress(number)}
                  src={require("../../../images/delete4.png")}
                  alt=""
                />
              </button>
            </div>
          )}
        </div>
        <div className="">
          {false &&
            locationInfo &&
            locationInfo.state.value &&
            locationInfo.classCodesInfo && (
              <div className="row mb-3">
                <h6 className="col-sm-12">
                  <b>Class Code Information</b>
                </h6>
              </div>
            )}
          {locationInfo &&
            locationInfo.classCodesInfo &&
            Object.keys(locationInfo.classCodesInfo).map((e) => {
              return (
                <Newcode
                  key={e}
                  state={locationInfo.state.value}
                  addressChildNo={e}
                  addressBlockNo={number}
                  removeClassCode={removeClassCode}
                  classCodesInfo={locationInfo.classCodesInfo}
                  updateLocationError={updateLocationError}
                  updateClassCodesInfo={updateClassCodesInfo}
                  handleMinMax={this.handleMinMax}
                  childrenLoc={childrenLoc}
                />
              );
            })}
          {locationInfo && locationInfo.classCodesInfo && (
            <div className={`row ${styles.add_classcode_margin}`}>
              <div className={styles.add_classcode_leftmargin}></div>
              <div className={`calculator ${styles.add_classcode_container}`}>
                <div>
                  <button
                    type="button"
                    className={`${styles.addLoc} ${styles.transpButton} p-0`}
                    onClick={() =>
                      this.addClassCode(
                        number,
                        locationInfo.classCodesInfo,
                        updateLocationInfo
                      )
                    }
                  >
                    <img src={require("../../../images/plus4.png")} />{" "}
                    <b>Add class code</b>
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

export default CompLoc;
