import React, { Component } from "react";
import Allques from "../../subcompo/sections-quote/Allques";
import { setUWQues } from "../../../utils/session_data";
import { quesList } from "../../../utils/ques_list";
import { submitQues, updateQuesList } from "../../../utils/form_ques";
import NewProgress from "../../../newcomponent/common/NewProgress";
import { LibertateContext } from "../../../context/Context";
import styles from "../sections-quote/FormUnderQues.module.scss";
import { isDeepEquals } from "../../../utils/isDeepEquals";

class FormUnderQues extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: quesList,
    };
    this.setUWQues = setUWQues.bind(this);
    this.updateQuesList = updateQuesList.bind(this);
    this.submitQues = submitQues.bind(this);
  }

  // createQuestionsData = () => {
  //   let { quesList } = JSON.parse(JSON.stringify(this.state));
  //   for (let question of [...quesList]) {
  //     if (question.response === true) {
  //       question.response = "Y";
  //     } else if (question.response === false) {
  //       question.response = "N";
  //     }
  //   }

  //   return { quesList };
  // };

  componentDidMount = () => {
    this.props.setContextData("getQuoteClicked", false);
  };

  componentDidUpdate = (prevProps) => {
    let { currProspect } = this.props;
    let _prev_prop_uw_len = prevProps?.currProspect?.uwQues?.length;
    let _curr_prop_uw_len = this.props?.currProspect?.uwQues?.length;

    if (_curr_prop_uw_len && _prev_prop_uw_len !== _curr_prop_uw_len) {
      let _quesList = [];

      // for (let ind in currProspect.uwQues) {

      //   _quesList.push({
      //     ...currProspect.uwQues[ind],
      //     response: currProspect.uwQues[ind].response === "Y" ? true : false,
      //   });
      // }

      this.setState({ quesList: this.props?.currProspect?.uwQues });
    }
  };

  // setQuestions = (quesList) => {
  //   let response = {};
  //   for (let question of quesList) {
  //     response[question.name] = {
  //       ques: question.ques,
  //       response: question.response,
  //       remarks: question.remarks,
  //     };
  //   }

  //   return response;
  // };

  render() {
    const { quesList, incompleteQuestions } = this.state;
    const {
      updatePopUpData,
      sessionData,
      currProspect,
      setFormStage,
      hideButtons,
      startLoader,
      stopLoader,
      setCurrProspect,
      acordUwQues,
      isUwFlow,
      submissionDate,
      currentUser,
      visitTimestamp,
      uuid,
      setContextData,
    } = this.props;

    let compName = "";
    try {
      if (currProspect?.companyProfile?.companyName)
        compName = currProspect.companyProfile.companyName.value;
    } catch (err) {
      console.log(err);
    }
    return (
      <div id="undrQues" className={`container-fluid per98 ${styles.undrQues}`}>
        <NewProgress />
        <div id="underWritingQues">
          <form>
            <div className={`row no-gutters ${styles.heading_row}`}>
              <span
                className={`${styles.formTitle} ${styles.title2} ${styles.finallyquestionwidth}`}
              >
                And finally, answers to some basic questions about your business
              </span>
              <p className={styles.compName}>{compName}</p>
            </div>
            <div className="table-responsive table-undrques-container">
              <Allques
                quesList={quesList}
                setUWQues={this.setUWQues}
                updateQuesList={this.updateQuesList}
              />
            </div>
            {incompleteQuestions && (
              <div className={`${styles.errMsg} text-center mb-4`}>
                Please answer all the questions!
              </div>
            )}
          </form>
          <div className="row">
            <div className="col-12 text-center">
              <button
                type="button"
                className={`btn btn-warning btn-lg btn-warning-shadow ${styles.btnSubmits}`}
                onClick={() => {
                  if (!hideButtons)
                    this.submitQues(
                      setFormStage,
                      quesList,
                      updatePopUpData,
                      sessionData.attributes,
                      startLoader,
                      stopLoader,
                      setCurrProspect,
                      acordUwQues,
                      currProspect,
                      isUwFlow,
                      submissionDate,
                      currentUser,
                      visitTimestamp,
                      uuid,
                      setContextData
                    );
                }}
                disabled={hideButtons}
              >
                Get Quote
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default function FormUnderQuesWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => <FormUnderQues {...props} {...context} />}
    </LibertateContext.Consumer>
  );
}
