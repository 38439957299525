import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import "react-datepicker/dist/react-datepicker.css";
class Datepick extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selDate: moment(this.props.date),
    };
  }

  componentDidUpdate() {
    if (this.state.selDate !== this.props.date) {
      this.setState({ selDate: this.props.date });
    }
  }

  handleDateChange(date) {
    const { updateEmodInfo, dateNo, state } = this.props;
    try {
      updateEmodInfo(state, dateNo, date);
    } catch (e) {}
  }

  render() {
    return (
      <DatePicker
        popperPlacement="auto"
        selected={
          this.state.selDate ? moment(this.state.selDate).toDate() : null
        }
        onChange={(date) => this.handleDateChange(date)}
      />
    );
  }
}
export default Datepick;
