import React, { useState } from "react";
import styles from "./MinimizeComonent.module.scss";

export default function MinimizeComponent(props) {
  const [display, setDisplay] = useState(true);

  return (
    <>
      <div className={styles.pink_header}>
        <div className={styles.font_family_montserrat_bold}>{props.title}</div>
        {display ? (
          <span
            className={` ${styles.pink_sign} ${styles.noselect}`}
            onClick={() => {
              if (display) setDisplay(false);
              else return;
            }}
          >
            &#8722;
          </span>
        ) : (
          <span
            className={` ${styles.pink_sign} ${styles.noselect}`}
            onClick={() => {
              if (!display) setDisplay(true);
              else return;
            }}
          >
            &#43;
          </span>
        )}
      </div>
      {display ? props.children : <></>}
    </>
  );
}
