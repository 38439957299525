import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import styles from "./DropDown.module.scss"

export default function DropDown({ options, title, updateCallback, value, id }) {
  const [selectedOption, setSelectedOption] = useState();

  useEffect(() => {
    if (selectedOption?.value)
      updateCallback(selectedOption.value);
  }, [selectedOption])

  return (
    <div className="dropdown-container">
      <b className="font-family-montserrat-semi-bold">
        <label htmlFor={id}>{title}</label>
      </b>
      <div className={styles.dropdown_element_container}>
        <Select
          id={id}
          defaultValue={selectedOption}
          onChange={setSelectedOption}
          options={options}
          value={options.filter(option =>
            option.value === value)}
        />
      </div>
    </div>
  );
};