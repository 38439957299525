import React, { Component, forwardRef } from "react";
import Clamp from "react-multiline-clamp";
import moment from "moment";
import MaterialTable from "material-table";
import { TablePagination } from "@mui/material";
import ArrowDownward from "@mui/icons-material/ArrowDownward";
import Search from "@mui/icons-material/Search";
import Clear from "@mui/icons-material/Clear";
import FirstPage from "@mui/icons-material/FirstPage";
import LastPage from "@mui/icons-material/LastPage";
import ChevronLeft from "@mui/icons-material/ChevronLeft";
import ChevronRight from "@mui/icons-material/ChevronRight";
import Check from "@mui/icons-material/Check";
import Loader from "../common/Loader";
import { Nav } from "react-bootstrap";
import Header from "../common/Header";
import { Auth } from "aws-amplify";
import SideTab from "../../newcomponent/common/SideTab";
import { ThemeProvider, createTheme } from "@mui/material";
import { sumPayroll } from "../../utils/form_compPro";
import { isDeepEquals } from "../../utils/isDeepEquals";
import { userTableData, underWriterData } from "../../api/commonApi";

let ismobile_font = window.innerWidth < 1600;
const tableIcons = {
  SortArrow: forwardRef((props, ref) => <ArrowDownward {...props} ref={ref} />),
  Search: forwardRef((props, ref) => <Search {...props} ref={ref} />),
  ResetSearch: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  FirstPage: forwardRef((props, ref) => <FirstPage {...props} ref={ref} />),
  LastPage: forwardRef((props, ref) => <LastPage {...props} ref={ref} />),
  NextPage: forwardRef((props, ref) => <ChevronRight {...props} ref={ref} />),
  PreviousPage: forwardRef((props, ref) => (
    <ChevronLeft {...props} ref={ref} />
  )),
  Check: forwardRef((props, ref) => <Check {...props} ref={ref} />),
  Clear: forwardRef((props, ref) => <Clear {...props} ref={ref} />),
  // Filter: forwardRef((props,ref) => <Filter {...props} ref={ref} />)
  Filter: () => <></>,
};

let isMobile_sidenav = window.innerWidth < 900;
class DashboardLibertate extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selected: "tab1",
    };
  }

  setSelected = (tab) => {
    let selected = this.state.state;
    if (selected !== tab) {
      this.setState({ selected: tab });
    }
  };

  render() {
    let { selected } = this.state;
    const tabsMap = {
      tab1: "User Dashboard",
    };

    return (
      <div className="dashboard-container">
        <div
          className="d-flex bg-light"
          style={{ borderBottomStyle: "groove" }}
        >
          <Header sessionData={this.props.sessionData} />
        </div>

        <div className="position-relative">
          {isMobile_sidenav ? <SideTab /> : " "}

          <div className="tabs  lib_dashboard">
            <div className="d-flex justify-content-center mb-3 text-center">
              <h2 className="mt-3 mb-3">
                <b>Dashboard</b>
              </h2>
            </div>
            <Nav
              className="userdashboard__button"
              variant="pills"
              defaultActiveKey={selected}
              style={{ fontSize: "16px" }}
            >
              {Object.keys(tabsMap).map((tab) => (
                <Nav.Item>
                  <Nav.Link
                    eventKey={tab}
                    onClick={() => this.setSelected(tab)}
                  >
                    {tabsMap[tab]}
                  </Nav.Link>
                </Nav.Item>
              ))}
            </Nav>
            {Object.keys(tabsMap).map((tab) => (
              <div key={tab} className="Admin__Dashboard">
                {
                  <LibertateDashboard
                    tabName={tab}
                    selected={selected}
                    sessionData={this.props.sessionData}
                  />
                }
              </div>
            ))}
          </div>
        </div>
        <Loader />
      </div>
    );
  }
}

class TextField extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: props.value,
    };
  }
  onChangeHandler(target) {
    console.log("target", target);
    this.setState({ value: target });
  }

  render() {
    let { value } = this.state;
    return (
      <textarea
        value={value}
        className="underwriter_comment"
        rows="1"
        style={{ width: "100%" }}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onBlur={(e) => {
          let dataToSave = {};
          dataToSave.user_email_id = this.props.rowData.user_email_id;
          dataToSave.sortKeyList = this.props.rowData.uuidCarriers;
          dataToSave.agent_comments = e.target.value;
          this.props.callback(dataToSave);
        }}
      ></textarea>
    );
  }
}

class LibertateDashboard extends Component {
  constructor(props) {
    super(props);
    this.myRef = React.createRef();
    this.state = {
      isLoading: true,
      scrollLeft: false,
      scrollRight: true,
    };
  }

  componentDidMount = () => {
    sessionStorage.clear();
    this.updateDisplay(this.props.sessionData);
  };

  camelCase = (str) => {
    if (!str) return "";
    return str
      .trim()
      .split(" ")
      .map((word) => {
        return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
      })
      .join(" ");
  };

  onClickCompanyName = (rowData) => {
    sessionStorage.clear();
    console.log(rowData);
    if (rowData.amtrustAccountDetails) {
      sessionStorage.setItem(
        "amtrustAccountDetails",
        JSON.stringify(rowData.amtrustAccountDetails)
      );
    }

    let currProspect = {
      childrenLoc: rowData.childrenLoc,
      companyProfile: rowData.companyProfile,
      emodStatesData: rowData.emodStatesData,
      riskProfile: rowData.riskProfile,
      peoDetails: rowData.peoDetails,
      carrierList: rowData.carrierList,
    };

    if (rowData.uw_ques) {
      currProspect["uwQues"] = rowData.uw_ques;
    }

    if (rowData.status === "quote_generated") {
      sessionStorage.setItem("quoteData", JSON.stringify(rowData.quoteData));
      sessionStorage.setItem("address", JSON.stringify(rowData.address));
      sessionStorage.setItem("formStage", "three");
      if (rowData.quoteSubmitted) {
        sessionStorage.setItem("hideButtons", "true");
      }
    } else if (rowData.status === "company_profile") {
      sessionStorage.setItem("formStage", "two");
    }

    sessionStorage.setItem("currProspect", JSON.stringify(currProspect));
    sessionStorage.setItem("user_id", rowData.user_email_id);

    window.location.href = "/Quote";
  };

  updateData = (data) => {
    new Promise((resolve, reject) => {
      try {
        underWriterData(data, "update")
          .then((res) => {
            console.log("data saved");
            resolve();
          })
          .catch((err) => {
            console.log("error while saving", err);
            reject();
          });
      } catch (error) {}
    });
  };

  updateDataInUser = (data) => {
    let data_req = JSON.parse(JSON.stringify(data));
    if (data_req.sortKeyList) {
      delete data_req.sortKeyList;
    }

    userTableData(data_req, "update")
      .then((res) => {
        console.log("data saved");
        resolve();
      })
      .catch((err) => {
        console.log("error while saving", err);
        reject();
      });
  };

  dateSort = (a, b, field) => {
    let momentA = moment(a[field], "M/D/YY");
    let momentB = moment(b[field], "M/D/YY");

    if (
      (!momentA.isValid() && momentB.isValid()) ||
      momentA.isBefore(momentB)
    ) {
      return -1;
    } else if (
      (momentA.isValid() && !momentB.isValid()) ||
      momentA.isAfter(momentB)
    ) {
      return 1;
    } else {
      return 0;
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  scrollLeft = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));
    const tableContainerDiv =
      this.myRef.current.lastChild.lastChild.children[2].children[0]
        .children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft -= 150;
      if (tableContainerDiv.scrollLeft == 0) {
        this.setState({ scrollLeft: false });
        this.setState({ scrollRight: true });
      }

      const maxScrollLeft =
        tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

      if (tableContainerDiv.scrollLeft < maxScrollLeft) {
        this.setState({ scrollRight: true });
      }
    }
  };

  scrollRight = () => {
    let { scrollLeft, scrollRight } = JSON.parse(JSON.stringify(this.state));

    const tableContainerDiv =
      this.myRef.current.lastChild.lastChild.children[2].children[0]
        .children[0];

    if (tableContainerDiv) {
      tableContainerDiv.scrollLeft += 150;
      this.setState({ scrollLeft: true });
    }

    const maxScrollLeft =
      tableContainerDiv.scrollWidth - tableContainerDiv.clientWidth;

    if (tableContainerDiv.scrollLeft > maxScrollLeft) {
      this.setState({ scrollRight: false });
    }
  };

  fontfunciton = () => {
    if (window.innerWidth < 1600 && window.innerWidth > 1500) {
      return "0.84rem";
    }
    if (window.innerWidth < 1500 && window.innerWidth > 1410) {
      return "0.8rem";
    }
    if (window.innerWidth < 1410 && window.innerWidth > 1310) {
      return "0.76rem";
    }
    if (window.innerWidth < 1300) {
      return "0.72rem";
    }
  };

  customFilterAll = (term, rowData, field) => {
    let customFieldName = field["field"];

    return rowData[customFieldName]
      ?.toString()
      ?.toLowerCase()
      ?.startsWith(term?.toLowerCase());
  };

  formatFein = (value) => {
    if (!value) return "-";
    let fein = "-";
    value = value?.split("-")?.join("");
    var len = value?.length,
      dummystr = "000000000";

    if (len === 0) {
      fein = "-";
    } else if (len > 0 && len < 9) {
      value = dummystr.slice(0, 9 - len) + "" + value;
      fein = value[0] + value[1] + "-" + value?.slice(2);
    } else {
      fein = value[0] + value[1] + "-" + value?.slice(2);
    }

    return fein;
  };

  setTableData = () => {
    let { rowDataList, isLoading, scrollLeft, scrollRight } = JSON.parse(
      JSON.stringify(this.state)
    );
    let { tabName } = this.props;

    const defaultMaterialTheme = createTheme();

    let columns = {
      tab1: [
        {
          title: "Company Name",
          field: "companyName",
          filtering: true,
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "2px 0px 2px 12px",
            minWidth: "200px",
            maxWidth: "410px",
          },
          headerStyle: {
            padding: " 0px 0px 0px 10px",
            minWidth: "200px",
            maxWidth: "410px",
          },
          filterCellStyle: {
            padding: "5px 0px 5px 4px",
          },
          render: (rowData) => (
            <Clamp lines={2}>
              <span>
                <div
                  onClick={() => this.onClickCompanyName(rowData)}
                  className="company-name"
                >
                  {this.camelCase(rowData.companyName)}
                </div>
              </span>
            </Clamp>
          ),
        },
        {
          title: "State",
          field: "state",
          filtering: true,
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "70px",
          },
          headerStyle: {
            padding: " 0px 0px 0px 10px",
            minWidth: "70px",
          },

          render: (rowData) => <Clamp lines={1}>{rowData.state || "-"}</Clamp>,
        },
        {
          title: "FEIN",
          field: "fein",
          filterPlaceholder: "Filter",
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),

          cellStyle: {
            wordBreak: "break-all",
            padding: "2px 0px 2px 6px",
            minWidth: "100px",
          },
          headerStyle: {
            padding: "10px 0",
            minWidth: "100px",
          },
          render: (rowData) => (
            <Clamp lines={1}>{this.formatFein(rowData?.fein) || ""}</Clamp>
          ),
        },
        {
          title: "Payroll",
          field: "payroll",
          filtering: true,
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "120px",
          },
          headerStyle: {
            padding: " 0px 0px 0px 10px",
            minWidth: "120px",
          },

          render: (rowData) => <Clamp lines={1}>{rowData.payroll}</Clamp>,
        },
        {
          title: "Effective Date",
          field: "effectiveDate",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => rowData.effectiveDate,

          headerStyle: {
            padding: "0px 0px 0px 8px",
            minWidth: "70px",
          },
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "70px",
            width: "70px",
          },
          customFilterAndSearch: (term, rowData, field) =>
            this.customFilterAll(term, rowData, field),
        },
        {
          title: "Modified By",
          field: "modifiedBy",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => (
            <Clamp lines={1}>
              {rowData.modifiedBy
                ? rowData.modifiedBy.split("@")[0].length <= 3
                  ? rowData.modifiedBy.split("@")[0].toUpperCase()
                  : rowData.modifiedBy.split("@")[0][0].toUpperCase() +
                    rowData.modifiedBy.split("@")[0].slice(1)
                : ""}
            </Clamp>
          ),

          headerStyle: {
            padding: " 0px 15px 0px 10px",
            minWidth: "80px",
          },
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "80px",
            width: "80px",
          },
        },
        {
          title: "Status",
          field: "step",
          filtering: true,
          filterPlaceholder: "Filter",
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "125px",
          },
          headerStyle: {
            padding: " 0px 15px 0px 10px",
            minWidth: "110px",
          },
          render: (rowData) => (
            <Clamp lines={1}>
              {rowData.uwStatus ||
                (rowData.quoteSubmitted ? "Submitted" : "") ||
                rowData.step}
            </Clamp>
          ),
        },
        {
          title: "UW Comment",
          field: "uwComments",
          filtering: true,
          filterPlaceholder: "Filter",
          render: (rowData) => {
            return (
              <textarea
                value={rowData.uw_comments || ""}
                className="underwriter_comment"
                rows="1"
                disabled
                style={{ width: "100%" }}
              ></textarea>
            );
          },
          headerStyle: {
            padding: " 0px 0px 0px 21px",
            minWidth: "150px",
          },
          filterCellStyle: {
            padding: "5px 5px 5px 14px",
          },
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "150px",
            width: "150px",
          },
        },
        {
          title: "Comments",
          field: "agent_comments",
          filtering: true,
          filterPlaceholder: "Filter",
          headerStyle: {
            padding: " 0px 0px 0px 21px",
            minWidth: "150px",
          },
          filterCellStyle: {
            padding: "5px 5px 5px 14px",
          },
          cellStyle: {
            padding: "2px 0px 2px 6px",
            minWidth: "150px",
            width: "150px",
          },
          render: (rowData) => {
            const callbackFunction = (data) => {
              console.log("inside callback", data);
              console.log(rowData);
              this.updateData(data);
              this.updateDataInUser(data);
            };
            return (
              <TextField
                key={`${rowData.user_email_id}_comment`}
                value={rowData.agent_comments}
                callback={callbackFunction}
                rowData={rowData}
              />
            );
          },
        },
      ],
    };

    return (
      <div style={{ position: "relative" }}>
        <ThemeProvider theme={defaultMaterialTheme}>
          <MaterialTable
            isLoading={isLoading}
            icons={tableIcons}
            data={rowDataList}
            columns={columns[tabName]}
            styles
            options={{
              filtering: true,
              filter: true,
              paginationPosition: "both",
              padding: "dense",
              showTitle: false,
              thirdSortClick: false,
              pageSize: 10,
              pageSizeOptions: [25, 50, 75, 100],
              headerStyle: {
                fontSize: ismobile_font ? this.fontfunciton() : "1rem",
                backgroundColor: "#003764",
                color: "#FFF",
                padding: "6px 15px 6px 10px",
                fontFamily: "Montserrat",
              },
              rowStyle: {
                fontSize: ismobile_font ? this.fontfunciton() : "0.95rem",
                color: "#000",
                fontFamily: "Montserrat",
              },
              searchFieldStyle: {
                fontSize: "0.95rem",
              },
              filterCellStyle: {
                padding: "5px 15px 5px 4px",
                fontFamily: "Montserrat",
              },
            }}
            components={{
              Pagination: (props) => (
                <TablePagination
                  {...props}
                  labelRowsPerPage={
                    <div style={{ fontSize: 12 }}>{props.labelRowsPerPage}</div>
                  }
                  labelDisplayedRows={(row) => (
                    <div style={{ fontSize: 12 }}>
                      {props.labelDisplayedRows(row)}
                    </div>
                  )}
                  SelectProps={{
                    style: {
                      fontSize: 12,
                    },
                  }}
                />
              ),
            }}
          />
        </ThemeProvider>
      </div>
    );
  };

  updateDisplay = (sessionData) => {
    if (sessionData) {
      let userType = sessionData.attributes["custom:user_type"];
      let rowDataList = [];
      let last_key;
      let indx = 0;
      let req_body = {
        first_time_loading: "true",
      };

      if (userType === "peo") {
        let email = sessionData.attributes.email;
        let user_peo = email.split("@")[0];
        req_body[userType] =
          user_peo === "jgregory"
            ? "gregory"
            : user_peo === "galactichr"
            ? "galactic"
            : user_peo;
      }

      do {
        if (last_key) req_body["lastEvaluatedKey"] = last_key;

        if (indx === 1) delete req_body["first_time_loading"];

        userTableData(req_body, "getAll")
          .then((res) => {
            let userDataList = [];
            let rowData;
            let step;

            let emailToNameMap = {
              "akshay@mobiux.in": "Akshay",
            };

            userDataList = res.data.list;
            last_key = res.data.old_key;

            for (let userDataRow in userDataList) {
              let userData = userDataList[userDataRow];

              try {
                if (userData.status === "quote_generated") {
                  step = "Quote Generated";
                } else if (userData.status === "company_profile") {
                  step = "Underwriting Page";
                } else {
                  step = "-";
                }

                // Calculating total Payroll
                let payroll = sumPayroll(userData.childrenLoc);
                let modifiedBy;

                if (
                  userData.modifiedBy &&
                  userData.modifiedBy in emailToNameMap
                ) {
                  modifiedBy =
                    emailToNameMap[userData.modifiedBy.trim().toLowerCase()];
                } else {
                  modifiedBy = userData.modifiedBy;
                }

                rowData = {
                  companyName:
                    userData?.companyProfile?.companyName?.value || "-",

                  state:
                    userData?.companyProfile?.state1?.value?.toUpperCase() ||
                    "-",

                  fein: userData?.companyProfile?.fein?.value,

                  payroll: payroll?.toLocaleString("en-US", {
                    style: "currency",
                    currency: "USD",
                    minimumFractionDigits: 0,
                  }),

                  effectiveDate: userData?.companyProfile?.effectiveDate?.value
                    ? moment(
                        userData?.companyProfile?.effectiveDate?.value
                      ).format("M/D/YY")
                    : "-",

                  modifiedBy: modifiedBy || "-",

                  step,

                  quoteSubmitted: userData?.quoteSubmitted,

                  uw_comments: userData?.uw_comments,

                  agent_comments: userData?.agent_comments,

                  uwStatus: userData?.uw_status,
                };

                if (rowData.companyName === "-") continue;
                rowDataList.push(rowData);
              } catch (error) {
                console.log("error in: ", userData, error);
              }
            }

            this.setState({ rowDataList, isLoading: false });
            resolve();
          })
          .catch((error) => {
            console.log("error", error);
            reject();
          });

        indx += 1;
      } while (indx === 1 || last_key);
    }
  };

  render() {
    return (
      <div>
        <div ref={this.myRef}>{this.setTableData()}</div>
      </div>
    );
  }
}

export default DashboardLibertate;
