import React from "react";
import axios from "axios";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import LoadingOverlay from "react-loading-overlay";
import { updateSolveTrackingDataGeneric } from "../../api/commonApi";
import {
  deleteFilefromS3,
  dashboardFileDownload,
  uwDashboardFileDownload,
} from "../../api/dropzoneApi";
// let deletImg = require("../../images/trash.png")
export class DownloadDashboardFiles extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dashboardFiles: this.props.dashboardFiles || [],
      preUwFiles: this.props.preUwFiles || [],
      fileListToStore: this.props.fileListToStore || [],
      downloadInProgress: false,
    };
  }

  componentWillReceiveProps(nextProps) {
    this.setState({
      dashboardFiles: nextProps.dashboardFiles || [],
      preUwFiles: nextProps.preUwFiles || [],
      fileListToStore: nextProps.fileListToStore || [],
    });
  }

  downloadFile = async (file) => {
    try {
      this.setState({ downloadInProgress: true });
      dashboardFileDownload(file).then(async (res) => {
        let downloadURL = res.data;

        let a = document.createElement("a");
        a.download = file.split("/").reverse()[0];
        a.href = downloadURL;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.setState({ downloadInProgress: false });
      });
    } catch (error) {
      this.setState({ downloadInProgress: false });
    }
  };

  downloadAllFiles = async (e) => {
    e.preventDefault();
    try {
      this.setState({ downloadInProgress: true });
      let { dashboardFiles, preUwFiles, fileListToStore } = this.state;
      var zip = new JSZip();
      const folder = zip.folder("files");
      for (let file of dashboardFiles) {
        dashboardFileDownload(file).then(async (res) => {
          let downloadURL = res.data;
          let responseFileData = await axios.get(downloadURL, {
            responseType: "blob",
          });

          folder.file(file.split("/").reverse()[0], responseFileData.data);
        });
      }

      for (let file of [...preUwFiles, ...fileListToStore]) {
        uwDashboardFileDownload(file).then(async (res) => {
          let downloadURL = res.data;
          let responseFileData = await axios.get(downloadURL, {
            responseType: "blob",
          });

          folder.file(file.split("/").reverse()[0], responseFileData.data);
        });
      }
      zip
        .generateAsync({ type: "blob" })
        .then((blob) => {
          saveAs(blob, "AffinityFiles.zip");
          this.setState({ downloadInProgress: false });
        })
        .catch((e) => console.log(e));
    } catch (error) {
      console.log("erorr: ", error);
      this.setState({ downloadInProgress: false });
    }
  };

  //function to download files from fourth specific bucket
  downloadFileFourth = async (file) => {
    try {
      this.setState({ downloadInProgress: true });
      uwDashboardFileDownload(file).then(async (res) => {
        let downloadURL = res.data;

        let a = document.createElement("a");
        a.download = file.split("/").reverse()[0];
        a.href = downloadURL;
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        this.setState({ downloadInProgress: false });
      });
    } catch (error) {
      this.setState({ downloadInProgress: false });
    }
  };

  deleteFile = async (indx) => {
    let { fileList } = this.state;
    this.setState({ downloadInProgress: true });
    let removedFile = fileList[indx];
    fileList.splice(indx, 1);
    //api call to update fileList in DB.
    deleteFilefromS3(removedFile)
      .then(async (res) => {
        updateSolveTrackingDataGeneric(
          {
            domain: this.props.domainName,
            dashboardFiles: fileList,
          },
          "generic"
        ).then((res) => {
          // this.props.updateDomainMessageidFiles(this.props.domainName, 'dashboardFiles', fileList);
          this.setState({ fileList, downloadInProgress: false });
        });
      })
      .catch((err) => {
        console.log("error in delete file from s3 API");
      });
  };

  render() {
    let { preUwFiles, fileListToStore, dashboardFiles, downloadInProgress } =
      this.state;

    let filesTotal =
      (fileListToStore?.length || 0) +
      (dashboardFiles?.length || 0) +
      (preUwFiles?.length || 0);

    return (
      <>
        {filesTotal > 0 ? (
          <div className="download-files">
            <div className="upload-heading my-3 mx-auto text-center">
              <h3 className="m-0">Download Files</h3>
              <button
                className="btn btn-link"
                onClick={(e) => this.downloadAllFiles(e)}
              >
                Download ALL
              </button>
            </div>
            <LoadingOverlay active={downloadInProgress} spinner>
              <div className="my-0 mx-auto">
                {filesTotal > 0 && (
                  <table style={{ marginInline: "auto" }}>
                    <tbody>
                      {dashboardFiles?.length > 0 &&
                        dashboardFiles.map((file, indx) => {
                          return (
                            <tr>
                              <td>
                                <button
                                  className="btn btn-link"
                                  onClick={() => this.downloadFile(file)}
                                >
                                  {file.split("/").pop()}
                                </button>
                              </td>
                              {/* <td>
                      <button className="btn btn-link" onClick={() => this.deleteFile(indx)}>
                        <img src={deletImg} alt="delete button" style={{ width: "25px" }} />
                      </button>
                    </td> */}
                            </tr>
                          );
                        })}
                      {fileListToStore?.length > 0 &&
                        fileListToStore.map((file, indx) => {
                          return (
                            <tr>
                              <td>
                                <button
                                  className="btn btn-link"
                                  onClick={() => this.downloadFileFourth(file)}
                                >
                                  {file.split("/").pop()}
                                </button>
                              </td>
                              {/* <td>
                      <button className="btn btn-link" onClick={() => this.deleteFile(indx)}>
                        <img src={deletImg} alt="delete button" style={{ width: "25px" }} />
                      </button>
                    </td> */}
                            </tr>
                          );
                        })}
                      {preUwFiles?.length > 0 &&
                        preUwFiles.map((file, indx) => {
                          return (
                            <tr>
                              <td>
                                <button
                                  className="btn btn-link"
                                  onClick={() => this.downloadFileFourth(file)}
                                >
                                  {file.split("/").pop()}
                                </button>
                              </td>
                              {/* <td>
                      <button className="btn btn-link" onClick={() => this.deleteFile(indx)}>
                        <img src={deletImg} alt="delete button" style={{ width: "25px" }} />
                      </button>
                    </td> */}
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
              </div>
            </LoadingOverlay>
          </div>
        ) : (
          <div className="upload-heading my-3 mx-auto text-center">
            {" "}
            <h3>No Files Uploaded!</h3>
          </div>
        )}
      </>
    );
  }
}
