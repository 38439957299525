import React, { useState, useEffect } from "react";
import numeral from "numeral";
import ScheduledDebitCredit from "../subcompo/ScheduledDebitCredit";
// style constants
const headerStyle = {
  backgroundColor: "#2f75b5",
  color: "#FFF",
  fontWeight: "bold",
  fontFamily: "MontserratSemiBold",
  borderLeft: "1px solid white",
  borderRight: "1px solid white",
  borderTop: "none",
  fontSize: "1.1rem",
  borderRadius: "13px",
  boxShadow: "none",
  padding: "16px 0px 16px 0px",
  textShadow: "0px 0px",
  width: "210px !important",
};
const cellStyle = {
  borderLeft: "1px solid white",
  borderRight: "1px solid white",
  borderTop: "1px solid white",
  borderBottom: "1px solid white",
  borderRadius: "5px",
  boxShadow: "none",
  fontSize: "1.5rem",
  textShadow: "0px 0px",
  padding: "16px 0px 16px 0px",
};
const AddCarrier = ({
  columns,
  updateColumns,
  payrollData,
  formatPayroll,
  stateCarrierData,
  setStateCarrierData,
}) => {
  //states
  const [carrierData, setCarrierData] = useState([]);
  const [premiumData, setPremiumData] = useState({});
  const [statusData, setStatusData] = useState({});
  const [commentData, setCommentData] = useState({});
  const [buttonCounter, setButtonCounter] = useState(0);
  const [totalPremium, setTotalPremium] = useState({});
  const [addData, setAddData] = useState({ status: "", data: {}, index: 0 });

  // constants
  const render = (rowData) => {
    return (
      <div className="d-flex">
        <div className="d-flex w-100 flex-column">
          <span
            className="small-text-quote-table dark-blue"
            style={{
              marginBottom: "10px",
            }}
          >
            {rowData.type?.toUpperCase()}
          </span>
          {rowData.name !== "TOTAL" ? (
            <span
              className={
                "small-text-quote-table " +
                (rowData.type?.toUpperCase() === "MONO"
                  ? "red-text"
                  : "green-text")
              }
              style={{
                marginBottom: "10px",
              }}
            >
              <input
                type="number"
                name="premiumInput"
                placeholder="Enter Premium"
                id="premiumInput"
                onBlur={(e) => {
                  handleUWChange(
                    e,
                    rowData.name,
                    buttonCounter,
                    "premium",
                    columns.length
                  );
                }}
                style={{
                  width: "9em",
                  height: "1.7em",
                  fontSize: "16px",
                  fontFamily: "'MontserratRegular'",
                }}
              />
            </span>
          ) : (
            <span
              className={
                "small-text-quote-table " +
                (rowData.type?.toUpperCase() === "MONO"
                  ? "red-text"
                  : "green-text")
              }
              style={{
                marginBottom: "10px",
              }}
            >
              {numeral(totalPremium[columns.length]).format("$0,0")}
            </span>
          )}
          <span
            className="small-text-quote-table blue-text"
            style={
              rowData.name === "TOTAL"
                ? {
                    marginBottom: "10px",
                  }
                : {
                    marginBottom: "10px",
                  }
            }
          >
            {formatPayroll(payrollData[rowData?.name])}
          </span>
          {rowData.name !== "TOTAL" && (
            <div
              style={{
                position: "relative",
                top: "10px",
              }}
            >
              <span
                className="small-text-quote-table"
                style={{ marginBottom: "10px" }}
              >
                <ScheduledDebitCredit />
              </span>
              <span
                style={{
                  width: "125%",
                  marginBottom: "10px",
                }}
              >
                <select
                  className="uw-options"
                  style={{
                    textAlign: "center",
                  }}
                  onBlur={(e) =>
                    handleUWChange(e, rowData.name, buttonCounter, "status")
                  }
                >
                  <option value="select">Select</option>
                  <option value="bound">Bound</option>
                  <option value="quoted">Quoted</option>
                  <option value="closed">Closed</option>
                  <option value="declined">Declined</option>
                  <option value="uw_questions">UW Questions</option>
                  <option value="submitted">Submitted</option>
                </select>
              </span>
              <span
                style={{
                  marginBottom: "10px",
                }}
              >
                <textarea
                  style={{
                    marginBottom: "10px",
                  }}
                  className="uw-comment"
                  onBlur={(e) =>
                    handleUWChange(e, rowData.name, buttonCounter, "comment")
                  }
                />
              </span>
            </div>
          )}
        </div>
      </div>
    );
  };
  const new_column_data = (carrierName, id) => ({
    title: (
      <div className="d-flex justify-content-center">
        <span>
          <i onClick={() => handleEditCarrierClick(id)} className="bi bi-pen" />
        </span>
        <span className="w-75">{carrierName}</span>
      </div>
    ),
    field: carrierName,
    width: "140%",
    align: "center",
    headerStyle,
    cellStyle,
    render,
    id,
  });
  const stateList = Object.keys(
    stateCarrierData[Object.keys(stateCarrierData)[0]]
  );

  // useEffects
  // set StateCarrierData on unblur from comments/premium/status
  useEffect(() => {
    if (!premiumData.state && !statusData.state && !commentData.state) return;
    const index = {
      premium: premiumData.buttonCounter,
      status: statusData.buttonCounter,
      comment: commentData.buttonCounter,
    };
    const updateCustomCarrierData = (data, state, value) => {
      if (state !== undefined) {
        data[state] = {
          ...data[state],
          ...value,
          debit_credit: { isChecked: false, value: "" },
          isChecked: false,
        };
      }
    };

    let customCarrierData =
      stateCarrierData && carrierData[index.premium] !== undefined
        ? stateCarrierData[`custom_carrier_${carrierData[index.premium]}`] || {}
        : {};
    updateCustomCarrierData(customCarrierData, premiumData.state, {
      premium: `$${Number(premiumData.data).toLocaleString("en")}`,
    });
    index.premium !== undefined &&
      (stateCarrierData[
        `custom_carrier_${carrierData[index.premium]}`
      ] = customCarrierData);
    totalPremium[premiumData.length] = 0;
    for (let i in stateCarrierData[
      `custom_carrier_${carrierData[index.premium]}`
    ]) {
      totalPremium[premiumData.length] += numeral(
        stateCarrierData[`custom_carrier_${carrierData[index.premium]}`]?.[i]
          ?.premium
      ).value();
    }

    setTotalPremium(totalPremium);
    customCarrierData = stateCarrierData
      ? stateCarrierData[`custom_carrier_${carrierData[index.status]}`] || {}
      : {};
    updateCustomCarrierData(customCarrierData, statusData.state, {
      status: statusData.data,
    });
    index.status !== undefined &&
      (stateCarrierData[
        `custom_carrier_${carrierData[index.status]}`
      ] = customCarrierData);

    customCarrierData = stateCarrierData
      ? stateCarrierData[`custom_carrier_${carrierData[index.comment]}`] || {}
      : {};
    updateCustomCarrierData(customCarrierData, commentData.state, {
      comments: commentData.data,
    });
    index.comment !== undefined &&
      (stateCarrierData[
        `custom_carrier_${carrierData[index.comment]}`
      ] = customCarrierData);

    setStateCarrierData(stateCarrierData);
    return () => {
      setPremiumData([]);
      setStatusData([]);
      setCommentData([]);
    };
  }, [premiumData, statusData, commentData]);

  // update column and stateCarrierData when adding new carrier or editing existing carrier
  useEffect(() => {
    if (columns.length > 1) {
      const columnsCopy = columns.map((c, i) => {
        if (c.id == addData.id) {
          return addData.data;
        } else return c;
      });
      if (addData.status == "edit" && addData.data.field.length)
        Object.keys(stateCarrierData).map((s, i) => {
          if (stateCarrierData[s].index == addData.id) {
            stateCarrierData[`custom_carrier_${addData.data.field}`] =
              stateCarrierData[s];
            delete stateCarrierData[s];
            delete stateCarrierData["custom_carrier_undefined"];
            setStateCarrierData(stateCarrierData);
          }
        });
      updateColumns(columnsCopy);
    }
  }, [addData]);

  // re-render rowData to update total premium in UI
  useEffect(() => {
    columns.map((c, i) => {
      c.render = render;
    });
  }, [totalPremium]);

  // functions
  // add new column with input field as header and empty fields for premiums, status, comments
  const handleAddCarrierClick = () => {
    const id = Date.now();
    setButtonCounter(buttonCounter + 1);
    const new_column = {
      title: (
        <input
          type="text"
          autoFocus
          name="carrierText"
          id="carrierText"
          placeholder="Carrier Name"
          style={{ width: "8.5em", height: "1.7em" }}
          onBlur={(e) => {
            if (e.target.value.length > 0) {
              const newAgentCarrier = e.target.value;
              setCarrierData([...carrierData, e.target.value]);
              const new_column = new_column_data(newAgentCarrier, id);
              stateCarrierData[`custom_carrier_${newAgentCarrier}`] = {
                index: id,
              };
              for (let state of stateList) {
                stateCarrierData[`custom_carrier_${newAgentCarrier}`][
                  state.toLowerCase()
                ] = {
                  debit_credit: { isChecked: false, value: "" },
                  isChecked: false,
                };
              }
              setStateCarrierData(stateCarrierData);
              setAddData({
                status: true,
                data: new_column,
                id: id,
              });
            }
          }}
        />
      ),
      field: "",
      width: "140%",
      align: "center",
      headerStyle,
      cellStyle,
      render,
      id,
    };
    updateColumns([...columns, new_column]);
  };

  // when clicked on edit button, column header turned into text input to update carrier name
  const handleEditCarrierClick = (id) => {
    const new_column = {
      title: (
        <input
          type="text"
          autoFocus
          name="carrierText"
          id="carrierText"
          placeholder="Carrier Name"
          style={{ width: "8.5em", height: "1.7em" }}
          onBlur={(e) => {
            if (e.target.value.length > 0) {
              const newAgentCarrier = e.target.value;
              setCarrierData([...carrierData, e.target.value]);
              const new_column = new_column_data(newAgentCarrier, id);
              setAddData({
                status: "edit",
                data: new_column,
                id: id,
              });
            }
          }}
        />
      ),
      field: "",
      width: "140%",
      align: "center",
      headerStyle,
      cellStyle,
      render,
      id,
    };
    setAddData({ status: "edit", id, data: new_column });
  };

  // when unblurred, used to update premium, comments, status
  const handleUWChange = (e, state, buttonCounter, type, length) => {
    type == "premium" &&
      setPremiumData({
        data: e.target.value,
        state: state.toLowerCase(),
        buttonCounter,
        length,
      });
    type == "status" &&
      setStatusData({
        data: e.target.value,
        state: state.toLowerCase(),
        buttonCounter,
      });
    type == "comment" &&
      setCommentData({
        data: e.target.value,
        state: state.toLowerCase(),
        buttonCounter,
      });
  };

  return (
    <div className="add-carrier">
      <button className="btn btn-primary" onClick={handleAddCarrierClick}>
        Add Carrier
      </button>
    </div>
  );
};

export default AddCarrier;
