import React, { useState } from "react";
import CompLoc from "../sections-quote/CompLoc";
import axios from "axios";
import { awsUrl2 } from "../../../config";
import styles from "./LocationAndPayroll.module.scss";
import { getStateAndCity } from "../../../api/commonApi";

export default function LocationAndPayroll({
  effectiveDate,
  childrenLoc,
  emodStatesData,
  peoDetails,
  carrierList,
  setChildrenLoc,
  refreshEmodDetails,
}) {
  const [state_class_code, setState_class_code] = useState({});

  const setStateAndCity = (zipCode, index) => {
    let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));
    let _emodStatesData = JSON.parse(JSON.stringify(emodStatesData));
    let _peoDetails = JSON.parse(JSON.stringify(peoDetails));
    let _carrierList = JSON.parse(JSON.stringify(carrierList));

    getStateAndCity(zipCode, {})
      .then((res) => {
        const data = res.data;
        if (data !== `"Data not found"`) {
          _childrenLoc[index].state = { value: data.state };
          _childrenLoc[index].cityName = { value: data.city };

          refreshEmodDetails(
            _peoDetails.selectedPeo === "insurecomp",
            _emodStatesData,
            _childrenLoc,
            _peoDetails.selectedPeo,
            _carrierList
          );

          setChildrenLoc(_childrenLoc);
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
      });
  };

  const removeClassCode = (locationIndex, classCodeIndex) => {
    let _state_class_code = JSON.parse(JSON.stringify(state_class_code));
    let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));

    if (!_childrenLoc[locationIndex]?.state?.value) {
      delete _childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
      setChildrenLoc(_childrenLoc);
      return;
    }

    //code to handle state-classcode combination requested pricing starts
    let currState = _childrenLoc[locationIndex].state.value || "";
    if (
      currState.toLowerCase() in _state_class_code &&
      _state_class_code[currState.toLowerCase()].val
    ) {
      let class_code_desc = _childrenLoc[locationIndex].classCodesInfo[
        classCodeIndex
      ]["classCodeDescription"]
        ? _childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
            "classCodeDescription"
          ].value
        : undefined;
      if (class_code_desc) {
        let class_code = class_code_desc.split(":")[0].trim();
        if (
          _state_class_code[currState.toLowerCase()].cc_dsc &&
          class_code in _state_class_code[currState.toLowerCase()].cc_dsc
        ) {
          let locToUpdate, ccToUpdate;
          for (let locIndx in _childrenLoc) {
            if (_childrenLoc[locIndx].state.value === currState) {
              let ccInfo = _childrenLoc[locIndx].classCodesInfo;
              for (let ccIndx in ccInfo) {
                if (
                  !(
                    ccInfo[ccIndx] &&
                    ccInfo[ccIndx].classCodeDescription &&
                    ccInfo[ccIndx].classCodeDescription.value
                  )
                ) {
                  continue;
                }
                let local_cc_desc = ccInfo[ccIndx].classCodeDescription.value;

                let local_cc = local_cc_desc.split(":")[0].trim();
                if (
                  !locToUpdate &&
                  !ccToUpdate &&
                  (Number(locIndx) !== Number(locationIndex) ||
                    Number(ccIndx) !== Number(classCodeIndex)) &&
                  Number(class_code) === Number(local_cc)
                ) {
                  _childrenLoc[locIndx].classCodesInfo[
                    ccIndx
                  ].pricing.disabled = true;
                  locToUpdate = locIndx;
                  ccToUpdate = ccIndx;
                }
              }
            }
          }
          if (locToUpdate && ccToUpdate) {
            _childrenLoc[locToUpdate].classCodesInfo[
              ccToUpdate
            ].pricing.disabled = false;
          } else {
            delete _state_class_code[currState.toLowerCase()].cc_dsc[
              class_code
            ];
          }
        }
      }
    }
    //code to handle state-classcode ends here

    delete _childrenLoc[locationIndex].classCodesInfo[classCodeIndex];
    setState_class_code(_state_class_code);
    setChildrenLoc(_childrenLoc);
  };

  const updateLocationInfo = (
    value,
    locationIndex,
    fieldName,
    classCodeIndex
  ) => {
    try {
      let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));
      let _emodStatesData = JSON.parse(JSON.stringify(emodStatesData));
      let _peoDetails = JSON.parse(JSON.stringify(peoDetails));
      let _carrierList = JSON.parse(JSON.stringify(carrierList));

      if (classCodeIndex) {
        _childrenLoc[locationIndex].classCodesInfo[classCodeIndex] = {};
      } else {
        _childrenLoc[locationIndex][fieldName] = { value: value };
        if (fieldName === "state") {
          refreshEmodDetails(
            _peoDetails.selectedPeo === "insurecomp" ||
              _peoDetails.selectedPeo === "wcn",
            _emodStatesData,
            _childrenLoc,
            _peoDetails.selectedPeo,
            _carrierList
          );
        }
      }
      setChildrenLoc(_childrenLoc);
    } catch (error) {
      console.log(error);
    }
  };

  const updateClassCodesInfo = (
    locationIndex,
    classCodeIndex,
    fieldName,
    value,
    deleteAcordFlag
  ) => {
    let _state_class_code = JSON.parse(JSON.stringify(state_class_code));
    let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));

    _childrenLoc[locationIndex].classCodesInfo[classCodeIndex][fieldName] = {
      value: value,
    };

    // remove the from acord flag
    if (deleteAcordFlag) {
      delete _childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
        ?.fromAcord;
    }

    if (fieldName === "pricing") {
      let currState = _childrenLoc[locationIndex].state.value || "";
      if (currState.toLowerCase() in _state_class_code) {
        let class_code_desc;
        try {
          class_code_desc =
            _childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value;
        } catch (error) {}
        if (!("cc_dsc" in _state_class_code[currState.toLowerCase()])) {
          _state_class_code[currState.toLowerCase()].cc_dsc = {};
        }
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (
            !(class_code in _state_class_code[currState.toLowerCase()].cc_dsc)
          ) {
            _state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
              value;
          } else {
            let locToUpdate, ccToUpdate;
            for (let locIndx in _childrenLoc) {
              if (_childrenLoc[locIndx].state.value === currState) {
                let ccInfo = _childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  let cc_desc;
                  try {
                    cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                  } catch (error) {}
                  if (cc_desc) {
                    let cc = cc_desc.split(":")[0].trim();
                    if (cc === class_code) {
                      _childrenLoc[locIndx].classCodesInfo[ccIndx]["pricing"] =
                        {
                          value: value,
                          disabled: true,
                        };
                      if (!locToUpdate && !ccToUpdate) {
                        locToUpdate = locIndx;
                        ccToUpdate = ccIndx;
                      }
                    }
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              _childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                "pricing"
              ].disabled = false;

              _state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                value;
            }
          }
        }
      }
    }
    if (fieldName === "classCodeDescription") {
      let currState = _childrenLoc[locationIndex].state.value || "";
      if (currState.toLowerCase() in _state_class_code) {
        let class_code_desc = _childrenLoc[locationIndex].classCodesInfo[
          classCodeIndex
        ]["classCodeDescription"]
          ? _childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
              "classCodeDescription"
            ].value
          : undefined;

        if (!("cc_dsc" in _state_class_code[currState.toLowerCase()])) {
          _state_class_code[currState.toLowerCase()].cc_dsc = {};
        }
        if (class_code_desc) {
          let class_code = class_code_desc.split(":")[0].trim();
          if (class_code in _state_class_code[currState.toLowerCase()].cc_dsc) {
            let locToUpdate, ccToUpdate;
            for (let locIndx in _childrenLoc) {
              if (_childrenLoc[locIndx].state.value === currState) {
                let ccInfo = _childrenLoc[locIndx].classCodesInfo;
                for (let ccIndx in ccInfo) {
                  let cc_desc;
                  try {
                    cc_desc = ccInfo[ccIndx]["classCodeDescription"].value;
                  } catch (e) {}
                  if (cc_desc) {
                    let cc = cc_desc.split(":")[0].trim();
                    if (cc === class_code) {
                      _childrenLoc[locIndx].classCodesInfo[ccIndx]["pricing"] =
                        {
                          value:
                            _state_class_code[currState.toLowerCase()].cc_dsc[
                              class_code
                            ],
                          disabled: true,
                        };
                      if (!locToUpdate && !ccToUpdate) {
                        locToUpdate = locIndx;
                        ccToUpdate = ccIndx;
                      }
                    }
                  }
                }
              }
            }
            if (locToUpdate && ccToUpdate) {
              _childrenLoc[locToUpdate].classCodesInfo[ccToUpdate][
                "pricing"
              ].disabled = false;
            }
          } else {
            if (
              _childrenLoc[locationIndex].classCodesInfo[classCodeIndex]
                .pricing &&
              _childrenLoc[locationIndex].classCodesInfo[classCodeIndex].pricing
                .value
            ) {
              _state_class_code[currState.toLowerCase()].cc_dsc[class_code] =
                _childrenLoc[locationIndex].classCodesInfo[
                  classCodeIndex
                ].pricing.value;
            }
          }
        }
      }
    }
    setState_class_code(_state_class_code);
    setChildrenLoc(_childrenLoc);
  };

  const updateLocationError = (
    locationIndex,
    classCodeIndex,
    fieldName,
    value
  ) => {
    let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));

    if (
      classCodeIndex &&
      _childrenLoc?.[locationIndex]?.classCodesInfo?.[classCodeIndex]?.[
        fieldName
      ]
    ) {
      _childrenLoc[locationIndex].classCodesInfo[classCodeIndex][
        fieldName
      ].error = value;
    } else if (_childrenLoc?.[locationIndex]?.[fieldName]) {
      _childrenLoc[locationIndex][fieldName].error = value;
    }
    setChildrenLoc(_childrenLoc);
  };

  const resetClassCodeDescriptionData = (locationIndex) => {
    let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));

    for (let index in _childrenLoc?.[locationIndex]?.classCodesInfo || []) {
      if (
        _childrenLoc[locationIndex].classCodesInfo[index].classCodeDescription
      ) {
        delete _childrenLoc[locationIndex].classCodesInfo[index]
          .classCodeDescription.value;
      }
      if (_childrenLoc[locationIndex].classCodesInfo[index].pricing) {
        delete _childrenLoc[locationIndex].classCodesInfo[index].pricing;
      }
    }
    setChildrenLoc(_childrenLoc);
  };

  const deleteAddress = (addressId) => {
    try {
      let _childrenLoc = JSON.parse(JSON.stringify(childrenLoc));
      let _emodStatesData = JSON.parse(JSON.stringify(emodStatesData));
      let _peoDetails = JSON.parse(JSON.stringify(peoDetails));
      let _carrierList = JSON.parse(JSON.stringify(carrierList));

      if (_childrenLoc[addressId]) delete _childrenLoc[addressId];

      refreshEmodDetails(
        _peoDetails.selectedPeo === "insurecomp" ||
          _peoDetails.selectedPeo === "wcn",
        _emodStatesData,
        _childrenLoc,
        _peoDetails.selectedPeo,
        _carrierList
      );

      setChildrenLoc(_childrenLoc);
    } catch (error) {
      console.log("Error deleting location: ", error);
    }
  };

  const onAddChildLoc = (data) => {
    let dupChildrenLoc = JSON.parse(JSON.stringify(childrenLoc));
    let rand = Math.random();
    const keys = Object.keys(dupChildrenLoc);
    const index = parseInt(keys[keys.length - 1]) + 1;
    if (!data.location_details) {
      data.location_details = {};
    }
    dupChildrenLoc[index] = {
      number: index,
      key: rand,
      classCodesInfo: { 0: {} },
      state: {},
      zipCode: {},
      cityName: {},
      street: {},
      suta: {},
    };
    setChildrenLoc(dupChildrenLoc);
  };

  let locationCount = 1,
    index = 0;

  try {
    locationCount = Object.keys(childrenLoc).length;
  } catch (e) {}

  // console.log("effectiveDate: ", effectiveDate);

  return (
    <div className={`calculator ${styles.location_payroll_container}`}>
      <div id="allLocations" className={styles.allLocations}>
        <div id="addressDiv">
          {Object.values(childrenLoc).map((e) => {
            index++;
            return (
              <div key={index}>
                <CompLoc
                  index={index}
                  key={e.key}
                  locationInfo={e}
                  updateLocationInfo={updateLocationInfo}
                  setStateAndCity={setStateAndCity}
                  removeClassCode={removeClassCode}
                  updateClassCodesInfo={updateClassCodesInfo}
                  updateLocationError={updateLocationError}
                  resetClassCodeDescriptionData={resetClassCodeDescriptionData}
                  deleteLocation={deleteAddress}
                  effectiveDate={effectiveDate}
                  locationCount={locationCount}
                  childrenLoc={childrenLoc}
                />
                <hr className={styles.hr}></hr>
              </div>
            );
          })}
        </div>
      </div>
      <div className="">
        <button
          type="button"
          className={`btnInvisible ${styles.addLoc} ${styles.transpButton} p-0`}
          onClick={onAddChildLoc}
        >
          <img src={require("../../../images/plus4.png")} /> <b>Add location</b>
        </button>
      </div>
    </div>
  );
}
