import React, { Component } from "react";
import Loader from "../component/common/NewLoader";
import SideTab from "./common/SideTab";
import Header from "../component/common/Header";
import Popup from "../component/common/Popup";
import QuotePageBanner from "../component/subcompo/sections-home/QuotePageBanner";
import { checkMailCampaignUser } from "../utils/check_mail_campaign_user";
import FetchQuotesData from "./FetchQuotesData";
import { LibertateContext } from "../context/Context";

class NewQuote extends Component {
  constructor(props) {
    super(props);
    this.state = {
      popupData: {
        title: "",
        children: <></>,
        acceptBtn: "",
        acceptBtnCallback: () => {},
        rejectBtnCallback: () => {},
        show: false,
      },
    };
  }

  updatePopUpData = (pData) => {
    this.setState({ popupData: pData });
  };

  updateCurrentFormStage = (currentFormStage, stageNumber) => {
    if (
      (currentFormStage <= stageNumber &&
        this.state.currentFormStage !== currentFormStage) ||
      ["riskProfile", "uwPortal"].includes(currentFormStage)
    ) {
      this.setState({ currentFormStage });
    }
  };

  updateMailCampaignUser = (mailCampaignUser) => {
    if (this.state.mailCampaignUser !== mailCampaignUser) {
      this.setState({ mailCampaignUser });
    }
  };

  render() {
    let { popupData, currentFormStage, mailCampaignUser } = this.state;

    return (
      <div className="newquote-mainbody container-fluid">
        <div
          className="d-flex justify-content-between"
          style={{ backgroundColor: "white" }}
        >
          <Header sessionData={this.props.sessionData} />
        </div>
        {(checkMailCampaignUser() || mailCampaignUser) &&
          currentFormStage === 1 && <QuotePageBanner />}
        <FetchQuotesData>
          <SideTab
            updatePopUpData={this.updatePopUpData}
            updateCurrentFormStage={this.updateCurrentFormStage}
            updateMailCampaignUser={this.updateMailCampaignUser}
            currentFormStage={currentFormStage}
            sessionData={this.props.sessionData}
            setFormStage={this.props.setFormStage}
            formStage={this.props.formStage}
            getQuoteClicked={this.props.getQuoteClicked}
          />
        </FetchQuotesData>
        {this.props.displayLoader && <Loader />}
        {popupData.show && (
          <Popup popupData={popupData} updatePopUpData={this.updatePopUpData} />
        )}
      </div>
    );
  }
}

export default function NewQuoteWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => (
        <NewQuote
          setFormStage={context.setFormStage}
          getQuoteClicked={context.getQuoteClicked}
          formStage={context.formStage}
          sessionData={props.sessionData}
          displayLoader={context.displayLoader}
        />
      )}
    </LibertateContext.Consumer>
  );
}
