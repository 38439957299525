import React, { useState, useEffect } from "react";
import { Tab, Col, Row, Nav } from "react-bootstrap";
import FormCompPro from "../../component/subcompo/sections-quote/FormCompPro";
import FormUnderQues from "../../component/subcompo/sections-quote/FormUnderQues";
import FormCmprQuote from "../../component/subcompo/sections-quote/FormCmprQuote";
import RiskProfile from "../../component/subcompo/sections-quote/RiskProfile/RiskProfile";
import UnderWriting from "../../component/common/UnderWriting";
import { logout } from "../../utils/common";
import HeaderSubPart from "../../component/common/HeaderSubPart";

let isMobile = window.innerWidth < 900;
const stages = {
  one: 1,
  two: 2,
  three: 3,
  four: 4,
};

const reverseStageMap = {
  1: "one",
  2: "two",
  3: "three",
  4: "four",
  riskProfile: "riskProfile",
};

function TabNavItem(props) {
  let currProspect = sessionStorage.getItem("currProspect");
  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }
  return (
    <Nav.Item>
      <Nav.Link
        eventKey={props.eventKey}
        className={props.disabled ? "disabled" : ""}
        onClick={props.onClick}
      >
        <div className="d-flex flex-row align-items-center">
          <img src={props.image} width="50" height="50" alt={props.label} />
          <p
            className={
              "sidebar-stagename mb-0 " + (props.active ? "active" : "")
            }
          >
            {props.label}
          </p>
          {props.dash && (
            <p className={"ml-auto mr-2 mb-3 tick"}>
              <img src={require("../../images/tick_bw.png")} />
            </p>
          )}
          {!props.dash && (
            <p
              className={
                "ml-auto mr-2 mb-3 " + (props.completed ? "tick" : "disabled")
              }
            >
              <img src={require("../../images/tick.png")} />
            </p>
          )}
        </div>
      </Nav.Link>
    </Nav.Item>
  );
}

function SideTab(props) {
  let {
    updatePopUpData,
    updateCurrentFormStage,
    updateMailCampaignUser,
    sessionData,
    formStage,
    getQuoteClicked,
  } = props;

  const windowPath = window.location.pathname.toLowerCase().includes("/quote");
  let currProspect = sessionStorage.getItem("currProspect");
  let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
    ? true
    : false;
  let [isLoggedIn, setLoggedInStatus] = useState(false);
  let [group, setGroup] = useState([]);
  let [userGroups, setuserGroups] = useState([]);
  let [emailID, setemailID] = useState("");
  let [isDemoLogin, setIsDemoLogin] = useState(false);
  let [isUwLogin, setIsUwLogin] = useState(false);
  const [enableUnderwritingPage, setEnableUnderwritingPage] = useState(false);

  useEffect(() => {
    async function authentication() {
      try {
        emailID = sessionData.attributes.email.split("@")[0];
        let userGroups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
        if (userGroups?.includes(process.env.REACT_APP_DEMO_GROUP)) {
          setIsDemoLogin(true);
        }
        if (
          userGroups?.includes(process.env.REACT_APP_UW_GROUPS) &&
          !(window.location.pathname.toLowerCase() === "/uwportal")
        ) {
          setIsUwLogin(true);
        }
        setuserGroups({ userGroups });
        setemailID(emailID);
        console.log("emailID: ", emailID, userGroups);
      } catch (error) {
        console.log("comp did update err", error);
      }

      if (emailID) {
        setuserGroups({ emailID });
      }
    }

    authentication();
  }, []);

  let isHistoryVariant;
  if (currProspect) {
    isHistoryVariant = JSON.parse(currProspect).historicalQuote;
  }

  let stageNumber = stages[formStage];

  let tabLinks;
  tabLinks = [
    {
      label: "Company Profile",
      image: require("../../images/comPro2.png"),
    },
    {
      label: "Underwriting Questions",
      image: require("../../images/underQues2.png"),
    },
  ];

  if (!flagPreUnderWriter) {
    tabLinks.push(
      ...[
        { label: "Get Quotes", image: require("../../images/getQuotes2.png") },
      ]
    );
  }

  const riskPofileTabLink = {
    label: "Risk Profile",
    image: require("../../images/black-card-1.png"),
    eventKey: "riskProfile",
    disabled: stageNumber < 2,
    completed: stageNumber > 1,
    key: 11,
    onClick: () => {
      updateCurrentFormStage("riskProfile");
    },
  };
  const underWritingPageLink = {
    label: "UnderWriting Page",
    image: require("../../images/black-card-1.png"),
    eventKey: "underwriting",
    disabled: stageNumber < 3 || !enableUnderwritingPage,
    completed: stageNumber > 2 && enableUnderwritingPage,
    key: 12,
  };

  // if (!props.currentFormStage) {
  //   try {
  //     if (updateCurrentFormStage !== undefined) {
  //       updateCurrentFormStage(stageNumber, stageNumber);
  //     }
  //   } catch (error) {
  //     console.log(error, " contents not getting print");
  //   }
  // }

  for (let i = 0; i < tabLinks.length; i++) {
    let tabLink = tabLinks[i];
    tabLink.eventKey = Object.keys(stages)[i];
    // Disable if this stage is later than the current stage.
    tabLink.disabled = i >= stageNumber;
    tabLink.onClick = () => {
      updateCurrentFormStage(i + 1, stageNumber);
    };
    // Completed if stateNuber is more than this stage.
    tabLink.completed = stageNumber > i + 1;
    if (isHistoryVariant) {
      if (i < 4) {
        tabLink.disabled = true;
        tabLink.completed = false;
        tabLink.dash = true;
      }
    }
    // Active tab if stageNumber equals this stage.
    tabLink.active = stageNumber === i + 1;
    tabLink.key = i;
  }

  useEffect(() => {
    async function checkLoggedInStatus() {
      let groups;
      try {
        groups =
          sessionData.signInUserSession.accessToken.payload["cognito:groups"];
      } catch (error) {}
      if (sessionData) {
        setLoggedInStatus(true);
        if (groups) {
          setGroup(groups);
        }
      }
    }
    checkLoggedInStatus();
  }, []);

  useEffect(() => {
    let _stageNumber = stages[formStage];
    updateCurrentFormStage(_stageNumber, _stageNumber);
  }, [formStage, getQuoteClicked]);

  const navigationContent = (
    <Col
      md="auto"
      className="newquote-sidebar d-flex flex-column justify-content-between"
    >
      <Nav className="flex-column">
        <Nav.Item>
          <Nav.Link className="sidebar-heading" disabled>
            <span>
              Complete the process in a few easy steps and get your quote
            </span>
          </Nav.Link>
        </Nav.Item>
        {tabLinks.map((tabLink) => (
          <TabNavItem {...tabLink} />
        ))}
        <hr
          style={{
            width: "90%",
            backgroundColor: "grey",
          }}
        />
        <TabNavItem {...riskPofileTabLink} />
        {group.includes(process.env.REACT_APP_UW_GROUPS) && (
          <TabNavItem {...underWritingPageLink} />
        )}
      </Nav>
    </Col>
  );

  const NavigationSubcontent = (
    <Nav.Item>
      <Nav.Link>
        <HeaderSubPart />
      </Nav.Link>
    </Nav.Item>
  );
  return (
    <>
      <Tab.Container
        id="left-tabs-example"
        activeKey={reverseStageMap[props.currentFormStage]}
      >
        <div
          className={`row  ${
            windowPath ? "position-relative" : ""
          } no-gutters `}
        >
          {!isMobile && windowPath ? (
            navigationContent
          ) : (
            <nav className="navwidth newquote-sidebar bg-white">
              <input
                type="checkbox"
                id="menu"
                name="menu"
                className="m-menu__checkbox bg-white"
              />
              <label className="m-menu__toggle" for="menu">
                <svg
                  width="35"
                  height="35"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="#000"
                  stroke-width="2"
                  stroke-linecap="butt"
                  stroke-linejoin="arcs"
                >
                  <line x1="3" y1="12" x2="21" y2="12"></line>
                  <line x1="3" y1="6" x2="21" y2="6"></line>
                  <line x1="3" y1="18" x2="21" y2="18"></line>
                </svg>
              </label>
              <label className="m-menu__overlay" for="menu"></label>

              <div className="m-menu">
                <div className="m-menu__header">
                  <label className="m-menu__toggle" for="menu">
                    <svg
                      width="35"
                      height="35"
                      viewBox="0 0 24 24"
                      fill="none"
                      stroke="#000000"
                      stroke-width="2"
                      stroke-linecap="butt"
                      stroke-linejoin="arcs"
                    >
                      <line x1="18" y1="6" x2="6" y2="18"></line>
                      <line x1="6" y1="6" x2="18" y2="18"></line>
                    </svg>
                  </label>
                  <span></span>
                </div>
                <Nav.Item>
                  <Nav.Link className="sidebar-heading">
                    <div className="d-flex align-items-center justify-content-between">
                      <span className="LoginUser">{emailID}</span>
                      <span>
                        {isMobile ? (
                          <li className="no-bullets">
                            <a
                              className="dropdown-item"
                              onClick={() => {
                                logout();
                              }}
                            >
                              Logout <i className="bi-person-fill"></i>
                            </a>
                          </li>
                        ) : (
                          " "
                        )}
                      </span>
                    </div>
                  </Nav.Link>
                </Nav.Item>
                {windowPath ? navigationContent : ""}
                {NavigationSubcontent}
                {isMobile && isUwLogin ? (
                  <a
                    className="dashboard-link-mobile text-dark"
                    href={"/UwPortal"}
                  >
                    UW Dashboard
                  </a>
                ) : (
                  ""
                )}
                <hr
                  style={{
                    width: "90%",
                    backgroundColor: "grey",
                  }}
                />
              </div>
            </nav>
          )}

          {!windowPath ? (
            ""
          ) : (
            <Col className="newquote-content">
              <Tab.Content>
                <Tab.Pane eventKey="one">
                  <FormCompPro
                    updateMailCampaignUser={updateMailCampaignUser}
                    isDemoLogin={isDemoLogin}
                    sessionData={props.sessionData}
                    setFormStage={props.setFormStage}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="two">
                  <FormUnderQues
                    updatePopUpData={updatePopUpData}
                    sessionData={props.sessionData}
                    setFormStage={props.setFormStage}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="three">
                  <FormCmprQuote
                    updatePopUpData={updatePopUpData}
                    setEnableUnderwritingPage={setEnableUnderwritingPage}
                    sessionData={props.sessionData}
                    setFormStage={props.setFormStage}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="riskProfile">
                  <RiskProfile
                    updatePopUpData={updatePopUpData}
                    sessionData={props.sessionData}
                  />
                </Tab.Pane>
                <Tab.Pane eventKey="underwriting">
                  <UnderWriting
                    updatePopUpData={updatePopUpData}
                    sessionData={props.sessionData}
                  />
                </Tab.Pane>
              </Tab.Content>
            </Col>
          )}
        </div>
      </Tab.Container>
    </>
  );
}

export default SideTab;
