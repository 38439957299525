import React, { useEffect, useState } from "react";
import DropDown from "../../common/DropDown/DropDown";
import Form from "react-bootstrap/Form";
import ReactMultiSelect from "../../common/ReactMultiSelect/ReactMultiSelect";
import { NumericFormat, PatternFormat } from "react-number-format";
import DatePicker from "react-datepicker";
import moment from "moment";
import carrierList from "../../../utils/carrierList.json";
import styles from "./CompanyProfile.module.scss";
import { getStateAndCity } from "../../../api/commonApi";
// import "./CompanyProfile.css";

const { carrierPeoProgramList } = carrierList;

var stateCity = require("../../../utils/state_city.json");

export default function CompanyProfile({
  companyProfile,
  userDetails,
  childrenLoc,
  peoDetails,
  carrierList,
  setCompanyProfile,
  setPeoDetails,
  setCarrierList,
  emodStatesData,
  _carrierList,
  refreshEmodDetails,
  sessionData,
}) {
  const [sameAsPrimary, setSameAsPrimary] = useState(false);
  const [stateSpecificCheck, setStateSpecificCheck] = useState(false);
  const [specificStatesList, setSpecificStatesList] = useState([]);
  const [zipError, setZipError] = useState(false);

  useEffect(() => {
    // Checking the groups of stateSpecific, it stateSpecific is present we set the stateSpecificCheck to True else false
    let groups =
      sessionData?.signInUserSession?.accessToken?.payload["cognito:groups"];
    // this.props.sessionData hold the currentSeesion Data.
    if (groups) {
      if (groups.includes(process.env.REACT_APP_STATE_SPECIFIC_GROUP)) {
        let specifiedState =
          sessionData?.attributes?.["custom:state"].split(",");
        setStateSpecificCheck(true);
        setSpecificStatesList(specifiedState);
      }
    }
  }, []);
  let flagPreUnderWriter = sessionStorage.getItem("preUnderwriter")
    ? true
    : false;

  let peoOptions = [{ label: "Please Select", value: "" }];
  let peoUsed = new Set();

  for (let peoData of userDetails.peoList || []) {
    if (!peoUsed.has(peoData.value.toLowerCase())) {
      peoOptions.push({ label: peoData.label, value: peoData.value });
      peoUsed.add(peoData.value.toLowerCase());
    }
  }

  let allStates1 = [
    <option key={0} value="">
      State
    </option>,
  ];
  for (var item of stateCity) {
    if (stateSpecificCheck) {
      if (specificStatesList.includes(item.Code)) {
        if (companyProfile && item === companyProfile.state1.value) {
          allStates1.push(
            <option key={item.Code} value={item.Code.toLowerCase()} selected>
              {item.Code}
            </option>
          );
        } else {
          allStates1.push(
            <option key={item.Code} value={item.Code.toLowerCase()}>
              {item.Code}
            </option>
          );
        }
      }
    } else {
      if (companyProfile && item === companyProfile.state1.value) {
        allStates1.push(
          <option key={item.Code} value={item.Code.toLowerCase()} selected>
            {item.Code}
          </option>
        );
      } else {
        allStates1.push(
          <option key={item.Code} value={item.Code.toLowerCase()}>
            {item.Code}
          </option>
        );
      }
    }
  }
  let allStates2 = [
    <option key={0} value="">
      State
    </option>,
  ];
  for (var item of stateCity) {
    if (companyProfile && item === companyProfile.state2.value) {
      allStates2.push(
        <option key={item.Code} value={item.Code.toLowerCase()} selected>
          {item.Code}
        </option>
      );
    } else {
      allStates2.push(
        <option key={item.Code} value={item.Code.toLowerCase()}>
          {item.Code}
        </option>
      );
    }
  }

  let expdate =
    companyProfile?.expectedExpiryDate?.value ||
    moment(companyProfile.expectedStartDate.value).add(1, "year");

  let expstartdate = companyProfile.expectedStartDate.value;

  const handleSameAsPrimaryCheck = () => {
    let _companyProfile = JSON.parse(JSON.stringify(companyProfile));

    if (sameAsPrimary) {
      _companyProfile.cityName2.value = "";
      _companyProfile.state2.value = "";
      _companyProfile.zipCode2.value = "";
      _companyProfile.street2.value = "";
      setSameAsPrimary(false);
      setCompanyProfile(null, null, _companyProfile);
    } else {
      _companyProfile.cityName2.value = _companyProfile.cityName1.value;
      _companyProfile.state2.value = _companyProfile.state1.value;
      _companyProfile.zipCode2.value = _companyProfile.zipCode1.value;
      _companyProfile.street2.value = _companyProfile.street1.value;
      setSameAsPrimary(true);
      setCompanyProfile(null, null, _companyProfile);
    }
  };

  const findCarriers = (peoValue, expectedStartDate) => {
    let carrierList = [];
    for (let data of carrierPeoProgramList) {
      if (data.devRemoved && process.env.REACT_APP_MODE === "dev") continue;
      if (data.prodRemoved && process.env.REACT_APP_MODE === "prod") continue;

      if (data.disableCarrier) continue;

      if (
        data.displayPeoName === "LIB" &&
        data.actualCarrierName.indexOf("HEMIC") > -1
      )
        continue;

      //includeCarrier check is only if the range is there, if no range then includeCarrier should be true.
      if (data.range) {
        expectedStartDate = moment(expectedStartDate, "YYYY-MM-DD");
        let includeCarrier = false;
        for (let range of data.range) {
          if (!range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");

            if (fromDate.isSameOrBefore(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (!range.from && range.to) {
            const toDate = moment(range.to, "YYYY-MM-DD");
            if (toDate.isAfter(expectedStartDate)) {
              includeCarrier = true;
              break;
            }
          }
          if (range.to && range.from) {
            const fromDate = moment(range.from, "YYYY-MM-DD");
            const toDate = moment(range.to, "YYYY-MM-DD");

            if (
              expectedStartDate.isSameOrAfter(fromDate) &&
              expectedStartDate.isBefore(toDate)
            ) {
              includeCarrier = true;
              break;
            }
          }
        }
        if (!includeCarrier) continue;
      }

      if (
        data.peo === peoValue ||
        (peoValue === "demo" && data.peo === "gms")
      ) {
        carrierList.push({
          label: data.actualCarrierName,
          value: data.carrier,
        });
      }
    }
    return carrierList;
  };

  const handleDateChangeEff = (date) => {
    let _companyProfile = JSON.parse(JSON.stringify(companyProfile));
    let selectedPeoValue = peoDetails.selectedPeo;
    let _carrierList = [];
    _carrierList = findCarriers(selectedPeoValue, date);
    if (date) {
      _companyProfile.expectedStartDate.value = date;
      _companyProfile.effectiveDate.value = date;
      _companyProfile.expectedExpiryDate = {
        value: moment(date).add(1, "year"),
      };
      setCompanyProfile(null, null, _companyProfile);
      setCarrierList({ error: false, value: _carrierList });
    }
  };

  const handleDateChangeExpEff = (date) => {
    let _companyProfile = JSON.parse(JSON.stringify(companyProfile));

    if (date) {
      _companyProfile.expectedExpiryDate = {
        value: date,
      };
      setCompanyProfile(null, null, _companyProfile);
    }
  };

  const handleSelectedList = (list) => {
    let _carrierList = JSON.parse(JSON.stringify(carrierList));

    _carrierList = {
      error: false,
      value: list,
    };

    if (
      peoDetails.selectedPeo !== "insurecomp" &&
      peoDetails.selectedPeo !== "wcn"
    )
      refreshEmodDetails(
        peoDetails.selectedPeo === "insurecomp" ||
          peoDetails.selectedPeo === "wcn",
        emodStatesData,
        childrenLoc,
        peoDetails.selectedPeo,
        _carrierList
      );

    setCarrierList(_carrierList);
  };

  const updateCallback = (value) => {
    let _carrierList = findCarriers(value, expstartdate);
    refreshEmodDetails(
      value === "insurecomp" || value === "wcn",
      emodStatesData,
      childrenLoc,
      value,
      { error: false, value: _carrierList }
    );
    setPeoDetails({
      selectedPeo: value,
      error: false,
    });
    setCarrierList({ error: false, value: _carrierList });
  };

  const updateCompanyProfile = (event, fieldName) => {
    let val = event.target.value;
    if (fieldName === "state1" || fieldName === "state2") {
      val = event.target.value.toLowerCase();
    }
    setCompanyProfile(fieldName, "value", val);
  };

  const setStateAndCity = (zipCode, num) => {
    if (!num) num = "";
    getStateAndCity(zipCode, {})
      .then((res) => {
        const data = res.data;
        if (data !== `"Data not found"`) {
          setCompanyProfile(`state${num}`, "value", data.state.toLowerCase());
          setCompanyProfile(`cityName${num}`, "value", data.city);
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
      });
  };

  const setStateAndCityStateSpecific = (zipCode, num) => {
    if (!zipCode) return;
    if (!num) num = "";

    let stateList = [];
    if (stateSpecificCheck) {
      stateList = specificStatesList;
    }

    getStateAndCity(zipCode, stateList)
      .then((res) => {
        const data = res.data;
        console.log("data: ", data);
        if (data !== `"Data not found"`) {
          setCompanyProfile(`state${num}`, "value", data.state.toLowerCase());
          setCompanyProfile(`cityName${num}`, "value", data.city);
        }
        if (stateSpecificCheck) {
          if (data?.message === "Data not found") {
            setZipError(true);
          }
        }
      })
      .catch((err) => {
        console.log("Err in get state with zip code:", err);
        setZipError(true);
      });
  };
  return (
    <div className={styles.company_profile_container}>
      <div className={`row ${styles.row}`}>
        <div className="col-lg-6 col-md-12" id="peo-select-container">
          <Form.Group className="form-">
            <DropDown
              id={"peo-select"}
              updateCallback={updateCallback}
              options={peoOptions}
              title={"Distributing partner"}
              value={peoDetails.selectedPeo}
              style={
                peoDetails && peoDetails.error ? { borderColor: "red" } : {}
              }
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-md-12" id="carrier-select-container">
          <Form.Group className={styles.form_group}>
            <b className="font-family-montserrat-semi-bold">
              <label htmlFor="peo-select">Carrier</label>
            </b>
            <ReactMultiSelect
              listToDisplay={_carrierList}
              selectedList={carrierList.value || []}
              handleSelectedListCallback={handleSelectedList}
              style={carrierList.error ? { border: "1px solid red" } : {}}
            />
          </Form.Group>
        </div>
      </div>
      <div
        className={`row ${styles.row}  ${styles.companyrow_margin}`}
        id="company-profile-row1"
      >
        <div className="col-lg-6 col-md-12 grey">
          <Form.Group className={styles.form_group}>
            <label htmlFor="companyName" className={styles.mandatory}>
              <b className="font-family-montserrat-semi-bold">
                Company Name (Applicant Name)
              </b>
            </label>
            <input
              value={companyProfile.companyName.value}
              id="companyName"
              type="text"
              style={
                companyProfile?.companyName?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              onChange={(event) => updateCompanyProfile(event, "companyName")}
              placeholder="Company Name"
              autoComplete="off"
              className="rectangle1"
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div className="col-lg-6 col-md-12">
          <Form.Group className={styles.form_group}>
            <label
              htmlFor="descriptionOfOperations"
              title="Description of Operations"
              className={styles.mandatory}
            >
              <b className="font-family-montserrat-semi-bold">
                Description of Operations
              </b>
            </label>
            <input
              value={companyProfile.descriptionOfOperations.value}
              onChange={(event) =>
                updateCompanyProfile(event, "descriptionOfOperations")
              }
              style={
                companyProfile?.descriptionOfOperations?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              placeholder="Description of Operations"
              id="descriptionOfOperations"
              type="text"
              // onKeyUp={onlyAlpha}
              autoComplete="off"
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
      </div>
      <div
        className={`row ${styles.row}  ${styles.feinrow_margin}`}
        id="company-profile-row2"
      >
        <div className="col-lg-2 col-md-12">
          <Form.Group className={styles.form_group}>
            <label htmlFor="fein" className={styles.mandatory}>
              <b className="font-family-montserrat-semi-bold">FEIN</b>
            </label>
            <input
              placeholder="FEIN"
              value={companyProfile.fein ? companyProfile.fein.value : ""}
              onChange={(event) => {
                updateCompanyProfile(event, "fein");
              }}
              style={
                companyProfile?.fein && companyProfile?.fein?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              id="fein"
              type="text"
              autoComplete="off"
              onInput={(event) => {
                event.target.value = event.target.value
                  .replace(/[^0-9.]/g, "")
                  .replace(/(\..*)\./g, "$1");
              }}
              onBlur={(event) => {
                var value = event.target.value.toString().trim();
                value = value.split("-").join("");
                var len = value.length,
                  dummystr = "000000000";

                let _fein = {};

                if (len > 0 && len < 9) {
                  value = dummystr.slice(0, 9 - len) + "" + value;
                  _fein.value = value[0] + value[1] + "-" + value.slice(2);
                  _fein.errMsg = "";
                } else if (len > 9) {
                  _fein.errMsg = "FEIN needs to be 9 digits";
                  _fein.error = true;
                } else if (len === 0) {
                  _fein.value = undefined;
                  _fein.errMsg = "";
                } else {
                  _fein.value = value[0] + value[1] + "-" + value.slice(2);
                  _fein.errMsg = "";
                }
                setCompanyProfile("fein", null, _fein);
              }}
            />
            <p className={styles.errMsg}>
              {companyProfile.fein &&
              companyProfile.fein.errMsg &&
              companyProfile.fein.errMsg !== ""
                ? companyProfile.fein.errMsg
                : ""}
            </p>
          </Form.Group>
        </div>
        <div className="col-lg-5 col-md-12">
          <Form.Group className={styles.form_group}>
            <label
              htmlFor="firstName"
              title="First Name"
              className={styles.mandatory}
            >
              <b className="font-family-montserrat-semi-bold">First Name</b>
            </label>
            <input
              placeholder="First Name"
              value={companyProfile.firstName.value}
              onChange={(event) => updateCompanyProfile(event, "firstName")}
              disabled={flagPreUnderWriter}
              style={
                companyProfile?.firstName?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              id="firstName"
              type="text"
              // onKeyUp={onlyAlpha}
              autoComplete="off"
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div className="col-lg-5 col-md-12">
          <Form.Group className={styles.form_group}>
            <label
              htmlFor="lastName"
              title="Last Name"
              className={styles.mandatory}
            >
              <b className="font-family-montserrat-semi-bold">Last Name</b>
            </label>
            <input
              placeholder="Last Name"
              value={companyProfile.lastName.value}
              disabled={flagPreUnderWriter}
              onChange={(event) => updateCompanyProfile(event, "lastName")}
              style={
                companyProfile?.lastName?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              id="lastName"
              type="text"
              // onKeyUp={onlyAlpha}
              autoComplete="off"
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
      </div>
      <div
        className={`row ${styles.row}  ${styles.Phone_margin}`}
        id="company-profile-row3"
      >
        <div id="phone-number" className={styles.phone_number}>
          <Form.Group className={styles.form_group}>
            <label htmlFor="phoneNumber">
              <b className="font-family-montserrat-semi-bold">Phone Number</b>
            </label>
            <PatternFormat
              value={companyProfile.phoneNumber.value}
              onChange={(event) => updateCompanyProfile(event, "phoneNumber")}
              disabled={flagPreUnderWriter}
              style={
                companyProfile?.phoneNumber?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              placeholder="(000) 000-0000"
              autoComplete="off"
              id="phoneNumber"
              format="(###) ###-####"
              type="text"
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div id="entity-type" className={styles.entity_type}>
          <Form.Group className="form-">
            <DropDown
              id={"entityType"}
              updateCallback={(val) =>
                updateCompanyProfile({ target: { value: val } }, "entityType")
              }
              options={[
                { label: "Please Select", value: undefined },
                { label: "Corporation", value: "Corporation" },
                { label: "Joint Venture", value: "Joint Venture" },
                { label: "LLC", value: "LLC" },
                {
                  label: "Not for Profit 501[c][3]",
                  value: "Not for Profit 501[c][3]",
                },
                { label: "Other", value: "Other" },
                { label: "Partnership", value: "Partnership" },
                { label: "Sole Proprietor", value: "Sole Proprietor" },
                { label: "SubCharter 'S' corp", value: "SubCharter 'S' corp" },
                { label: "Trust", value: "Trust" },
              ]}
              title={"Entity Type"}
              value={companyProfile.entityType.value}
              style={
                companyProfile?.entityType?.error
                  ? { border: "1px solid red " }
                  : { fontSize: "16px" }
              }
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div id="years-in-business" className={styles.years_in_business}>
          <Form.Group className={styles.form_group}>
            <label
              htmlFor="yearsInBusiness"
              title="Years in Business"
              className={styles.mandatory}
            >
              <b className="font-family-montserrat-semi-bold">
                Years in Business
              </b>
            </label>
            <span id="yearsInBusiness">
              <NumericFormat
                value={companyProfile.yearsInBusiness.value}
                onChange={(event) =>
                  updateCompanyProfile(event, "yearsInBusiness")
                }
                style={
                  companyProfile?.yearsInBusiness?.error
                    ? {
                        border: "1px solid red ",
                        textAlign: "center",
                      }
                    : { textAlign: "center" }
                }
                autoComplete="off"
                id="yearsInBusiness"
                format="####"
                type="text"
                allowNegative={false}
              />
            </span>
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div id="expected-start-date" className={styles.expected_start_date}>
          <Form.Group className={styles.form_group}>
            <label
              htmlFor="expectedStartDate"
              title="Expected Start Date"
              className={styles.mandatory}
            >
              <b className="font-family-montserrat-semi-bold">
                Expected Start Date
              </b>
            </label>
            <span id="expectedStartDate">
              <DatePicker
                selected={
                  companyProfile.expectedStartDate.value
                    ? moment(companyProfile.expectedStartDate.value).toDate()
                    : null
                }
                onChange={handleDateChangeEff}
                showYearDropdown
                showMonthDropdown
                useShortMonthInDropdown
              />
            </span>
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
      </div>
      {
        <div className={`row ${styles.row} ${styles.Phone_margin}`}>
          <div
            id="expected-expiry-date"
            className={styles.expected_expiry_date}
          >
            <Form.Group className={styles.form_group}>
              <label
                htmlFor="expectedExpiryDate"
                title="Expected Expiry Date"
                className={styles.mandatory}
              >
                <b className="font-family-montserrat-semi-bold">
                  Expected Expiry Date
                </b>
              </label>
              <span id="expectedExpiryDate">
                <DatePicker
                  selected={expdate ? moment(expdate).toDate() : null}
                  onChange={handleDateChangeExpEff}
                  minDate={moment(expstartdate).toDate()}
                  showYearDropdown
                  showMonthDropdown
                  useShortMonthInDropdown
                />
              </span>
              <p className={styles.errMsg} />
            </Form.Group>
          </div>

          <div className="col-lg-4 col-md-12 grey">
            <Form.Group className={styles.form_group}>
              <label htmlFor="companyName" className={styles.mandatory}>
                <b className="font-family-montserrat-semi-bold">Producer</b>
              </label>
              <input
                value={companyProfile?.producer?.value}
                id="producer"
                type="text"
                style={
                  companyProfile?.producer?.error
                    ? { border: "1px solid red " }
                    : {}
                }
                onChange={(event) => updateCompanyProfile(event, "producer")}
                placeholder="Producer"
                autoComplete="off"
                className="rectangle1"
              />
              <p className={styles.errMsg} />
            </Form.Group>
          </div>
        </div>
      }
      <div className={`row-col-2 mb-0 ${styles.primary_margin}`}>
        <Form.Group
          className={`${styles.form_group} ${styles.primary_margin_formGroup}`}
        >
          <b className="font-family-montserrat-semi-bold">Primary Location</b>
        </Form.Group>
      </div>
      <div className={`row ${styles.row}  ${styles.street_margin}`}>
        <div id="street-1" className={styles.street1_margin}>
          <Form.Group className={styles.form_group}>
            <label htmlFor="street1">
              <b className="font-family-montserrat-semi-bold">Street Address</b>
            </label>
            <input
              value={companyProfile.street1.value}
              id="street1"
              type="text"
              style={
                companyProfile?.street1?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              onChange={(event) => updateCompanyProfile(event, "street1")}
              placeholder="Street Address"
              autoComplete="off"
              className="rectangle1"
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div id="zip-1" className={styles.zip_1}>
          <Form.Group className={styles.form_group}>
            <label htmlFor={"zipCode1"} title="Zip Code">
              <b className="font-family-montserrat-semi-bold">Zip</b>
            </label>
            <PatternFormat
              name="zipCode1"
              id={"zipCode1"}
              format="#####"
              mask="_"
              type="text"
              onChange={(event) => {
                updateCompanyProfile(event, "zipCode1"), setZipError(false);
              }}
              value={companyProfile ? companyProfile.zipCode1.value : ""}
              onBlur={(event) =>
                setStateAndCityStateSpecific(event.target.value, 1)
              }
              style={
                companyProfile && companyProfile?.zipCode1?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              placeholder="Zip"
              autoComplete="off"
            />
            {zipError ? (
              <p style={{ color: "red" }}>
                Please enter only {specificStatesList.join(",") || "valid"} zip
                code
              </p>
            ) : (
              ""
            )}
            <p className={`${styles.errMsg} ${styles.addressErrs}`} />
          </Form.Group>
        </div>
        <div id="city-1" className={styles.city_1}>
          <Form.Group className={styles.form_group}>
            <label htmlFor={"cityName1"} title="City">
              <b className="font-family-montserrat-semi-bold">City</b>
            </label>
            <input
              id={"cityName1"}
              name="cityName1"
              type="text"
              onChange={(event) => updateCompanyProfile(event, "cityName1")}
              value={companyProfile ? companyProfile.cityName1.value : ""}
              style={
                companyProfile && companyProfile?.cityName1?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              placeholder="City Name"
              autoComplete="off"
            />
            <p className={`${styles.errMsg} ${styles.addressErrs}`} />
          </Form.Group>
        </div>
        <div className={styles.state_1} id="state-1">
          <Form.Group className={styles.form_group}>
            <label title="State">
              <b className="font-family-montserrat-semi-bold">State</b>
            </label>
            <select
              id={"state1"}
              name="state1"
              type="text"
              onChange={(event) => updateCompanyProfile(event, "state1")}
              value={
                companyProfile &&
                companyProfile.state1 &&
                companyProfile.state1.value
                  ? companyProfile.state1.value.toLowerCase()
                  : ""
              }
              style={
                companyProfile && companyProfile?.state1?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              autoComplete="off"
            >
              {allStates1}
            </select>
            <p className={`${styles.errMsg} ${styles.addressErrs}`} />
          </Form.Group>
        </div>
        <div className="col-sm-1"></div>
      </div>
      <div className={`row-col-2 ${styles.mailing_margin}`}>
        <Form.Group
          className={`${styles.form_group} ${styles.mailing_margin_formGroup}`}
        >
          <div className={`row ${styles.row} `}>
            <b
              className={`font-family-montserrat-semi-bold ${styles.mailing_address_heading}`}
            >
              Mailing Address
            </b>
            <div className=" d-flex align-items-center">
              <input
                className="mr-1"
                type="checkbox"
                id="sameAsPrimary-1"
                name={"sameAsPrimary"}
                checked={sameAsPrimary}
                onChange={handleSameAsPrimaryCheck}
              />
              <label htmlFor="sameAsPrimary-1">
                <small>Same as Physical Address</small>
              </label>
            </div>
          </div>
        </Form.Group>
      </div>
      <div className={`row ${styles.row} ${styles.mail_street_margin}`}>
        <div id="street-2" className={styles.street2_margin}>
          <Form.Group className={styles.form_group}>
            <label htmlFor="street2">
              <b className="font-family-montserrat-semi-bold">Street Address</b>
            </label>
            <input
              value={companyProfile.street2.value}
              id="street2"
              type="text"
              style={
                companyProfile?.street2?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              onChange={(event) => updateCompanyProfile(event, "street2")}
              placeholder="Street Address"
              autoComplete="off"
              className="rectangle1"
            />
            <p className={styles.errMsg} />
          </Form.Group>
        </div>
        <div className={styles.zip_2} id="zip-2">
          <Form.Group className={styles.form_group}>
            <label htmlFor={"zipCode2"} title="Zip Code">
              <b className="font-family-montserrat-semi-bold">Zip</b>
            </label>
            <PatternFormat
              name="zipCode2"
              id={"zipCode2"}
              format="#####"
              mask="_"
              type="text"
              onChange={(event) => updateCompanyProfile(event, "zipCode2")}
              value={companyProfile ? companyProfile.zipCode2.value : ""}
              style={
                companyProfile && companyProfile?.zipCode2?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              onBlur={(event) => setStateAndCity(event.target.value, 2)}
              placeholder="Zip"
              autoComplete="off"
            />
            <p className={`${styles.errMsg} ${styles.addressErrs}`} />
          </Form.Group>
        </div>
        <div id="city-2" className={styles.city_2}>
          <Form.Group className={styles.form_group}>
            <label htmlFor={"cityName2"} title="City">
              <b className="font-family-montserrat-semi-bold">City</b>
            </label>
            <input
              id={"cityName2"}
              name="cityName2"
              type="text"
              onChange={(event) => updateCompanyProfile(event, "cityName2")}
              value={companyProfile ? companyProfile.cityName2.value : ""}
              style={
                companyProfile && companyProfile?.cityName2?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              placeholder="City Name"
              autoComplete="off"
            />
            <p className={`${styles.errMsg} ${styles.addressErrs}`} />
          </Form.Group>
        </div>
        <div className={styles.state_2} id="state-2">
          <Form.Group className={styles.form_group}>
            <label title="State">
              <b className="font-family-montserrat-semi-bold">State</b>
            </label>
            <select
              id={"state2"}
              name="state2"
              type="text"
              onChange={(event) => updateCompanyProfile(event, "state2")}
              value={
                companyProfile &&
                companyProfile.state2 &&
                companyProfile.state2.value
                  ? companyProfile.state2.value.toLowerCase()
                  : ""
              }
              style={
                companyProfile && companyProfile?.state2?.error
                  ? { border: "1px solid red " }
                  : {}
              }
              autoComplete="off"
            >
              {allStates2}
            </select>
            <p className={`${styles.errMsg} ${styles.addressErrs}`} />
          </Form.Group>
        </div>
        <div className="col-sm-1"></div>
      </div>
    </div>
  );
}
