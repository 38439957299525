import React, { Component } from "react";
import { NumericFormat } from "react-number-format";
import { Form } from "react-bootstrap";
import styles from "./Allques.module.scss"
let isMobile = window.innerWidth < 700;
class Allques extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quesList: this.props.quesList,
    };
  }

  componentDidUpdate() {
    if (this.state.quesList !== this.props.quesList) {
      this.setState({ quesList: this.props.quesList });
    }
  }

  createTable = () => {
    const { quesList } = this.state;
    const { updateQuesList } = this.props;
    let table = [];

    // Outer loop to create parent
    for (let i = 0; i < quesList.length; i++) {
      table.push(
        <tr className="bringcolumn " key={i}>
          <td className={"responsivecolumn" + `${styles.tableQues}` + " " + `trow${i}`}>
            {quesList[i].ques}
          </td>

          <td className={styles.tableAns}>
            <div className="d-flex">
              <Form.Check
                inline
                id={`inputY-${i}`}
                value="Y"
                name={quesList[i].name}
                checked={quesList[i].response}
                label="Yes"
                type="radio"
                onChange={(e) => updateQuesList(e, "response", true)}
              />
              <Form.Check
                inline
                id={`inputN-${i}`}
                value="N"
                name={quesList[i].name}
                checked={!quesList[i].response}
                label="No"
                type="radio"
                onChange={(e) => updateQuesList(e, "response", false)}
              />
            </div>
          </td>
          <td className={styles.tableRemarks}>
            {quesList[i].type === "percent" ? (
              <NumericFormat
                id={"qr" + (i + 1)}
                className={
                  !quesList[i].response ? `${styles.hidden} ques-input` : "ques-input"
                }
                maxLength="3"
                suffix={"%"}
              />
            ) : (
              <input
                id={`remarks-${i}`}
                value={
                  quesList[i].remarks && quesList[i].remarks !== "none"
                    ? quesList[i].remarks
                    : ""
                }
                type="text"
                className={
                  !quesList[i].response ? `${styles.hidden} ques-input` : "ques-input"
                }
                disabled={!quesList[i].response}
                onChange={(e) => updateQuesList(e, "remarks", e.target.value)}
              />
            )}
          </td>
        </tr>
      );
    }
    return table;
  };
  componentDidMount() {
    const { setUWQues } = this.props;
    let obj = JSON.parse(sessionStorage.getItem("currProspect"));
    if (obj) {
      if (obj.uwQues) {
        setUWQues(obj.uwQues);
      }
    }
  }
  render() {
    return (
      <table className={`table table-striped table-borderless ${styles.table_undrques}`}>
        <thead>
          {!isMobile ? (
            <tr>
              <th className="tableQues">Questions</th>
              <th className="tableAns">Response</th>
              <th className="tableRem">Remarks</th>
            </tr>
          ) : (
            " "
          )}
        </thead>
        <tbody>{this.createTable()}</tbody>
      </table>
    );
  }
}
export default Allques;
