import React, { useEffect, useState } from "react";
import mono_states from "../../utils/mono_states.json";
import DropZones from "../common/DropZones";

const UpdateRates = (props) => {
  const [data, setData] = useState({});
  const [carriers, setCarriers] = useState([]);
  const [selectedCarrier, setSelectedCarrier] = useState("select");
  const [uwNetRates, setUwNetRates] = useState({});

  const getStateType = (program, state, programStateType) => {
    if (mono_states.includes(state)) {
      return "MONO";
    } else if (programStateType?.[program]?.[state]) {
      return "MCP";
    } else if (
      programStateType?.[program]?.["master"]?.without_fundrate?.includes(state) ||
      programStateType?.[program]?.["master"]?.with_fundrate?.includes(state)
    ) {
      return "MASTER";
    }
    return "";
  }

  let firstCaps = (st) => {
    return st
      .split(" ")
      .map((e) => {
        e = e.trim().toLowerCase();
        return e[0].toUpperCase() + e.substring(1);
      })
      .join(" ");
  };

  const roundOffAndThousandSeperated = (num) => {
    let val = Math.round(Number(num));
    return "$" + val.toLocaleString("en");
  };

  const roundVaues = (a, b) => {
    let divi = 100,
      fix = 2;
    if (b) {
      divi = 10000;
      fix = 4;
    }
    if (a) return (Math.round(Number(a) * divi) / divi).toFixed(fix);
    return 0;
  }

  const createTableData = (rows) => {
    let table_rows = [];
    for (let ele of rows) {
      table_rows.push(
        <tr className="carr-dropdown-body-row" key={`carr-dropdown-${ele.state}`}>
          <td>{ele.state_type}</td>
          <td>{ele.state.toUpperCase()}</td>
          <td>{ele.class_code}</td>
          <td className="description-details">{firstCaps(ele.class_code_desc)}</td>
          <td style={{ textAlign: "left" }}>{roundOffAndThousandSeperated(ele.payroll)}</td>
          <td>{roundVaues(ele.manual_rate)}</td>
          <td><TextField value={ele.net_rate_ax} key1={ele.program} key2={ele.state} key3={ele.last_key_for_uw_net_rate} key4={"net_rate_ax"} callback={updateUwNetRates} /></td>
          <td><TextField value={ele.net_rate} key1={ele.program} key2={ele.state} key3={ele.last_key_for_uw_net_rate} key4={"net_rate"} callback={updateUwNetRates} /></td>
        </tr>
      );
    }
    return table_rows;
  }

  const updateUwNetRates = (key1, key2, key3, key4, val) => {
    let _uwNetRates = JSON.parse(JSON.stringify(uwNetRates));
    if (!(key1 in _uwNetRates)) _uwNetRates[key1] = {};
    if (!(key2 in _uwNetRates[key1])) _uwNetRates[key1][key2] = {};
    if (!(key3 in _uwNetRates[key1][key2])) _uwNetRates[key1][key2][key3] = {};
    if (!(key4 in _uwNetRates[key1][key2][key3])) _uwNetRates[key1][key2][key3][key4] = val;
    setUwNetRates(_uwNetRates)
  }

  const submitHandler = () => {
    let uid = sessionStorage.getItem("user_id");
    let uuid_list = JSON.parse(sessionStorage.getItem("sortKeyList"));

    let dataToSave = {};
    dataToSave.user_email_id = uid;
    dataToSave.sortKeyList = uuid_list;
    dataToSave.uw_net_rates = JSON.parse(JSON.stringify(uwNetRates));

    props.updateData(dataToSave);
  }

  useEffect(() => {
    if (props.peoData && props.programStateType && props.programs) {

      if (props.uw_net_rates) {
        setUwNetRates(props.uw_net_rates);
      }

      let _data = {},
        _carriers = {};

      let _selectedCarrier;

      for (let program in props.peoData) {
        let program_key_list = program.split("-");
        let program_key = program_key_list[1] || program_key_list[0];

        _carriers[program_key] = program;

        if (!_selectedCarrier) _selectedCarrier = program;

        if (!(program in _data))
          _data[program] = [];

        for (let state in props.peoData?.[program] || {}) {

          for (let row of props.peoData?.[program]?.[state] || []) {
            let last_key_for_uw_net_rate = `${row?.wc_code}#${row?.wc_desc}`;

            _data[program].push({
              state_type: getStateType(program, state, props.programStateType),
              state,
              class_code: row?.wc_code,
              class_code_desc: row?.wc_desc,
              payroll: row?.payroll,
              manual_rate: row?.manual_rate,
              net_rate_ax: props.uw_net_rates?.[program]?.[state]?.[last_key_for_uw_net_rate]?.["net_rate_ax"] || "",
              net_rate: props.uw_net_rates?.[program]?.[state]?.[last_key_for_uw_net_rate]?.["net_rate"] || "",
              last_key_for_uw_net_rate,
              program
            });
          }
        }
      }
      setData(_data);
      setCarriers(_carriers);
      setSelectedCarrier(_selectedCarrier);
    }
  }, [props.peoData, props.programStateType, props.uw_net_rates]);

  return (
    <div className="update-rates">
      <div className="update-rates-heading">Update Class code and rates</div>
      <div className="carr-dropdown-container">
        <label htmlFor="carr-dropdown">Carrier</label>
        <select id="carr-dropdown" onChange={e => setSelectedCarrier(e.target.value)} value={selectedCarrier}>
          <option value={"select"}>{"Select"}</option>
          {Object.keys(carriers).map(carr => <option value={carriers[carr]}>{carr}</option>)}
        </select>
      </div>
      <div>
        <table>
          <tbody>
          <tr id="carr-dropdown-head-row-id" key={"carr-dropdown-head-row"}>
            <th>State Type</th>
            <th>State</th>
            <th>Class Code</th>
            <th style={{ textAlign: "left" }}>ClassCode Description</th>
            <th>Payroll</th>
            <th>Rate</th>
            <th>Net Rate w/ Ax & EC</th>
            <th>Net Rate w/o Ax & EC</th>
          </tr>
          </tbody>
          {
            selectedCarrier !== "select" &&
            createTableData(data?.[selectedCarrier] || [])
          }
        </table>
      </div>
      <div className="submit-uw-details mt-3">
        <button
          className="btn btn-warning"
          onClick={() => submitHandler()}
        >
          Submit
        </button>
      </div>
      <div className="mt-2" style={{ width: "50%", marginInline: "auto" }}>
        <DropZones
          updatePopUpData={props.updatePopUpData}
          fileList={props.fileList}
          displayArray={[false, false, true]}
          fileKeyName="uwFileList"
        />
      </div>
    </div>
  );
};

const TextField = (props) => {
  const [currValue, setCurrValue] = useState("");

  useEffect(() => {
    setCurrValue(props.value)
  }, [props.value]);

  const onChangeFunc = (e) => {
    if (e.target.value)
      props.callback(props.key1, props.key2, props.key3, props.key4, e.target.value);
  }
  return <input
    type="number"
    className="netrate-uw-input-box"
    value={currValue}
    onChange={e => setCurrValue(e.target.value)}
    onBlur={onChangeFunc}
  />
}

export default UpdateRates;