import React, { Component } from "react";
import { Link } from "react-router-dom";
import { logout } from "../../utils/common";
import { Auth } from "aws-amplify";
import styles from "./HeaderSubPart.module.scss";
import { LibertateContext } from "../../context/Context";

let isMobile = window.innerWidth < 900;
class HeaderSubPart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      userGroups: [],
    };
  }

  componentDidMount = () => {
    let logoSize;
    let hrf = window.location.href;
    if (!hrf.includes("toolbar")) {
      if (hrf.includes("Quote")) {
        logoSize = "small";
      } else {
        logoSize = "large";
      }
    }
    if (logoSize) this.setState({ logoSize });

    let isLoggedIn,
      emailID,
      userGroups = [];
    try {
      isLoggedIn = this.props.sessionData;
      // console.log("isLoggedIn: ", isLoggedIn);
      if (isLoggedIn?.attributes?.name) {
        emailID = isLoggedIn.attributes.name;
      } else {
        emailID = isLoggedIn.attributes.email.split("@")[0];
      }
      let userGroups =
        isLoggedIn.signInUserSession.accessToken.payload["cognito:groups"];
      this.setState({ userGroups });
      // console.log("emailID: ", emailID, userGroups);
    } catch (error) {
      // console.log("comp did update err", error);
    }

    if (emailID) {
      this.setState({ emailID });
    }
  };

  render() {
    let { emailID, userGroups } = this.state;
    let { resetEntireState } = this.props;
    let quoteGroups = [process.env.REACT_APP_GET_QUOTE_GROUP];
    let raterGroups = [process.env.REACT_APP_RATER_GROUP];
    let dashboardGroups = [process.env.REACT_APP_DASHBOARD_GROUP];
    let uwDashboardGroup = [process.env.REACT_APP_UW_GROUPS];
    let uploadGroups = [
      process.env.REACT_APP_UPLOAD_COMPANY,
      process.env.REACT_APP_UPLOAD_RATER,
    ];

    let displayQuote =
      !window.location.pathname.toLowerCase().includes("quote") &&
      userGroups.some((e) => quoteGroups.includes(e)) &&
      !userGroups.some((e) => uwDashboardGroup.includes(e));
    let displayRater =
      !window.location.pathname.toLowerCase().includes("rates") &&
      userGroups.some((e) => raterGroups.includes(e));
    let displayDashboard =
      !(window.location.pathname.toLowerCase() === "/dashboard") &&
      userGroups.some((e) => dashboardGroups.includes(e));
    let displayUwDashboard =
      !(window.location.pathname.toLowerCase() === "/uwportal") &&
      userGroups.some((e) => uwDashboardGroup.includes(e));
    let displayRaterDashboard =
      !(window.location.pathname.toLowerCase() === "/raterdashboard") &&
      userGroups.some((e) => dashboardGroups.includes(e));
    let displayUpload = userGroups.some((e) => uploadGroups.includes(e));

    let companyUploadGroups = userGroups.includes(
      process.env.REACT_APP_UPLOAD_COMPANY
    );
    let raterUploadGroups = userGroups.includes(
      process.env.REACT_APP_UPLOAD_RATER
    );

    return (
      <>
        {emailID && (
          <div
            className={`d-flex mt-2 justify-content-between ${styles.navcontent}`}
          >
            {(displayQuote ||
              window.location.pathname.includes("UwPortal")) && (
              <Link
                to={"/quote"}
                className="dashboard-link text-dark"
                onClick={() => {
                  resetEntireState();
                }}
              >
                Get Quote
              </Link>
            )}
            {displayRater && (
              <Link to={"/rates"} className="dashboard-link text-dark">
                Rater
              </Link>
            )}
            {(displayRaterDashboard || displayDashboard) && (
              <div className="d-flex align-items-start dropdown">
                <span
                  className="dropdown-toggle text-dark"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Dashboard
                </span>
                <div className="dropdown-menu">
                  {displayDashboard && (
                    <Link className="dropdown-item nowrap" to={"/dashboard"}>
                      Quote Tool
                    </Link>
                  )}
                  {displayRaterDashboard && (
                    <Link className="dropdown-item" to={"/raterdashboard"}>
                      Rater
                    </Link>
                  )}
                </div>
              </div>
            )}
            {isMobile
              ? ""
              : displayUwDashboard && (
                  <div className="d-flex align-items-start dropdown navbar-font">
                    {displayUwDashboard && (
                      <a
                        className="dashboard-link text-dark"
                        href={"/UwPortal"}
                      >
                        UW Dashboard
                      </a>
                    )}
                  </div>
                )}
            {displayUpload && (
              <div className="d-flex align-items-start dropdown">
                <span
                  className="dropdown-toggle text-dark"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Upload
                </span>
                <div className="dropdown-menu">
                  {companyUploadGroups && (
                    <Link className="dropdown-item" to={"/DataUpload"}>
                      Company Profile
                    </Link>
                  )}
                  {raterUploadGroups && (
                    <Link className="dropdown-item" to={"/RaterUpload"}>
                      Rater
                    </Link>
                  )}
                </div>
              </div>
            )}
            {!isMobile && (
              <div className="d-flex align-items-start dropdown">
                <span
                  className="dropdown-toggle text-dark navbar-font"
                  data-toggle="dropdown"
                  href="#"
                  role="button"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  {emailID}
                </span>
                <div className="dropdown-menu ">
                  <a
                    className="dropdown-item"
                    onClick={() => {
                      logout();
                    }}
                  >
                    Logout
                  </a>
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}

export default function HeaderSubPartWrapper(props) {
  return (
    <LibertateContext.Consumer>
      {(context) => <HeaderSubPart {...props} {...context} />}
    </LibertateContext.Consumer>
  );
}
