import axios from "axios";
import { awsUrl, awsUrl2 } from "../config";
import { getHeader } from "../utils/common";

export function uploadLossForm(postDataS3) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/uploadLossForm", postDataS3, header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

export function getProgramCarrierHandler(requestbody) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(
        awsUrl2 + "/api/getProgramCarrier",
        JSON.stringify(requestbody),
        header
      )
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}

// This Api  need to check from Akshay
export function getMinMaxPremium(bodyData) {
  return new Promise(async (resolve, reject) => {
    let header = await getHeader();
    axios
      .post(awsUrl + "/api/getMinMaxPremium", JSON.stringify(bodyData), header)
      .then((response) => {
        resolve(response);
      })
      .catch((error) => {
        console.error("Error in API request:", error);
        reject("Error in API request:" + error);
      });
  });
}
