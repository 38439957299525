import React from "react";
import { NumericFormat } from "react-number-format";
import { Form } from "react-bootstrap";
import {
  histClaimCalcTI,
  histClaimCalcTP,
  histClaimCalcTR,
} from "../../../utils/form_cmprRates";
import styles from "./HistoricalClaims.module.scss";

function HistoricalClaims({
  setHistoricalClaimsErrorStore,
  setHistoricalClaimsCheck,
  setHistoricalClaims,
  currProspect,
  historicalClaims,
  historicalClaimsCheck,
  historicalClaimsErrorStore,
}) {

  function clearErrHistoricalClaims(e, key) {
    let year = e.target.id.split("-")[1];
    let _historicalClaimsErrorStore = JSON.parse(
      JSON.stringify(historicalClaimsErrorStore)
    );

    if (_historicalClaimsErrorStore[year]) {
      _historicalClaimsErrorStore[year][key] = false;
      setHistoricalClaimsErrorStore(_historicalClaimsErrorStore);
    }
  }

  function handleChange(e, key) {
    let year = e.target.id.split("-")[1];
    let _historicalClaims = JSON.parse(JSON.stringify(historicalClaims));

    if (!_historicalClaims[year]) {
      _historicalClaims[year] = {};
    }
    _historicalClaims[year][key] = e.target.value;

    if (key === "totInc") {
      _historicalClaims = histClaimCalcTI(_historicalClaims, year);
    }
    if (key === "totPaid") {
      _historicalClaims = histClaimCalcTP(_historicalClaims, year);
    }
    if (key === "totRes") {
      _historicalClaims = histClaimCalcTR(_historicalClaims, year);
    }
    setHistoricalClaims(_historicalClaims);
  }

  function HistoricalClaimTextBlock(props) {
    let name = props.name;
    let label = props.label;
    let absYear = props.absYear;
    let historyLimitYear = props.historyLimitYear;
    let rowId = props.rowId;

    return (
      <Form.Group className="form-group">
        <label
          htmlFor={`${name}-${absYear}`}
          title={label}
          className="font-family-montserrat-semi-bold"
        >
          {label}
        </label>
        <input
          autoComplete="off"
          id={`${name}-${absYear}`}
          type="text"
          name="carText"
          onBlur={(e) => handleChange(e, name)}
          style={{
            backgroundColor:
              historyLimitYear > rowId || historicalClaimsCheck[absYear]
                ? "#dddddd"
                : "",
            borderColor: historicalClaimsErrorStore?.[absYear]?.[name]
              ? "red"
              : "",
          }}
          disabled={historyLimitYear > rowId}
          onKeyUp={(e) => {
            clearErrHistoricalClaims(e, historicalClaimsErrorStore, name);
          }}
          defaultValue={
            historicalClaims?.[absYear]?.[name]
              ? historicalClaims[absYear][name]
              : ""
          }
        />
      </Form.Group>
    );
  }

  function HistoricalClaimNumberBlock(props) {
    let name = props.name;
    let label = props.label;
    let absYear = props.absYear;
    let label_title = props.label_title;
    let historyLimitYear = props.historyLimitYear;
    let rowId = props.rowId;

    return (
      <Form.Group className="form-group">
        <label
          htmlFor={`${name}-${absYear}`}
          title={label_title}
          className="font-family-montserrat-semi-bold"
        >
          {label}
        </label>
        <NumericFormat
          name={name}
          id={`${name}-${absYear}`}
          autoComplete="off"
          onKeyUp={(e) => {
            clearErrHistoricalClaims(e, historicalClaimsErrorStore, name);
          }}
          thousandSeparator={true}
          onBlur={(e) => handleChange(e, name)}
          style={{
            backgroundColor:
              historyLimitYear > rowId || historicalClaimsCheck[absYear]
                ? "#dddddd"
                : "",
            borderColor: historicalClaimsErrorStore?.[absYear]?.[name]
              ? "red"
              : "",
          }}
          disabled={historyLimitYear > rowId}
          prefix={"$"}
          className="text-center"
          type="text"
          defaultValue={
            historicalClaims?.[absYear]?.[name]
              ? historicalClaims[absYear][name]
              : ""
          }
        />
      </Form.Group>
    );
  }

  function handleCheck(e) {
    let checkbox = e.target;
    let year = checkbox.id.split("-")[1];
    let checked = checkbox.checked;
    let _historicalClaimsCheck = JSON.parse(
      JSON.stringify(historicalClaimsCheck)
    );
    let _historicalClaimsErrorStore = JSON.parse(
      JSON.stringify(historicalClaimsErrorStore)
    );

    _historicalClaimsCheck[year] = checked;

    for (let key in _historicalClaimsErrorStore[year]) {
      _historicalClaimsErrorStore[year][key] = false;
    }

    setHistoricalClaimsCheck(_historicalClaimsCheck);
    setHistoricalClaimsErrorStore(_historicalClaimsErrorStore);
  }

  let table = (function createTable() {
    let currProspectDetails = currProspect;

    if (!currProspectDetails?.companyProfile?.yearsInBusiness?.value) return [];
    let table = [];
    let j = 0;

    let yearsInBusiness = Number(
      currProspectDetails?.companyProfile?.yearsInBusiness?.value
    );
    let absYear;

    let rowId, rowIdDup, rowIdYear, rowIdDupYear;

    rowIdYear = new Date().getFullYear();
    rowIdDupYear = new Date().getFullYear();
    let historyLimitYear = rowIdYear - yearsInBusiness;

    for (let i = 0; i < 10; i++) {
      rowId = rowIdYear - j;
      rowIdDup = rowIdDupYear - i;
      absYear = !absYear ? rowIdDup : absYear;
      rowId = rowId.toString();

      if (i % 2 === 0) {
        j++;

        table.push(
          <div className="row">
            <tr id={rowIdDup} key={rowIdDup}>
              <td className="col-4" id="carrier">
                <HistoricalClaimTextBlock
                  name="carrier"
                  label="Carrier"
                  absYear={absYear}
                  historyLimitYear={historyLimitYear}
                  rowId={rowId}
                />
              </td>
              <td className="col-3" id="Annaulprem">
                <HistoricalClaimNumberBlock
                  name="annPre"
                  label="Annual Premium"
                  absYear={absYear}
                  label_title="Annual Premium"
                  historyLimitYear={historyLimitYear}
                  rowId={rowId}
                />
              </td>
              <td className="col-3" id="claims">
                <HistoricalClaimNumberBlock
                  name="claims"
                  label="# Claims"
                  absYear={absYear}
                  label_title="Number of claims"
                  historyLimitYear={historyLimitYear}
                  rowId={rowId}
                />
              </td>
              <td className="col-4" id="totalincurred">
                <HistoricalClaimNumberBlock
                  name="totInc"
                  label="Total Incurred"
                  absYear={absYear}
                  label_title="Total Incurred"
                  historyLimitYear={historyLimitYear}
                  rowId={rowId}
                />
              </td>
              <td className="per10">
                <HistoricalClaimNumberBlock
                  name="totPaid"
                  label="Total Paid"
                  absYear={absYear}
                  label_title="Total Paid"
                  historyLimitYear={historyLimitYear}
                  rowId={rowId}
                />
              </td>
              <td className="per10">
                <HistoricalClaimNumberBlock
                  name="totRes"
                  label="Total Reserves"
                  absYear={absYear}
                  label_title="Total Reserves"
                  historyLimitYear={historyLimitYear}
                  rowId={rowId}
                />
              </td>
            </tr>
          </div>
        );
      } else {
        let yearId = Number(rowId) + 1;
        table.push(
          <div className="row">
            <tr id={rowIdDup} key={rowIdDup} className="row-no-coverage my-1">
              <td className="no-coverage">
                <input
                  type="checkbox"
                  id={`check-${absYear}`}
                  disabled={historyLimitYear > yearId}
                  checked={
                    historyLimitYear > yearId || historicalClaimsCheck[absYear]
                      ? historicalClaimsCheck[absYear] || true
                      : false
                  }
                  onChange={(e) => handleCheck(e)}
                />
                No coverage in {yearId}
              </td>
            </tr>
          </div>
        );
        absYear--;
      }
    }
    return table;
  })();

  return (
    <div className={styles.historicalClaimsContainer}>
      <h4 className="mb-3 mt-3">
        <b className="font-family-montserrat-bold">
          Historical claims information
        </b>
      </h4>
      <div className="row container">
        <table className={styles.claimTable}>
          <thead>
            <tr></tr>
          </thead>
          <tbody>{table}</tbody>
        </table>
      </div>
    </div>
  );
}

export default HistoricalClaims;
